import { FC } from "react";
import { Table } from "../../../../../components/Table";
import { TableColumn } from "../../../../../components/table/TableRow";
import { extractMarkdownText } from "../../../../../coreUtils";

interface Props {
  data: any;
}

const columns: TableColumn[] = [
  { dataField: "id", text: "#", align: "center" },
  {
    dataField: "nome_colaborador",
    text: "Colaborador",
    align: "left",
  },
  { dataField: "data_hora_visita", text: "Data/Hora", align: "center" },
  { dataField: "local_visita", text: "Local", align: "center" },
  {
    dataField: "observ",
    text: "Detalhes da Visita",
    align: "left",
    formatter: (c) => extractMarkdownText(c),
    fixedColWidth: true,
    colWidth: "70%",
  },
];

export const VisitasLeadGrid: FC<Props> = ({ data }) => {
  return (
    <Table
      data={data}
      columns={columns}
      fixedSize={false}
      pageSize={data.length}
      showRegisterCount={false}
      paginated={false}
      bgStyle={{ marginBottom: "0.5rem" }}
    />
  );
};
