import { FC } from "react";
import { Table } from "../../../../../components/Table";
import { iconeAlterar } from "../../../../../components/cadastro";
import { TableColumn } from "../../../../../components/table/TableRow";

interface Props {
  data: any;
  setSelected: (id: any) => void;
  alterar: (id: any) => void;
}

export const SistemaTerceiroGrid: FC<Props> = ({
  data,
  setSelected,
  alterar,
}) => {
  const columns: TableColumn[] = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    { dataField: "nome", text: "Nome", align: "left" },
    iconeAlterar(alterar),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
