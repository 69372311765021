import { FC, ReactElement, useEffect } from "react";
import { useHistory } from "react-router-dom";

interface Props {
  children: ReactElement;
}

const ScrollToTop: FC<Props> = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    return history.listen((_) => {
      window.scrollTo(0, 0);
    });
  }, [history]);

  return children;
};

export default ScrollToTop;
