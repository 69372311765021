import { FC, useRef } from "react";
import { useState } from "react";
import {
  dateFromLocaleString,
  formatDateISO,
  limparNumero,
} from "../../../../../coreUtils";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  PhoneInput,
  TextInput,
} from "../../../../../components";
import {
  RadioGroup,
  RadioGroupOption,
} from "../../../../../components/RadioGroup";
import { DatePicker } from "../../../../../components/DatePicker";
import { toastr } from "react-redux-toastr";
import { interacaoLeadEditorFormats } from "../interacao/InteracoesLead";
import { Divider } from "../../../../../components/Divider";
import {
  ModalActions,
  ModalCadastroProps,
  ModalCadastroV2,
} from "../../../../../components/cadastro/ModalCadastroV2";
import { modalTitle } from "../Lead";
import LeadService from "../../../../../services/components/comercial/LeadService";
import { AtualizacoesEditor } from "../../../../../components/AtualizacoesEditor";
import { extrairIdColabMencionados } from "../../../../../components/AtualizacoesForm";
import { ComboBoxOption } from "../../../../../components/ComboBox";

interface Props {
  isOpen: ModalCadastroProps["isOpen"];
  toggle: ModalCadastroProps["toggle"];
  action: ModalCadastroProps["action"];
  selected: any;
  notifyEvent: ModalCadastroProps["notifyEvent"];
}

const receptivoLeadOptions: RadioGroupOption[] = [
  { label: "Ativo", value: "A" },
  { label: "Receptivo", value: "R" },
];

export const grausConversaoLead: ComboBoxOption[] = [
  { label: "Pouca", value: "POU" },
  { label: "Média", value: "MED" },
  { label: "Grande", value: "GRA" },
  { label: "Total", value: "TOT" },
];

export const CadastroLeadModal: FC<Props> = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [idCidade, setIdCidade] = useState(null);
  const [nomeContato, setNomeContato] = useState("");
  const [telefone, setTelefone] = useState("");
  const [idOrigem, setIdOrigem] = useState(null);
  const [grauConversao, setGrauConversao] = useState("");
  const [receptivo, setReceptivo] = useState<string | null>(null);
  const [dataProxContato, setDataProxContato] = useState<any>(null);
  const [horaProxContato, setHoraProxContato] = useState("");
  const [obsPrimeiraInteracao, setObsPrimeiraInteracao] = useState("");
  const [idPlanoInteresse, setIdPlanoInteresse] = useState(null);

  const editorRef = useRef<any>();

  const limparDados = () => {
    setNomeEmpresa("");
    setIdCidade(null);
    setNomeContato("");
    setTelefone("");
    setReceptivo(null);
    setIdOrigem(null);
    setGrauConversao("");
    setDataProxContato(null);
    setHoraProxContato("");
    setObsPrimeiraInteracao("");
    setIdPlanoInteresse(null);
  };

  const fetchData = (data: any) => {
    setNomeEmpresa(data?.nome_empresa);
    setIdCidade(data?.id_cidade);
    setNomeContato(data?.nome_contato);
    setTelefone(data?.telefone);
    setIdOrigem(data?.id_origem);
    setGrauConversao(data?.grau_conversao);
    setReceptivo(data?.receptivo ? "R" : "A");
    setDataProxContato(
      data?.proximo_contato ? dateFromLocaleString(data.proximo_contato) : null
    );
    setHoraProxContato(data?.hora_proximo_contato);
    setIdPlanoInteresse(data?.id_plano_interesse);
  };

  const submitPayload = (action: any) => {
    if (nomeEmpresa.trim() === "") {
      toastr.warning("Atenção", "Por favor, informe o Nome da Empresa");
      return false;
    }

    if (["", null, undefined].includes(grauConversao)) {
      toastr.warning(
        "Atenção",
        "Por favor, informe o Grau de Conversão do Lead"
      );
      return false;
    }

    if ([0, null, undefined].includes(idOrigem)) {
      toastr.warning("Atenção", "Por favor, informe a Origem do Lead");
      return false;
    }
    if (["", null, undefined].includes(receptivo)) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a Forma de Contato (Ativo ou Receptivo)"
      );
      return false;
    }

    const payload = {
      nome_empresa: nomeEmpresa,
      id_cidade: idCidade ?? 0,
      nome_contato: nomeContato,
      telefone: telefone,
      receptivo: receptivo === "R",
      grau_conversao: grauConversao,
      id_origem: idOrigem,
      id_plano_interesse: idPlanoInteresse,
    };

    return action === ModalActions.add
      ? {
          ...payload,
          obs_prim_interacao: obsPrimeiraInteracao,
          id_colab_mencionados: extrairIdColabMencionados(editorRef),
          data_prox_contato:
            dataProxContato instanceof Date
              ? formatDateISO(dataProxContato)
              : null,
          hora_prox_contato:
            limparNumero(horaProxContato).length > 0 ? horaProxContato : null,
        }
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitle}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={LeadService.routesBase}
      number="011_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={12}
          label="Empresa"
          value={nomeEmpresa}
          onChange={setNomeEmpresa}
          maxLength={100}
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          label="Contato"
          value={nomeContato}
          onChange={setNomeContato}
          maxLength={100}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={7}
          isConcatField
          concatModelName="cidade"
          defaultOptions
          label="Cidade"
          isSearchable
          isClearable
          name="cidade"
          onChange={(v: any) => setIdCidade(v)}
          value={idCidade}
        />
        <PhoneInput
          md={5}
          label="Telefone"
          value={telefone}
          onChange={setTelefone}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={5}
          isConcatField
          concatModelName="plano_contratado"
          defaultOptions
          label="Plano de Interesse"
          isSearchable
          isClearable
          onChange={(v: any) => setIdPlanoInteresse(v)}
          value={idPlanoInteresse}
        />
        <RadioGroup
          label="Grau de Conversão"
          options={grausConversaoLead}
          value={grauConversao}
          onChange={setGrauConversao}
          divClassName="fb-2 pr-0"
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={5}
          isConcatField
          concatModelName="origem_cliente"
          defaultOptions
          label="Origem do Lead"
          isSearchable
          onChange={(v: any) => setIdOrigem(v)}
          value={idOrigem}
          className="pr-0"
        />
        <RadioGroup
          label="Tipo de Lead"
          value={receptivo}
          onChange={setReceptivo}
          options={receptivoLeadOptions}
          divClassName="fb-2 pr-0"
        />
      </Row>
      {action === ModalActions.add && (
        <>
          <Divider>Primeira Interação</Divider>
          <Row className="mb-3">
            <DatePicker
              md={4}
              label="Próximo Contato"
              value={dataProxContato}
              onChange={setDataProxContato}
            />
            <TextInput
              md={2}
              label="Hora"
              value={horaProxContato}
              onChange={setHoraProxContato}
              type="time"
            />
          </Row>
          <AtualizacoesEditor
            value={obsPrimeiraInteracao}
            onChange={setObsPrimeiraInteracao}
            formats={interacaoLeadEditorFormats}
            editorRef={editorRef}
          />
        </>
      )}
    </ModalCadastroV2>
  );
};
