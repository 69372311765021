import { Page } from "@react-pdf/renderer";
import { Orientation, Style } from "@react-pdf/types";
import { FC } from "react";

const _style = {
  paddingBottom: 16,
  paddingTop: 10,
  paddingHorizontal: 10,
};

interface Props {
  children?: any;
  orientation?: Orientation;
  style?: Style;
}

export const A4Page: FC<Props> = ({ children, orientation, style = {} }) => {
  return (
    <Page size="A4" style={{ ..._style, ...style }} orientation={orientation}>
      {children}
    </Page>
  );
};
