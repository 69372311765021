import { FC } from "react";
import { Table } from "../../../../../components/Table";
import { TableColumn } from "../../../../../components/table/TableRow";
import { extractMarkdownText, formatNumber } from "../../../../../coreUtils";

interface Props {
  data: any;
}

const columns: TableColumn[] = [
  { dataField: "id", text: "#", align: "center" },
  {
    dataField: "nome_colaborador",
    text: "Colaborador",
    align: "left",
  },
  { dataField: "data_envio_proposta", text: "Data", align: "center" },
  {
    dataField: "observ",
    text: "Detalhes da Proposta",
    align: "left",
    formatter: (c) => extractMarkdownText(c),
    fixedColWidth: true,
    colWidth: "60%",
  },
  {
    dataField: "vlr_maior_proposta",
    text: "Maior Valor",
    align: "right",
    formatter: (c: any) => formatNumber(c, true, 2),
    fixedColWidth: true,
    colWidth: "10%",
  },
];

export const PropostasLeadGrid: FC<Props> = ({ data }) => {
  return (
    <Table
      data={data}
      columns={columns}
      fixedSize={false}
      pageSize={data.length}
      showRegisterCount={false}
      paginated={false}
    />
  );
};
