import { FC, useState } from "react";
import { ModalBase } from "../../../../../components/ModalBase";
import { Table } from "../../../../../components/Table";
import { authGetV2 } from "../../../../../apiV2";
import { TableColumn } from "../../../../../components/table/TableRow";
import { Row } from "reactstrap";
import { FiltroPeriodoDatas } from "../../../../../components/FiltroPeriodoDatas";
import { BotaoPesquisar } from "../../../../../components";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  selected: any;
}

const columns: TableColumn[] = [
  { dataField: "ip_acesso", text: "IP Acesso", align: "center" },
  { dataField: "data_hora_log", text: "Data/Hora", align: "center" },
];

export const LogAcessosModal: FC<Props> = ({ isOpen, toggle, selected }) => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setDataIni(new Date());
    setDataFim(new Date());
    setDados([]);
  };

  const handleDate = (di: any, df: any) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
    };

    setLoading(true);
    const [ok, ret] = await authGetV2(
      `/log/acesso/listar/${selected}/`,
      params
    );
    setDados(ok ? ret : []);
    setLoading(false);
  };

  return (
    <ModalBase
      title="Acessos"
      number="003_2"
      isOpen={isOpen}
      toggle={toggle}
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
    >
      <Row className="mb-2">
        <FiltroPeriodoDatas onChange={handleDate} />
        <BotaoPesquisar onClick={carregarDados} loading={loading} />
      </Row>
      <Table columns={columns} data={dados} pageSize={10} />
    </ModalBase>
  );
};
