import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { handleFocus } from "../../coreUtils";
import { ComboBoxOption } from "../ComboBox";
import ComboBoxService from "../../services/components/ComboBoxService";
import { generateFilters } from "../AsyncComboBox";

interface Props {
  id?: any;
  name?: React.SelectHTMLAttributes<HTMLSelectElement>["name"];
  autoFocus?: React.SelectHTMLAttributes<HTMLSelectElement>["autoFocus"];
  tabOrder?: React.SelectHTMLAttributes<HTMLSelectElement>["tabIndex"];
  className?: React.SelectHTMLAttributes<HTMLSelectElement>["className"];
  options?: ComboBoxOption[];
  onBlur?: (v: any, e: any) => void;
  value?: any;
  onChange?: (v: any, e: any) => void;
  ref?: any;
  align?: React.CSSProperties["textAlign"];
  concatModelName?: string;
  label?: any;
}

export const TableAsyncCombobox: FC<Props> = forwardRef(
  (
    {
      id,
      name,
      autoFocus,
      tabOrder,
      className,
      onBlur,
      value,
      onChange,
      align,
      concatModelName,
      label,
    },
    ref
  ) => {
    const [internalId] = useState(
      id ?? "table-async-combobox-" + Math.floor(Math.random() * Date.now())
    );
    const [callOnBlur, setCallOnBlur] = useState(false);
    const inputRef = useRef<any>();
    const [options, setOptions] = useState<any[]>([]);
    const [internalLabel, setInternalLabel] = useState("");

    const fetchOptions = async (inputValue: any, searchById = false) => {
      if (!concatModelName) return [];
      let _result = [];
      const filters =
        inputValue !== ""
          ? generateFilters(concatModelName, inputValue, searchById)
          : {};

      const ret = await ComboBoxService.fetchOptions(concatModelName, filters);
      _result = ret;
      return _result;
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
      handleFocus(e);
    };

    const handleOnChange = (e: any) => {
      e.preventDefault();
      if (onChange) {
        const selValue = e.target.value || "";
        setCallOnBlur(true);
        onChange(selValue, e);
      }
    };

    const onBlurInternal = (e: any) => {
      if (onBlur) {
        const selValue = e.target.value || "";
        if (callOnBlur) onBlur(selValue, e);
      }
      setCallOnBlur(false);
    };

    useEffect(() => {
      setInternalLabel(label);
    }, [label]);

    useImperativeHandle(ref, () => ({
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
    }));

    return (
      <>
        <input
          id={internalId}
          autoFocus={autoFocus}
          name={name}
          tabIndex={tabOrder}
          className={className}
          ref={inputRef}
          onKeyDown={onKeyDown}
          onChange={handleOnChange}
          onBlur={onBlurInternal}
          value={internalLabel}
          style={{ textAlign: align }}
        />
        <datalist id={internalId}>
          <option disabled selected hidden style={{ textAlign: align }}>
            Selecione...
          </option>
          {options.map((e) => (
            <option value={e.value} style={{ textAlign: align }}>
              {e.label}
            </option>
          ))}
        </datalist>
      </>
    );
  }
);
