import PropTypes from "prop-types";
import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";

interface Props {
  required?: boolean;
  onChange?: any;
  md?: any;
  mdInput?: string | number;
  label?: any;
  style?: any;
  disabled?: boolean;
  defaultValue?: any;
  value?: any;
  placeholder?: any;
  ref?: any;
}
const NumberInput: FC<Props> = forwardRef(
  (
    {
      required = false,
      onChange,
      md,
      mdInput = 12,
      label,
      style,
      disabled = false,
      defaultValue,
      value,
      placeholder = "",
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState("");
    const [invalidMessage, setInvalidMessage] = useState("");
    const [invalid, setInvalid] = useState(false);

    useEffect(() => {
      if (defaultValue) setInputValue(defaultValue);
    }, []);

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    const inputIsValid = (value: any) => {
      const regex = RegExp(/^([0-9]+([.|,][0-9]*)?|[.|,][0-9]+)$/);
      if (value != null && value !== undefined) {
        if (!regex.test(String(value))) {
          setInvalidMessage("Valor inválido");
          return false;
        }
      } else if (required && !value) {
        setInvalidMessage("Esse campo é obrigatório");
        return false;
      }
      setInvalidMessage("");
      return true; // Vazio é válido
    };

    const checkFormat = (e: any) => {
      let value = (e.target.value || "").trim();
      const regex = RegExp(/(,|\.)/);
      const regexFim = RegExp(/(,|\.)$/);
      if (value) {
        if (regex.test(value)) {
          if (regexFim.test(value)) {
            value = `${value}00`;
          }
        } else {
          value = `${value},00`;
        }
        setInputValue(value);
      }

      setInvalid(!inputIsValid(value));
    };

    const handleOnChange = (e: any) => {
      e.preventDefault();
      const value = (e.target.value || "").trim();
      if (inputIsValid(value)) {
        setInvalid(false);
        setInputValue(value);
        if (onChange && value !== "") {
          // onChange props
          // Returns the Number version of the input
          onChange(Number.parseFloat(value.replace(",", ".")));
        }
      } else {
        setInvalid(true);
        setInputValue("");
        if (onChange) {
          onChange(0.0);
        }
      }
    };

    useImperativeHandle(ref, () => ({
      floatValue: () => {
        return inputIsValid(inputValue)
          ? Number.parseFloat((inputValue || "0.0").replace(",", "."))
          : 0.0;
      },
      clear: () => {
        setInputValue("");
      },
    }));

    return (
      <Col md={md}>
        <FormGroup>
          <Label>{label}</Label>
          <Col md={mdInput} className="no-gutters">
            <Input
              className="text-right"
              type="text"
              style={style || {}}
              onChange={handleOnChange}
              onBlur={checkFormat}
              value={inputValue}
              invalid={invalid}
              required={required}
              disabled={disabled}
              defaultValue={defaultValue}
              placeholder={placeholder}
            />
          </Col>
          <FormFeedback valid={false}>{invalidMessage}</FormFeedback>
        </FormGroup>
      </Col>
    );
  }
);

NumberInput.propTypes = {
  required: PropTypes.bool,
  onChange: PropTypes.func,
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

export default NumberInput;
