import { FC, useState } from "react";
import { Table } from "../../../../../components/Table";
import { TableColumn } from "../../../../../components/table/TableRow";
import {
  extractMarkdownText,
  formatValueFromAPI,
} from "../../../../../coreUtils";
import LeadService from "../../../../../services/components/comercial/LeadService";
import { ModalBase } from "../../../../../components/ModalBase";
import { useHistory } from "react-router-dom";
import { interacoesLeadRoute } from "../../../../../routes/modules/comercial";

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

const columns: TableColumn[] = [
  { dataField: "dh_interacao", text: "Data/Hora", align: "center" },
  {
    dataField: "nome_lead",
    text: "Lead",
    align: "left",
    formatter: (c: any, row: any) => formatValueFromAPI(c, row.id_lead),
  },
  {
    dataField: "nome_colaborador",
    text: "Colaborador",
    align: "left",
  },
  {
    dataField: "receptivo",
    text: "Interação",
    align: "center",
    formatter: (c: any) => (c ? "Receptiva" : "Ativa"),
  },
  {
    dataField: "observ",
    text: "Detalhes da Interação",
    align: "left",
    formatter: (c) => extractMarkdownText(c),
  },
];

export const InteracoesLeadsSemanaModal: FC<Props> = ({ isOpen, toggle }) => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const limparDados = () => {
    setDados([]);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await LeadService.interacao.listarSemana();
    if (!ok) {
      toggle();
    }
    setDados(ok ? ret : []);
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title="Interações com Leads na Semana"
      number="011_8"
      onClosed={limparDados}
      onBeforeOpen={carregarDados}
      loading={loading}
    >
      <Table
        data={dados}
        columns={columns}
        paginated={false}
        pageSize={15}
        onRowDoubleClick={(c: any, row: any) =>
          history.push(interacoesLeadRoute.path + `?id_lead=${row.id_lead}`)
        }
      />
    </ModalBase>
  );
};
