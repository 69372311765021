import { FC } from "react";
import { TabBody, TabBodyProps } from "../../../../components/TabController";
import { ItemCheckbox } from "./ItemCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { AtualizacoesSetorForm } from "./AtualizacoesSetorForm";
import ImplantacaoService from "../../../../services/ImplantacaoService";
import { setAtuFinanc, setupFinanc } from "../store/detalhesImplantSlice";

interface Props extends TabBodyProps {}

export const TabFinanceiro: FC<Props> = ({ title, active }) => {
  const store = useSelector((state: any) => state.detalhesImplant);
  const itens = store.financ;
  const dispatch = useDispatch();

  const carregarDados = async () => {
    const [ok, ret] = await ImplantacaoService.buscarItens(
      store.selected,
      "FIN"
    );
    if (ok) {
      dispatch(setupFinanc(ret));
    }
  };

  return (
    <TabBody title={title} active={active} onActivate={() => carregarDados()}>
      <ItemCheckbox
        label="Receber valor no caixa loja de implantação, personalização ou migração"
        name="rec_cxa_venda_implantacao"
        checked={itens.rec_cxa_venda_implantacao}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Gerar nota do pagamento da implantação (caso seja pix)"
        name="gera_nf_implantacao"
        checked={itens.gera_nf_implantacao}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Fazer contrato do cliente."
        name="contrato_gerado"
        checked={itens.contrato_gerado}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Preencher planilha de Comissionamento do Representante que conquistou o Cliente"
        name="planilha_comissao"
        checked={itens.planilha_comissao}
        notifyEvent={carregarDados}
      />
      <AtualizacoesSetorForm
        setor="FIN"
        abaAtiva={active ?? false}
        dados={itens.atualizacoes}
        setDados={(d) => dispatch(setAtuFinanc(d))}
      />
    </TabBody>
  );
};
