import { FC } from "react";

interface Props {
  children?: any;
  parametrizado?: any;
}

const NumberPageModal: FC<Props> = ({ children, parametrizado }) => {
  return (
    <span className="text-muted" style={{ marginRight: "auto" }}>
      [{children}] {parametrizado && " - P*"}
    </span>
  );
};

export default NumberPageModal;
