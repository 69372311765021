import { FC } from "react";
import { PDFReport } from "../../../../../components/pdf/PDFReport";
import {
  ReportColumnType,
  ReportDataList,
} from "../../../../../components/pdf/ReportDataList";

interface Props {
  dados?: any;
}

const columns: ReportColumnType[] = [
  {
    col: 0.4,
    dataField: "id",
    text: "#",
    align: "left",
  },
  {
    col: 5.4,
    dataField: "nome",
    text: "Nome",
    align: "left",
  },
  {
    col: 2.5,
    dataField: "nome_cidade",
    text: "Cidade",
    align: "left",
  },
  {
    col: 1.5,
    dataField: "whatsapp",
    text: "WhatsApp",
    align: "center",
  },
  {
    col: 1.5,
    dataField: "fone1",
    text: "Fone",
    align: "center",
  },
  {
    col: 0.7,
    dataField: "ativo",
    text: "Ativo",
    align: "right",
    formatter: (c: any) => (c ? "Sim" : "Não"),
  },
];

export const ContabilidadePDF: FC<Props> = ({ dados }) => {
  return (
    <PDFReport title="Contabilidades" number="013">
      <ReportDataList data={dados} columns={columns} />
    </PDFReport>
  );
};
