import { FC } from "react";
import { TableCheck } from "../../../../../components/TableCheck";
import { naturalSortDate } from "../../../../../coreUtils";
import { Table } from "../../../../../components/Table";
import { TableColumn } from "../../../../../components/table/TableRow";
import { iconeAlterar } from "../../../../../components/cadastro";
import { MoreDropdown } from "../../../../../components/MoreDropdown";
import { DropdownItem } from "reactstrap";

interface Props {
  data: any;
  setSelected: any;
  alterar: (id: any) => void;
  mostrarAcessos: (licenca: any) => void;
  podeAlterarDadosSensiveis: any;
  imprimirContrato: (licenca: any) => void;
}
export const EmpresasGrid: FC<Props> = ({
  data,
  setSelected,
  alterar,
  podeAlterarDadosSensiveis,
  mostrarAcessos,
  imprimirContrato,
}) => {
  const columns: TableColumn[] = [
    {
      dataField: "licenca",
      text: "Licença",
      align: "center",
      sortable: true,
    },
    {
      dataField: "nome_fant",
      text: "Nome Fantasia",
      align: "left",
      sortable: true,
    },
    {
      dataField: "razao_social",
      text: "Razão Social",
      align: "left",
      sortable: true,
    },
    {
      dataField: "cnpj",
      text: "CNPJ",
      align: "left",
    },
    {
      dataField: "sigla_user",
      text: "Sigla",
      align: "center",
      sortable: true,
    },
    {
      dataField: "data_lim",
      text: "Data Limite",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "icone_plano",
      text: "Plano",
      align: "center",
      formatter: (c) => <img src={c} />,
    },
    {
      dataField: "ativo",
      text: "Ativa",
      align: "center",
      formatter: (c: any) => <TableCheck value={c} />,
      sortable: true,
    },
    iconeAlterar(alterar, { keyField: "licenca" }),
    {
      dataField: "det",
      text: "",
      align: "center",
      selectOnClick: false,
      formatter: (c, row) => (
        <MoreDropdown>
          <DropdownItem onClick={() => mostrarAcessos(row.licenca)}>
            Log de Acessos
          </DropdownItem>
          {podeAlterarDadosSensiveis && (
            <DropdownItem onClick={() => imprimirContrato(row.licenca)}>
              Imprimir Contrato
            </DropdownItem>
          )}
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      keyField="licenca"
      onRowDoubleClick={alterar}
    />
  );
};
