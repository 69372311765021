import { FC, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import classNames from "classnames";
import { ModalActions } from "./cadastro/ModalCadastroV2";

const search: any = (tree: any, concatModelName: string) => {
  if (tree.concatModelName === concatModelName) {
    return tree;
  } else if (!tree.children) {
    return null;
  }

  for (const child of tree.children) {
    const found = search(child, concatModelName);

    if (found) {
      return found;
    }
  }
};

interface Props {
  concatModelName: string;
  selected?: any;
  setSelected?: (v: any) => void;
  onClick?: () => void;
  className?: string;
  ref?: any;
}

export const ConcatShortcut: FC<Props> = forwardRef(
  ({ concatModelName, selected, setSelected, onClick, className }, ref) => {
    const [internalId] = useState(
      "cs-" + Math.floor(Math.random() * Date.now())
    );
    const searchScope = {
      children: [],
    };
    const [shortcut] = useState(search(searchScope, concatModelName));

    const onClickRedirect = () => {
      const id = Math.floor(Math.random() * Date.now());
      const cadastro = window.open(shortcut.path) as any;

      if (cadastro) {
        cadastro.window.parameters = JSON.stringify({
          id: id,
          action: ![0, null, undefined, ""].includes(selected)
            ? ModalActions.edit
            : ModalActions.add,
          selected: selected,
        });
      }

      const func = (event: MessageEvent<any>) => {
        if (event.origin !== window.location.origin && !event.data?.selected) {
          return;
        }

        if (event.data.id !== id) {
          return;
        }

        if (setSelected) {
          setSelected(event.data.selected);
        }

        window.removeEventListener("message", func);
      };

      window.addEventListener("message", func);
    };

    useImperativeHandle(ref, () => ({
      click: () => document.getElementById(internalId)?.click(),
    }));

    return concatModelName && shortcut ? (
      <>
        <div
          id={internalId}
          className={classNames("react-select-shortcut", className)}
          onClick={onClick ?? onClickRedirect}
        >
          <shortcut.shortcutIcon size={20} color="white" />
        </div>
        <UncontrolledTooltip target={internalId}>
          F4 - Clique para acessar o Cadastro de {shortcut.name}
        </UncontrolledTooltip>
      </>
    ) : (
      <></>
    );
  }
);
