import { FC } from "react";
import { Table } from "../../../../../components/Table";
import { TableColumn } from "../../../../../components/table/TableRow";
import { TableCheck } from "../../../../../components/TableCheck";
import { iconeAlterar } from "../../../../../components/cadastro";
import { formatValueFromAPI, naturalSort } from "../../../../../coreUtils";

interface Props {
  data: any;
  setSelected: any;
  alterar: (id: any) => void;
}

export const ContabilidadeGrid: FC<Props> = ({
  data,
  setSelected,
  alterar,
}) => {
  const columns: TableColumn[] = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    { dataField: "nome", text: "Nome", align: "left", sortable: true },
    {
      dataField: "nome_cidade",
      text: "Cidade",
      align: "left",
      formatter: (c: any, row: any) => formatValueFromAPI(c, row.id_cidade),
      sortable: true,
    },
    {
      dataField: "whatsapp",
      text: "WhatsApp",
      align: "center",
      sortable: true,
    },
    { dataField: "fone1", text: "Fone", align: "center", sortable: true },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
      sortable: true,
    },
    iconeAlterar(alterar),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
      paginated={false}
    />
  );
};
