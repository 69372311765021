import Axios from "axios";
import { toastr } from "react-redux-toastr";
import { isDevEnv, viewDownloadPDF } from "./coreUtils";
import moment from "moment";

const baseURL = isDevEnv()
  ? "http://127.0.0.1:8000"
  : "https://ms-pdf.skillsoft.com.br";

const docService = Axios.create({
  baseURL: baseURL,
});

export const docPost = async (url, data, options) =>
  await docService
    .post(url, data, { responseType: "blob" })
    .then((ret) => ret.data)
    .catch(async (err) => {
      toastr.error(
        options.errorMessage ?? "Erro",
        err.response ? await err.response.data.text() : err.message
      );
      return false;
    });

export const docPrintReport = async (url, data, filename) => {
  const arq = await docPost(url, data, {
    errorMessage: "Erro ao Imprimir Relatório",
  });

  if (!filename) {
    filename = `${moment(new Date()).format("DDMMYYYY_hhmmss")}`;
  }

  if (arq) {
    viewDownloadPDF(arq, filename);
    return true;
  } else {
    return false;
  }
};
