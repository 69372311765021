import { FC } from "react";
import FormButton from "../FormButton";
import { ModalActions } from "./ModalCadastroV2";

interface Props {
  padded?: boolean;
  divClassName?: string;
  toggle: (action: any) => void;
  text?: any;
}

export const BotaoIncluir: FC<Props> = ({
  padded,
  divClassName,
  toggle,
  text = "Incluir",
}) => {
  return (
    <FormButton
      padded={padded}
      divClassName={divClassName}
      md="auto"
      color="info"
      onClick={() => toggle(ModalActions.add)}
    >
      {text}
    </FormButton>
  );
};
