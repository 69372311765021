import { Text, View } from "@react-pdf/renderer";
import { FC } from "react";

interface Props {
  reportNumber?: string;
}

export const ReportFooter: FC<Props> = ({ reportNumber = "XXXX" }) => {
  return (
    <View
      fixed
      style={{
        borderTop: "0.5px solid #000",
        marginTop: "10px",
        flexDirection: "row",
        position: "absolute",
        bottom: "5",
        left: "10",
      }}
    >
      <Text style={{ width: "52%", fontSize: 8, fontFamily: "Open Sans" }}>
        Copyright © Skillsoft Gestão e Software - www.skillsoft.com.br - [
        {reportNumber}]
      </Text>
      <Text
        render={({ pageNumber, totalPages }) =>
          `Página ${pageNumber} de ${totalPages}`
        }
        style={{
          textAlign: "right",
          width: "48%",
          fontFamily: "Open Sans",
          fontSize: 8,
        }}
      />
    </View>
  );
};
