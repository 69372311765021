import { cloneElement, FC, ReactElement, useRef, useState } from "react";
import { Edit2 } from "react-feather";

interface Props {
  children: ReactElement;
  enabled?: boolean;
}

export const UnlockToEdit: FC<Props> = ({ children, enabled }) => {
  const [locked, setLocked] = useState(true);
  const ref = useRef<any>();

  const toggleLock = () => {
    if (locked)
      setTimeout(() => {
        ref.current.props.innerRef.current.focus();
      }, 30);
    setLocked(!locked);
  };

  return enabled ? (
    <div style={{ display: "flex" }}>
      {cloneElement(children, {
        disabled: locked,
        ref: ref,
        onBlur: () => setLocked(true),
      })}

      <Edit2
        size={18}
        onMouseDown={() => toggleLock()}
        style={{
          cursor: "pointer",
          marginBlock: "auto",
          marginLeft: "0.4rem",
        }}
      />
    </div>
  ) : (
    children
  );
};
