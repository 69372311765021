import classNames from "classnames";
import React from "react";
import { Col, Button, ButtonGroup } from "reactstrap";

const TablePagination = ({
  currentPage,
  dataLength,
  pageSize,
  changePage,
  theme,
}) => {
  const buildPaginationControls = () => {
    const pageCount = Math.ceil(dataLength / pageSize);
    if (pageCount === 1) {
      return (
        <ButtonGroup size="sm" className="ml-auto">
          <Button color="primary" active>
            {currentPage}
          </Button>
        </ButtonGroup>
      );
    }

    return (
      <ButtonGroup size="sm" className="ml-auto">
        <Button
          color="primary"
          onClick={() => changePage(1)}
          disabled={currentPage < 4}
        >
          {"<<"}
        </Button>

        <Button
          color="primary"
          onClick={() => changePage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {"<"}
        </Button>

        {currentPage === pageCount && currentPage > 2 && (
          <Button color="primary" onClick={() => changePage(currentPage - 2)}>
            {currentPage - 2}
          </Button>
        )}
        {currentPage > 1 && (
          <Button color="primary" onClick={() => changePage(currentPage - 1)}>
            {currentPage - 1}
          </Button>
        )}
        <Button color="primary" active>
          {currentPage}
        </Button>
        {currentPage < pageCount && (
          <Button color="primary" onClick={() => changePage(currentPage + 1)}>
            {currentPage + 1}
          </Button>
        )}
        {currentPage === 1 && pageCount > 2 && (
          <Button color="primary" onClick={() => changePage(currentPage + 2)}>
            {currentPage + 2}
          </Button>
        )}

        <Button
          color="primary"
          onClick={() => changePage(currentPage + 1)}
          disabled={currentPage >= pageCount}
        >
          {">"}
        </Button>
        <Button
          color="primary"
          onClick={() => changePage(pageCount)}
          disabled={currentPage > pageCount - 4}
        >
          {">>"}
        </Button>
      </ButtonGroup>
    );
  };

  return (
    <Col
      sm={6}
      md={6}
      xs={6}
      lg={6}
      className={classNames(`${theme}-table-pagination-controls`, "ml-auto")}
    >
      {buildPaginationControls()}
    </Col>
  );
};

export default TablePagination;
