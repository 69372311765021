import { FC, useState } from "react";
import { Row } from "reactstrap";
import { TextInput } from "../../../../../components";
import {
  ModalActions,
  ModalCadastroProps,
  ModalCadastroV2,
} from "../../../../../components/cadastro/ModalCadastroV2";
import { modalTitleModulo, routesBaseModulo } from "../Modulo";
import { APIs } from "../../../../../apiV2";

interface Props {
  isOpen: ModalCadastroProps["isOpen"];
  toggle: ModalCadastroProps["toggle"];
  action: ModalCadastroProps["action"];
  selected: any;
  notifyEvent: ModalCadastroProps["notifyEvent"];
}

export const CadastroModuloModal: FC<Props> = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [descricao, setDescricao] = useState("");
  const [ativo, setAtivo] = useState(true);

  const limparDados = () => {
    setDescricao("");
    setAtivo(true);
  };

  const fetchData = (data: any) => {
    setDescricao(data.descricao);
    setAtivo(data.ativo);
  };

  const submitPayload = (action: any) => {
    const payload = {
      descricao: descricao,
      ativo: ativo,
    };

    return action === ModalActions.add ? payload : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleModulo}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseModulo}
      number="006_1"
      selected={selected}
      notifyEvent={notifyEvent}
      useApi={APIs.auth}
    >
      <Row>
        <TextInput
          label="Descrição"
          md={12}
          onChange={setDescricao}
          value={descricao}
        />
      </Row>
    </ModalCadastroV2>
  );
};
