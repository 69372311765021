import { FC } from "react";
import { useState } from "react";
import {
  modalTitleSistemaTerceiro,
  routesBaseSistemaTerceiro,
} from "../SistemaTerceiro";
import {
  ModalActions,
  ModalCadastroProps,
  ModalCadastroV2,
} from "../../../../../components/cadastro/ModalCadastroV2";
import { TextInput } from "../../../../../components";
import { Row } from "reactstrap";

interface Props {
  isOpen: ModalCadastroProps["isOpen"];
  toggle: ModalCadastroProps["toggle"];
  action: ModalCadastroProps["action"];
  selected: any;
  notifyEvent: ModalCadastroProps["notifyEvent"];
}

export const CadastroSistemaTerceiroModal: FC<Props> = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nome, setNome] = useState("");
  const [observ, setObserv] = useState("");

  const limparDados = () => {
    setNome("");
    setObserv("");
  };

  const fetchData = (data: any) => {
    setNome(data.nome);
    setObserv(data.observ);
  };

  const submitPayload = (action: ModalActions) => {
    const payload = {
      nome: nome,
      observ: observ,
    };

    return action === ModalActions.add ? payload : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleSistemaTerceiro}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseSistemaTerceiro}
      number="017_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={8}
          label="Nome"
          value={nome}
          onChange={setNome}
          maxLength={60}
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          label="Observação"
          type="textarea"
          rows={4}
          value={observ}
          onChange={setObserv}
        />
      </Row>
    </ModalCadastroV2>
  );
};
