import React, { FC } from "react";

interface Props {
  style?: React.CSSProperties;
  children?: any;
}

const ModalHeaderActionArea: FC<Props> = ({ style, children }) => {
  return (
    <div
      className="position-absolute"
      style={{
        right: "3.8rem",
        top: "1.09rem",
        display: "flex",
        alignItems: "baseline",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
export default ModalHeaderActionArea;
