import { RouteType } from "..";
import { MenuGroup } from "../../components/MenuGroup";
import { Contabilidade } from "../../pages/administrativo/cadastros/contabilidade/Contabilidade";
import { Empresa } from "../../pages/administrativo/cadastros/empresa/Empresa";
import { PlanoContratado } from "../../pages/administrativo/cadastros/plano_contratado/PlanoContratado";
import { Usuarios } from "../../pages/administrativo/cadastros/usuarios/Usuarios";
import { LuCalculator, LuUser } from "react-icons/lu";
import { LiaFileContractSolid, LiaStoreAltSolid } from "react-icons/lia";
import { Pop } from "../../pages/administrativo/procedimentos/pop/Pop";
import { BsCardChecklist } from "react-icons/bs";
import { SistemaTerceiro } from "../../pages/administrativo/cadastros/sistema_terceiro/SistemaTerceiro";
import { RiComputerLine } from "react-icons/ri";

export const administrativoCadastrosRoute: RouteType = {
  name: "Cadastros",
  path: "/administrativo/cadastros/",
  children: [
    {
      id: "AcSk013",
      path: "/administrativo/cadastros/contabilidade/",
      name: "Contabilidade",
      component: Contabilidade,
      icon: LuCalculator,
    },
    {
      id: "AcSk003",
      path: "/administrativo/cadastros/empresa/",
      name: "Empresa",
      component: Empresa,
      icon: LiaStoreAltSolid,
    },
    {
      id: "AcSk010",
      path: "/administrativo/cadastros/plano_contratado/",
      name: "Plano Contratado",
      component: PlanoContratado,
      icon: LiaFileContractSolid,
    },
    {
      id: "AcSk017",
      path: "/administrativo/cadastros/sistema_terceiro/",
      name: "Sistema Terceiro",
      component: SistemaTerceiro,
      icon: RiComputerLine,
    },
    {
      id: "AcSk002",
      path: "/administrativo/cadastros/usuarios/",
      name: "Usuários",
      component: Usuarios,
      icon: LuUser,
    },
  ],
};

export const administrativoProcessosRoute: RouteType = {
  name: "Processos",
  path: "/administrativo/processos/",
  children: [
    {
      id: "AcSk007",
      path: "/administrativo/processos/pops/",
      name: "POPs",
      component: Pop,
      icon: BsCardChecklist,
      hint: "Procedimento Operacional Padrão",
    },
  ],
};

export const administrativoRoutes: RouteType = {
  name: "Administrativo",
  path: "/administrativo/",
  component: () =>
    MenuGroup({
      routes: [administrativoCadastrosRoute, administrativoProcessosRoute],
      title: "Administrativo",
    }),
};
