import { useState, FC } from "react";
import { Row } from "reactstrap";
import { AsyncComboBox, TextInput } from "../../../../../components";
import { toastr } from "react-redux-toastr";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { APIs } from "../../../../../apiV2";
import {
  ModalActions,
  ModalCadastroProps,
} from "../../../../../components/cadastro/ModalCadastroV2";
import { modalTitleMenu, routesBaseMenu } from "../Menu";
import MaskedInput from "../../../../../components/MaskedInput";
import { HintLabel } from "../../../../../components/HintLabel";

interface Props {
  isOpen: ModalCadastroProps["isOpen"];
  toggle: ModalCadastroProps["toggle"];
  action: ModalCadastroProps["action"];
  selected: any;
  notifyEvent: any;
}

export const CadastroMenuModal: FC<Props> = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nroTela, setNroTela] = useState<string | null>(null);
  const [idModulo, setIdModulo] = useState(null);
  const [idPlano, setIdPlano] = useState(null);
  const [descricao, setDescricao] = useState("");
  const [obs, setObs] = useState("");
  const [baseInformacao, setBaseInformacao] = useState("");
  const [objetivo, setObjetivo] = useState("");

  const limparDados = () => {
    setNroTela(null);
    setIdModulo(null);
    setIdPlano(null);
    setDescricao("");
    setObs("");
    setBaseInformacao("");
    setObjetivo("");
  };

  const fetchData = (data: any) => {
    setNroTela(data.nro_tela);
    setIdModulo(data.id_modulo);
    setIdPlano(data.id_plano);
    setDescricao(data.descricao);
    setObs(data.obs ?? "");
    setBaseInformacao(data.base_informacao ?? "");
    setObjetivo(data.objetivo ?? "");
  };

  const submitPayload = (action: any) => {
    if (["", null, undefined].includes(nroTela)) {
      toastr.warning("Atenção", "Por favor, informe o Número da Tela");
      return false;
    }

    const nroTelaFinal = nroTela!.replaceAll("_", "");

    if (nroTelaFinal.length < 4) {
      toastr.warning(
        "Atenção",
        "O Número da tela deve possuir ao menos 4 dígitos"
      );
      return false;
    }

    if ([0, null, undefined].includes(idModulo)) {
      toastr.warning("Atenção", "Por favor, informe o Módulo");
      return false;
    }
    if ([0, null, undefined].includes(idPlano)) {
      toastr.warning("Atenção", "Por favor, informe o Plano");
      return false;
    }
    if (["", null, undefined].includes(descricao)) {
      toastr.warning("Atenção", "Por favor, informe a Descrição do Menu");
      return false;
    }

    const payload = {
      nro_tela: nroTelaFinal,
      id_modulo: idModulo,
      id_plano: idPlano,
      descricao: descricao,
      obs: obs,
      base_informacao: baseInformacao,
      objetivo: objetivo,
    };

    return action === ModalActions.add ? payload : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleMenu}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseMenu}
      number="012_1"
      selected={selected}
      notifyEvent={notifyEvent}
      useApi={APIs.auth}
    >
      <Row>
        <MaskedInput
          mask="9999"
          label="Número"
          md={3}
          onChange={setNroTela}
          value={nroTela}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={8}
          isConcatField
          concatModelName="modulo"
          defaultOptions
          label="Módulo"
          isSearchable
          onChange={setIdModulo}
          value={idModulo}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={8}
          isConcatField
          concatModelName="plano_contratado"
          defaultOptions
          label="Plano"
          isSearchable
          onChange={setIdPlano}
          value={idPlano}
        />
      </Row>
      <Row>
        <TextInput
          label="Descrição"
          md={9}
          onChange={setDescricao}
          value={descricao}
          maxLength={60}
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          label="Observação"
          value={obs}
          onChange={setObs}
          type="textarea"
          rows={3}
          upperCaseValue={false}
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          label={
            <HintLabel
              label="Base de Informações"
              hintText="Texto a ser exibido na Base de Informações da Tela no Sistema"
            />
          }
          value={baseInformacao}
          onChange={setBaseInformacao}
          type="textarea"
          rows={3}
          upperCaseValue={false}
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          label={
            <HintLabel
              label="Objetivo"
              hintText="Texto a ser exibido na Base de Informações da Tela no Sistema"
            />
          }
          value={objetivo}
          onChange={setObjetivo}
          type="textarea"
          rows={3}
          upperCaseValue={false}
        />
      </Row>
    </ModalCadastroV2>
  );
};
