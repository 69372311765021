import { FC, useState } from "react";
import { Col, CustomInput, Label } from "reactstrap";

interface Props {
  label?: any;
  md?: any;
  id?: any;
  name?: string;
  onChange?: any;
  checked?: boolean;
  defaultChecked?: boolean;
  divClassName?: string;
  className?: string;
  disabled?: boolean;
  padded?: boolean;
  style?: React.CSSProperties;
}
export const FormCheckbox: FC<Props> = ({
  label,
  md = "auto",
  name,
  id,
  onChange,
  checked,
  defaultChecked = false,
  divClassName,
  className,
  disabled,
  padded = true,
  style,
}) => {
  const [internalId] = useState(
    id ?? "ch-" + Math.floor(Math.random() * Date.now())
  );
  return (
    <Col md={md} className={divClassName}>
      {padded && <Label>&#8205;</Label>}
      <CustomInput
        type="checkbox"
        name={name}
        id={internalId}
        checked={checked}
        onChange={(e) => onChange && onChange(!checked, e)}
        htmlFor={internalId}
        label={label}
        disabled={disabled}
        defaultChecked={defaultChecked}
        className={className}
        style={style}
      />
    </Col>
  );
};
