import { FC, useState } from "react";
import { Row } from "reactstrap";
import { TextInput } from "../../../../../components";
import { MarkdownInput } from "../../../../../components/MarkdownInput";
import {
  ModalActions,
  ModalCadastroProps,
  ModalCadastroV2,
} from "../../../../../components/cadastro/ModalCadastroV2";
import { modalTitlePop, routesBasePop } from "../Pop";

interface Props {
  isOpen: ModalCadastroProps["isOpen"];
  toggle: ModalCadastroProps["toggle"];
  action: ModalCadastroProps["action"];
  selected: any;
  notifyEvent: ModalCadastroProps["notifyEvent"];
}

export const CadastroPopModal: FC<Props> = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [titulo, setTitulo] = useState("");
  const [ativo, setAtivo] = useState(true);
  const [markdown, setMarkdown] = useState("");

  const limparDados = () => {
    setTitulo("");
    setMarkdown("");
    setAtivo(true);
  };

  const fetchData = (data: any) => {
    setTitulo(data.titulo);
    setMarkdown(data.markdown);
    setAtivo(data.ativo);
  };

  const submitPayload = (action: any) => {
    const payload = {
      titulo: titulo,
      markdown: markdown,
      ativo: ativo,
    };

    return action === ModalActions.add ? payload : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitlePop}
      size="xl"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBasePop}
      number="007_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput label="Título" md={12} onChange={setTitulo} value={titulo} />
      </Row>
      <MarkdownInput value={markdown} onChange={setMarkdown} />
    </ModalCadastroV2>
  );
};
