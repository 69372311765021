import { apiGetV2 } from "../apiV2";
import { docPrintReport } from "../pdf";

const ContratoService = {
  imprimirContrato: async (licenca) => {
    const [ok, ret] = await apiGetV2(
      `/cadastro/buscar_dados_impressao_contrato/${licenca}/`
    );

    if (ok) {
      await docPrintReport(
        "/contratos/contrato_skill/",
        ret,
        `Contrato Licença ${licenca}`
      );
    }
  },
};

export default ContratoService;
