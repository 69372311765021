import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Row, UncontrolledTooltip } from "reactstrap";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { CgDetailsLess, CgDetailsMore } from "react-icons/cg";
import { BarLoader } from "react-spinners";
import { compareArraysNoOrder, naturalGenericSort } from "../coreUtils";
import TableRecordCount from "./table/TableRecordCount";
import TablePagination from "./table/TablePagination";
import TableRow, { TableColumn } from "./table/TableRow";
import { IconButton } from "./IconButton";

export interface EditModeInfo {
  onEdit?: any;
  rowKeyField?: any;
  dataField?: any;
}

interface ExpandedRowInfo {
  rowKeyField: any;
  expanded: any;
}

interface SortInfo {
  column: any;
  desc: boolean;
  sortFunc?: ((a: any, b: any, order: "asc" | "desc") => number) | null;
}

const __defaultEditModeInfo: EditModeInfo = {
  onEdit: false,
  rowKeyField: null,
  dataField: null,
};

const __defaultSortInfo: SortInfo = {
  column: null,
  desc: true,
  sortFunc: null,
};

const sortIconSize = 12;
const sortIconMarginLeft = 1;
const sortIconSpacePadding = (sortIconSize + sortIconMarginLeft) / 2;
const alturaLinha = 29.7812;

interface Props {
  id?: any;
  columns: Array<TableColumn>;
  data: Array<any>;
  striped?: boolean;
  hover?: boolean;
  theme?: string;
  paginated?: boolean;
  pageSize?: number;
  fixedSize?: boolean;
  multiselect?: boolean;
  rowSelect?: boolean;
  onSelect?: any;
  keyField?: string;
  canExpand?: boolean;
  expandedAreaBuilder?: (row: any) => any;
  expandedAreaStyle?: React.CSSProperties;
  expandedAreaClassName?: string;
  loading?: boolean;
  headerStyle?: React.CSSProperties;
  clearSelectionOnUpdate?: boolean;
  clipText?: boolean;
  onRowEnterPress?: any;
  onRowDoubleClick?: any;
  showRegisterCount?: boolean;
  selectColumnPosition?: "left" | "right";
  rowStyle?:
    | ((row: any, index: number) => React.CSSProperties)
    | React.CSSProperties;
  multiExpand?: boolean;
  bgStyle?: React.CSSProperties;
  selected?: any | any[];
  onSelectAll?: (isSelected: boolean) => void;
  hideSelectColumn?: boolean;
  footerStyle?: React.CSSProperties;
  growIntoPageSize?: boolean;
}

export const Table: FC<Props> = ({
  id,
  columns,
  data = [],
  striped = true,
  hover = true,
  theme = "default",
  paginated = true,
  pageSize = 20,
  fixedSize = true,
  multiselect = false,
  rowSelect = true,
  onSelect,
  keyField = "id",
  canExpand = false,
  expandedAreaBuilder,
  expandedAreaStyle,
  expandedAreaClassName = "p-2",
  loading = false,
  headerStyle = {},
  clearSelectionOnUpdate = false,
  clipText = false,
  onRowEnterPress,
  onRowDoubleClick,
  showRegisterCount = true,
  selectColumnPosition = "left",
  rowStyle,
  multiExpand = false,
  bgStyle = {},
  selected,
  onSelectAll,
  hideSelectColumn = false,
  footerStyle,
  growIntoPageSize = false,
}) => {
  const [selectedIDs, setSelectedIDS] = useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortInfo, setSortInfo] = useState(__defaultSortInfo);
  const [onEditModeInfo, setEditModeInfo] = useState(__defaultEditModeInfo);
  const [expandedRows, setExpandedRows] = useState<Array<any>>([]);
  const [internalId] = useState(
    id ?? "table-" + Math.floor(Math.random() * Date.now())
  );

  const selectAllRef = useRef<any>();

  const onSelectInternal = (value: any, checked: any, row: any) => {
    if (checked) {
      if (multiselect === true && selectedIDs.length > 0) {
        setSelectedIDS([...selectedIDs, value]);
      } else {
        setSelectedIDS([value]);
      }
    } else {
      if (multiselect === true) {
        setSelectedIDS([...selectedIDs.filter((element) => element !== value)]);
      } else {
        setSelectedIDS([]);
      }
    }

    if (onSelect) {
      onSelect(value, checked, row);
    }
  };

  const onSelectAllInternal = (isSelected: boolean) => {
    setSelectedIDS(isSelected ? data.map((e) => e[keyField]) : []);
    if (onSelectAll) {
      onSelectAll(isSelected);
    }
  };

  const handleRowClick = (value: any, row: any) => {
    if (rowSelect) {
      let checked;
      if (multiselect) {
        checked = !selectedIDs.includes(value);
      } else {
        checked = true;
      }
      onSelectInternal(value, checked, row);
    }
    if (canExpand && expandedAreaBuilder) {
      const rowKey = row[keyField];
      const expanded = expandedRows.includes(rowKey);
      if (multiExpand) {
        setExpandedRows(
          !expanded
            ? [...expandedRows, rowKey]
            : expandedRows.filter((e) => e !== rowKey)
        );
      } else {
        setExpandedRows(!expanded ? [rowKey] : []);
      }
    }
  };

  const toggleExpandAll = (e: any) => {
    e.stopPropagation();
    setExpandedRows(
      expandedRows.length > 0 ? [] : data.map((r) => r[keyField])
    );
  };

  const checkOnEdit = (row: any, dataField: string) => {
    return (
      onEditModeInfo.onEdit &&
      onEditModeInfo.rowKeyField === row[keyField] &&
      onEditModeInfo.dataField === dataField
    );
  };

  const endEdit = () => {
    setEditModeInfo(__defaultEditModeInfo);
  };

  const updateSelectedIDs = () => {
    if (selected) {
      if (multiselect) {
        if (selected instanceof Array) {
          if (!compareArraysNoOrder(selected, selectedIDs)) {
            setSelectedIDS(selected);
          }
        }
      } else {
        const selArray = [selected];
        if (selArray !== selectedIDs) {
          setSelectedIDS([selected]);
        }
      }
    }
  };

  useEffect(() => {
    updateSelectedIDs();
  }, [selected]);

  const deselectValues = useCallback(() => {
    const idsData = (data ?? []).map((e) => e[keyField]);
    const excludedIds = selectedIDs.filter((e) => !idsData.includes(e));
    setSelectedIDS(selectedIDs.filter((e) => !excludedIds.includes(e)));
    if (onSelect) {
      excludedIds.forEach((e) => onSelect(e, false, undefined));
    }
  }, [data]);

  const checkAllSelected = () => {
    if (multiselect && selectAllRef.current) {
      selectAllRef.current.checked =
        selectedIDs.length > 0 && selectedIDs.length === (data ?? []).length;
      selectAllRef.current.indeterminate =
        selectedIDs.length > 0 && selectedIDs.length < (data ?? []).length;
    }
  };

  useEffect(() => {
    if ((data ?? []).length < selectedIDs.length) {
      if (!clearSelectionOnUpdate) {
        deselectValues();
      }
    } else {
      checkAllSelected();
    }
  }, [multiselect, selectedIDs, data]);

  useEffect(() => {
    if (clearSelectionOnUpdate) {
      if (multiselect) {
        selectedIDs.forEach((element) => {
          onSelect(element, false, undefined);
        });
      } else {
        onSelect(null, false, undefined);
      }
      setSelectedIDS([]);
    }
  }, [clearSelectionOnUpdate, data]);

  useEffect(() => {
    if (data?.length > 0) {
      const pageCount = Math.ceil(data?.length / pageSize);
      if (currentPage > pageCount) {
        setCurrentPage(pageCount);
      }
    }
  }, [data, currentPage, pageSize]);

  let rowsToShow;
  let minHeight;
  minHeight = (pageSize + (data?.length <= pageSize ? 1 : 0)) * alturaLinha;
  const maxHeight = minHeight;
  if (growIntoPageSize) {
    if (pageSize > (data?.length ?? 0)) {
      minHeight = ((data?.length ?? 0) + 1) * alturaLinha;
    } else {
      minHeight = (pageSize + (data?.length <= pageSize ? 1 : 0)) * alturaLinha;
    }
  }

  if (sortInfo.column) {
    data.sort((a, b) => {
      if (sortInfo.sortFunc) {
        return sortInfo.sortFunc(
          a[sortInfo.column],
          b[sortInfo.column],
          sortInfo.desc ? "desc" : "asc"
        );
      } else {
        return naturalGenericSort(
          a[sortInfo.column],
          b[sortInfo.column],
          sortInfo.desc ? "desc" : "asc"
        );
      }
    });
  }
  if (paginated === true) {
    const currentIndex = (currentPage - 1) * pageSize;
    rowsToShow = data?.slice(currentIndex, currentIndex + pageSize);
  } else {
    rowsToShow = data;
  }

  const calculateVisibleColumnCount = () => {
    let visibleColCount = columns.reduce(
      (acumulator, col) => (col.hidden ? acumulator : acumulator + 1),
      0
    );
    if (onSelect) visibleColCount += 1;
    return visibleColCount;
  };

  const visibleColumns = columns.filter((e) => !e.hidden);

  const containerStyle: React.CSSProperties = {
    minHeight: growIntoPageSize ? undefined : `${minHeight}px`,
    height: fixedSize && !growIntoPageSize ? `${minHeight}px` : "auto",
    maxHeight: fixedSize ? maxHeight : undefined,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  };

  if (!paginated) {
    containerStyle["overflow"] = "auto";
  }

  let selectColumnHeader = () => (
    <>
      <th data-row-selection={true}>
        {multiselect && (
          <input
            style={{
              position: "relative",
              top: "2.5px",
            }}
            type="checkbox"
            ref={selectAllRef}
            onChange={({ target: { checked } }) => onSelectAllInternal(checked)}
            disabled={data.length === 0}
          />
        )}
      </th>
    </>
  );

  return (
    <div
      style={{ borderRadius: "10px", position: "relative", ...bgStyle }}
      className="bg-white"
    >
      <div style={containerStyle}>
        <table
          className={`p-0 ${theme}-table ${clipText ? "clip-text-table" : ""} ${
            fixedSize ? "fixed-size-table" : ""
          }`}
        >
          <thead>
            <tr>
              {onSelect &&
                !hideSelectColumn &&
                selectColumnPosition !== "right" &&
                selectColumnHeader()}
              {visibleColumns.map(
                (
                  {
                    text,
                    colWidth,
                    dataField,
                    sortable,
                    sortFunc,
                    fixedColWidth,
                    colHeaderStyle,
                    hint,
                    hideSortIcon,
                  },
                  colIndex
                ) => {
                  const style: React.CSSProperties = {
                    maxWidth: colWidth,
                    minWidth: colWidth,
                    textAlign: "center",
                  };

                  if (fixedColWidth) {
                    style["width"] = colWidth;
                  }

                  const tooltipId = `column-header-${colIndex}-${dataField}`;

                  let child = text;
                  if (sortable && !hideSortIcon) {
                    if (sortInfo.column === dataField) {
                      child = (
                        <>
                          {text}
                          {sortInfo.desc ? (
                            <FaSortDown
                              size={sortIconSize}
                              style={{ marginLeft: `${sortIconMarginLeft}px` }}
                              id={tooltipId}
                            />
                          ) : (
                            <FaSortUp
                              size={sortIconSize}
                              style={{ marginLeft: `${sortIconMarginLeft}px` }}
                              id={tooltipId}
                            />
                          )}
                          {hint && (
                            <UncontrolledTooltip target={tooltipId}>
                              {hint}
                            </UncontrolledTooltip>
                          )}
                        </>
                      );
                    } else {
                      child = (
                        <>
                          <div
                            style={{
                              paddingInline: `${sortIconSpacePadding}px`,
                            }}
                            id={tooltipId}
                          >
                            {text}
                          </div>
                          {hint && (
                            <UncontrolledTooltip target={tooltipId}>
                              {hint}
                            </UncontrolledTooltip>
                          )}
                        </>
                      );
                    }
                  }

                  if (
                    colIndex === 0 &&
                    canExpand &&
                    multiExpand &&
                    expandedAreaBuilder
                  ) {
                    const expandIcon =
                      expandedRows.length < data.length
                        ? CgDetailsLess
                        : CgDetailsMore;

                    child = (
                      <>
                        <IconButton
                          icon={expandIcon}
                          style={{ position: "absolute", left: "10px" }}
                          onClick={toggleExpandAll}
                        />
                        {child}
                      </>
                    );
                  }

                  return (
                    <th
                      style={{ ...style, ...headerStyle, ...colHeaderStyle }}
                      onClick={() => {
                        if (sortable) {
                          setSortInfo({
                            column: dataField,
                            desc: !sortInfo.desc,
                            sortFunc: sortFunc,
                          });
                        }
                      }}
                    >
                      {child}
                    </th>
                  );
                }
              )}
              {onSelect &&
                !hideSelectColumn &&
                selectColumnPosition === "right" &&
                selectColumnHeader()}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={calculateVisibleColumnCount()}>
                  <div
                    style={{
                      padding: "2.5rem 0.5rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <BarLoader width={250} />
                  </div>
                </td>
              </tr>
            ) : (
              rowsToShow.map((row, idx) => {
                let internalRowStyle =
                  typeof rowStyle === "function"
                    ? rowStyle(row, idx)
                    : rowStyle;

                if (expandedRows.includes(row[keyField])) {
                  internalRowStyle = {
                    backgroundColor: "#ffffd2",
                    ...rowStyle,
                  };
                }

                const tableRow = (
                  <TableRow
                    tableId={internalId}
                    row={row}
                    index={idx}
                    keyField={keyField}
                    rowSelect={rowSelect}
                    selectable={onSelect}
                    onSelect={onSelectInternal}
                    handleRowClick={handleRowClick}
                    multiselect={multiselect}
                    isSelected={selectedIDs.includes(row[keyField])}
                    hover={hover}
                    striped={striped}
                    checkOnEdit={checkOnEdit}
                    columns={visibleColumns}
                    endEdit={endEdit}
                    setEditModeInfo={setEditModeInfo}
                    onRowEnterPress={onRowEnterPress}
                    onRowDoubleClick={onRowDoubleClick}
                    selectColumnPosition={selectColumnPosition}
                    rowStyle={internalRowStyle}
                    hideSelectColumn={hideSelectColumn}
                  />
                );
                let expandedArea;
                if (
                  expandedRows.includes(row[keyField]) &&
                  expandedAreaBuilder
                ) {
                  expandedArea = (
                    <tr>
                      <td colSpan={calculateVisibleColumnCount()}>
                        <div
                          style={{
                            backgroundColor: "#248086",
                            borderRadius: "10px",
                            ...expandedAreaStyle,
                          }}
                          className={expandedAreaClassName}
                        >
                          {expandedAreaBuilder(row)}
                        </div>
                      </td>
                    </tr>
                  );
                }
                return (
                  <>
                    {tableRow}
                    {expandedArea && expandedArea}
                  </>
                );
              })
            )}
          </tbody>
          {(data ?? []).length > 0 && visibleColumns.some((e) => e.footer) && (
            <tfoot
              style={{
                position: "sticky",
                bottom: 0,
                backgroundColor: bgStyle?.backgroundColor ?? "white",
              }}
            >
              <tr>
                {visibleColumns.map(
                  ({
                    footer,
                    align,
                    colWidth,
                    fixedColWidth,
                    colFooterStyle,
                  }) => {
                    const style: React.CSSProperties = {
                      maxWidth: colWidth,
                      minWidth: colWidth,
                      textAlign: align,
                    };

                    if (fixedColWidth) {
                      style["width"] = colWidth;
                    }

                    let child = footer;

                    return (
                      <td
                        style={{ ...style, ...footerStyle, ...colFooterStyle }}
                      >
                        {child}
                      </td>
                    );
                  }
                )}
              </tr>
            </tfoot>
          )}
        </table>
      </div>
      {(showRegisterCount || paginated) && (
        <Row className="p-2">
          {showRegisterCount && (
            <TableRecordCount dataLength={data?.length ?? 0} theme={theme} />
          )}
          {data?.length > 0 && paginated && data?.length > pageSize && (
            <TablePagination
              dataLength={data.length}
              currentPage={currentPage}
              changePage={setCurrentPage}
              pageSize={pageSize}
              theme={theme}
            />
          )}
        </Row>
      )}
    </div>
  );
};
