import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { handleFocus } from "../coreUtils";
import { UnlockToEdit } from "./UnlockToEdit";
import { InputType } from "reactstrap/lib/Input";

interface Props {
  md?: number;
  mdInput?: number;
  label?: any;
  type?: InputType;
  name?: string;
  onChange?: (v: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  className?: string;
  divClassName?: string;
  inline?: boolean;
  check?: any;
  required?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  placeholder?: any;
  maxLength?: number;
  autoFocus?: boolean;
  upperCaseValue?: boolean;
  ref?: any;
  style?: React.CSSProperties;
  updateEveryRender?: boolean;
  addonText?: String;
  addonType?: String;
  rows?: number;
  unlockToEdit?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onBlur?: (v: string, e: React.FocusEvent<HTMLInputElement>) => void;
}

const TextInput: FC<Props> = forwardRef(
  (
    {
      md,
      mdInput = 12,
      label,
      type = "text",
      name,
      onChange,
      value,
      className,
      divClassName,
      inline = false,
      check = false,
      required = false,
      invalid = false,
      disabled = false,
      placeholder = "",
      maxLength,
      autoFocus,
      upperCaseValue = false,
      style,
      updateEveryRender = false,
      addonText,
      addonType = "append",
      rows,
      unlockToEdit = false,
      onKeyDown,
      onBlur,
    },
    ref
  ) => {
    const [innerValidation, setInnerValidation] = useState(true);
    const [internalValue, setInternalValue] = useState("");
    const inputRef = useRef<any>("");

    const validateInternally = (e: any) => {
      if (required && e.target.value === "") {
        setInnerValidation(false);
      } else {
        setInnerValidation(true);
      }
    };

    const onBlurInternal = (e: any) => {
      validateInternally(e);
      if (onBlur) onBlur(e.target.value, e);
    };

    useEffect(
      () => setInternalValue(upperCaseValue ? value?.toUpperCase() : value),
      updateEveryRender ? undefined : [value]
    );

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const inputValue =
        (upperCaseValue ? e.target.value?.toUpperCase() : e.target.value) || "";
      setInternalValue(inputValue);
      if (onChange) {
        onChange(inputValue, e);
      }
    };

    useImperativeHandle(ref, () => ({
      isValid: () => innerValidation && !invalid,
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
      value: upperCaseValue
        ? internalValue?.toUpperCase()?.trim()
        : internalValue.trim(),
      setValue: (val: any) => setInternalValue(val),
    }));

    const onKeyDownInternal = (e: React.KeyboardEvent) => {
      if (type !== "textarea" || (e.key !== "Enter" && e.key !== "ArrowUp")) {
        handleFocus(e);
      }
      if (onKeyDown) {
        onKeyDown(e);
      }
    };

    const internalInput = (
      <Input
        type={type}
        name={name}
        onChange={handleOnChange}
        onBlur={onBlurInternal}
        defaultValue={value}
        innerRef={inputRef}
        className={className}
        required={required}
        value={internalValue}
        invalid={!innerValidation || invalid}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
        autoFocus={autoFocus}
        onKeyDown={onKeyDownInternal}
        style={style}
        rows={rows}
      />
    );

    return (
      <Col md={md} className={divClassName}>
        <FormGroup check={check} inline={inline}>
          <Label check={check}>{label}</Label>
          <Col md={mdInput} className="no-gutters">
            <UnlockToEdit enabled={unlockToEdit}>
              {addonText ? (
                <InputGroup>
                  {addonType === "prepend" && (
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>{addonText}</InputGroupText>
                    </InputGroupAddon>
                  )}
                  {internalInput}
                  {addonType === "append" && (
                    <InputGroupAddon addonType="append">
                      <InputGroupText>{addonText}</InputGroupText>
                    </InputGroupAddon>
                  )}
                </InputGroup>
              ) : (
                internalInput
              )}
            </UnlockToEdit>
          </Col>
          <FormFeedback valid={false}>Esse campo é obrigatório</FormFeedback>
        </FormGroup>
      </Col>
    );
  }
);

export default TextInput;
