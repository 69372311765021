import { FC, useEffect, useState } from "react";
import { ModalBase } from "./ModalBase";
import { Label } from "reactstrap";
import FormButton from "./FormButton";
import ReactQuill from "react-quill";
import { routesBasePop } from "../pages/administrativo/procedimentos/pop/Pop";
import { apiGetV2 } from "../apiV2";

interface Props {
  idPop?: number;
  label?: string;
  buttonType?: "label" | "button";
  buttonColor?: string;
  className?: string;
}

export const PopModal: FC<Props> = ({
  idPop,
  label = "Visualizar",
  buttonType = "label",
  buttonColor = "primary",
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [markdown, setMarkdown] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setTitulo("");
    setMarkdown("");
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await apiGetV2(`${routesBasePop}/buscar/${idPop}/`);

    if (ok) {
      setTitulo(ret.titulo);
      setMarkdown(ret.markdown);
    } else {
      setIsOpen(false);
    }
    setLoading(false);
  };

  const toggle = () => {
    if (!isOpen) {
      if (markdown === "") {
        carregarDados();
      }
    }

    setIsOpen(!isOpen);
  };

  useEffect(() => {
    limparDados();
  }, [idPop]);

  return (
    <>
      {buttonType === "label" ? (
        <Label
          className="mt-auto mb-auto"
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={toggle}
          disabled={[0, null, undefined].includes(idPop)}
        >
          {label}
        </Label>
      ) : (
        <FormButton
          md="auto"
          color={buttonColor}
          onClick={toggle}
          disabled={[0, null, undefined].includes(idPop)}
        >
          {label}
        </FormButton>
      )}
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title={loading ? "Carregando..." : titulo}
        autoFocus
        footerActions
        loading={loading}
        onClosed={limparDados}
      >
        <ReactQuill value={markdown} readOnly={true} />
      </ModalBase>
    </>
  );
};
