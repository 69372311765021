import { FC, useState } from "react";
import { ModalBase, ModalProps } from "../../../components/ModalBase";
import { DatePicker } from "../../../components/DatePicker";
import ImplantacaoService from "../../../services/ImplantacaoService";
import { formatDateISO } from "../../../coreUtils";
import moment from "moment";
import { BiCopy } from "react-icons/bi";
import { UncontrolledTooltip } from "reactstrap";

interface Props {
  isOpen: ModalProps["isOpen"];
  toggle: ModalProps["toggle"];
  selected: any;
  notifyEvent: () => void;
}

export const OkUsoModal: FC<Props> = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [dtOkUso, setDtOkUso] = useState<Date | string>(new Date());
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    const payload = {
      id_implantacao: selected,
      data_ok_uso: formatDateISO(dtOkUso),
    };
    setLoading(true);
    const [ok] = await ImplantacaoService.okDeUso(payload);
    if (ok) {
      notifyEvent();
      toggle!();
    }
    setLoading(false);
  };

  const mensagemOkUso =
    "Prezado cliente, gostaríamos de informar que a cobrança da mensalidade " +
    "do sistema Skillsoft irá iniciar a partir da data " +
    `${moment(dtOkUso).format("DD/MM/YYYY")}.`;

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      title="Dar OK de Uso"
      number="014_3"
      size="md"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      onClosed={() => setDtOkUso(new Date())}
    >
      <span>
        O OK de Uso é a data na qual o cliente iniciou a utilização do sistema.
        Sendo que a cobrança financeira será iniciada a partir dessa data.
      </span>
      <br />
      <span>
        É de sua <b>OBRIGAÇÃO</b>, informar o cliente do início da cobrança com
        a seguinte mensagem:{" "}
      </span>
      <br />
      <br />
      <b>{mensagemOkUso}</b>
      <BiCopy
        size={16}
        className="mb-1 ml-2"
        onClick={() => {
          navigator.clipboard.writeText(mensagemOkUso);
        }}
        style={{ cursor: "pointer" }}
        id="copiar-mensagem-ok-uso"
      />
      <UncontrolledTooltip target="copiar-mensagem-ok-uso">
        Copiar mensagem
      </UncontrolledTooltip>
      <DatePicker
        md={6}
        label="Data de OK de Uso"
        value={dtOkUso}
        onChange={setDtOkUso}
        divClassName="mx-auto mt-2"
      />
    </ModalBase>
  );
};
