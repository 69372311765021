import { apiGetV2, apiSkillGetV2, authGetV2 } from "../../apiV2";
import { CONCAT_API_PATH } from "../../constants";

const ComboBoxService = {
  fetchOptions: async (modelName, filters = {}) => {
    const getFunc = ["empresa", "plano_contratado", "modulo", "menu"].includes(
      modelName
    )
      ? authGetV2
      : ["cidade", "ramo_atividade", "colaborador"].includes(modelName)
      ? apiSkillGetV2
      : apiGetV2;

    const [ok, ret] = await getFunc(
      `${CONCAT_API_PATH}/${modelName}/`,
      filters,
      { successMessage: false, errorMessage: false }
    );

    return ok ? ret : [];
  },
};

export default ComboBoxService;
