import { FC, useState } from "react";
import { Label, UncontrolledTooltip } from "reactstrap";

interface Props {
  value?: any;
  label?: boolean;
  width?: number;
  height?: number;
}

export const ClassifCli: FC<Props> = ({
  value,
  label,
  width = 40,
  height = 20,
}) => {
  const [id] = useState(Math.floor(Math.random() * Date.now()));

  let detClassifCli = value?.toString() + " - ";
  let corClassifCli = "";
  switch (value) {
    case 1:
    case 2:
    case 3:
      corClassifCli = "green";
      detClassifCli += "Cliente com ótimo / bom histórico de vendas";
      break;
    case 4:
    case 5:
      corClassifCli = "yellow";
      detClassifCli +=
        "Cliente com histórico de vendas razoável, requer atenção";
      break;
    case 6:
    case 7:
      corClassifCli = "red";
      detClassifCli += "Cliente com histórico de vendas ruim, evitar vendas";
      break;
    case 8:
      corClassifCli = "black";
      detClassifCli += "Cliente com dívidas em aberto no passado";
      break;
    default:
      corClassifCli = "#eee";
      detClassifCli += "Sem Classificação";
      break;
  }

  return (
    <div>
      {label && <Label>Classif.</Label>}
      <div
        style={{
          backgroundColor: corClassifCli,
          width: width,
          height: height,
          borderRadius: "4px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        id={`classif_cli_${id}`}
      />
      <UncontrolledTooltip placement="bottom" target={`classif_cli_${id}`}>
        {detClassifCli}
      </UncontrolledTooltip>
    </div>
  );
};
