import { FC, useEffect, useRef, useState } from "react";
import {
  AsyncComboBox,
  FormButton,
  FormCheckbox,
  IntegerInput,
  PageContainer,
  TextInput,
} from "../../../../components";
import ComboBox from "../../../../components/ComboBox";
import { tipoDemandaOptions, statusOptions } from "../Desenvolvimento";
import { Card, Row, UncontrolledTooltip } from "reactstrap";
import { toastr } from "react-redux-toastr";
import DesenvolvimentoService from "../../../../services/DesenvolvimentoService";
import {
  ConteudoDemandaEditor,
  quillDemandaDevFormats,
  quillDemandaDevModules,
} from "./components/ConteudoDemandaEditor";
import { ModalActions } from "../../../../components/cadastro/ModalCadastroV2";
import { RegistrarCliCobrModal } from "./components/RegistrarCliCobrModal";
import { IconButton } from "../../../../components/IconButton";
import { BsPlus, BsPrinter } from "react-icons/bs";
import { AtualizacoesForm } from "../../../../components/AtualizacoesForm";
import { FixedField } from "../../../../components/FixedField";
import { Redirect, useHistory } from "react-router-dom";
import { desenvolvimentoDemandasRoute } from "../../../../routes/modules/desenvolvimento";
import { useQueryParams } from "../../../../utils/hooks";
import { useDispatch } from "react-redux";
import { lerNotificacao } from "../../../../redux/reducers/notificacoesReducer";
import { extractMarkdownText, printReport } from "../../../../coreUtils";
import { authGetV2 } from "../../../../apiV2";
import { routesBaseEmpresa } from "../../../administrativo/cadastros/empresa/Empresa";
import { DemandaDevPDF } from "./DemandaDevPDF";
import { toPng } from "html-to-image";

interface Props {
  location: any;
}

export const IncluirAlterarDemandaDev: FC<Props> = ({ location }) => {
  const query = useQueryParams();
  const queryId = query.get("id") ? parseInt(query.get("id")!) : null;
  const queryIdAtualiza = query.get("id_atualiza")
    ? parseInt(query.get("id_atualiza")!)
    : null;

  const selected = location?.state?.selected ?? queryId;
  const action = [0, null, undefined].includes(selected)
    ? ModalActions.add
    : ModalActions.edit;
  const [idAtualizaMostrar, setIdAtualizaMostrar] = useState<any>(null);
  const [idCliente, setIdCliente] = useState(null);
  const [nomePlanoCliente, setNomePlanoCliente] = useState(null);
  const [iconePlanoCliente, setIconePlanoCliente] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [idModulo, setIdModulo] = useState(null);
  const [idTela, setIdTela] = useState(null);
  const [nroTela, setNroTela] = useState("");
  const [semTela, setSemTela] = useState(false);
  const [idColabAtrib, setIdColabAtrib] = useState(null);
  const [nomeColabInc, setNomeColabInc] = useState("");
  const [inclusao, setInclusao] = useState("");
  const [conteudo, setConteudo] = useState("");
  const [status, setStatus] = useState(statusOptions[0].value);
  const [prioridade, setPrioridade] = useState(0);
  const [cobrancasCli, setCobrancasCli] = useState([]);
  const [atualizacoes, setAtualizacoes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [cliCobrOpen, setCliCobrOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();

  const telaRef = useRef<any>();
  const editorRef = useRef<any>();

  const carregarAtualizacoes = async (inicio = false) => {
    const [ok, ret] = await DesenvolvimentoService.atualizacao.listar(selected);
    setAtualizacoes(ok ? ret : []);
    if (ok) {
      if (inicio) {
        dispatch(
          lerNotificacao(
            "DEVME",
            ret.map((e: any) => e.id)
          )
        );

        if (idAtualizaMostrar) {
          const atu = document.getElementById(`atu-${idAtualizaMostrar}`);
          if (atu) {
            atu.scrollIntoView();
          }
        }
      } else {
        setIdAtualizaMostrar(null);
      }
    }
  };

  const enviarAtu = async (conteudo: any, mencoes: any) => {
    const payload = {
      id_cab: selected,
      conteudo: conteudo,
      id_colab_mencionados: mencoes,
    };
    const [ok] = await DesenvolvimentoService.atualizacao.incluir(payload);
    if (ok) {
      carregarAtualizacoes();
    }
    return ok;
  };

  const excluirAtu = async (idAtu: any) => {
    const [ok] = await DesenvolvimentoService.atualizacao.excluir(idAtu);
    if (ok) {
      carregarAtualizacoes();
    }
  };

  const carregarCobrancasCli = async () => {
    const [ok, ret] = await DesenvolvimentoService.cliCobr.listar(selected);
    setCobrancasCli(ok ? ret : []);
  };

  const carregarDados = async (inicio?: boolean) => {
    const [ok, ret] = await DesenvolvimentoService.buscar(selected);
    if (ok) {
      setIdCliente(ret.id_cliente);
      setTipo(ret.tipo);
      setTitulo(ret.titulo);
      setIdModulo(ret.id_modulo);
      setIdTela(ret.id_tela);
      setNroTela(ret.nro_tela);
      setSemTela(ret.nro_tela === "SEMT");
      setIdColabAtrib(ret.id_colab_atrib);
      setNomeColabInc(ret.nome_colab_inc);
      setInclusao(ret.inclusao);
      setConteudo(ret.conteudo);
      setStatus(ret.status);
      setPrioridade(ret.prioridade);
      carregarCobrancasCli();
      carregarAtualizacoes(inicio);
    } else {
      history.goBack();
    }
  };

  const temTexto = () => extractMarkdownText(conteudo).length > 0;

  const handleSetNroTela = (v: any, s: any) => {
    if (v === idTela) return;
    if (v) {
      setIdTela(v);
      setNroTela(s.nro_tela);
      setIdModulo(s?.id_modulo);
    } else {
      setIdTela(null);
      setNroTela("");
      setIdModulo(null);
    }
  };

  const handleSubmit = async () => {
    if (["", null, undefined].includes(titulo)) {
      toastr.warning("Atenção", "Por favor, informe o Título da Demanda");
      return;
    }

    if ([null, undefined].includes(idCliente)) {
      toastr.warning(
        "Atenção",
        "Por favor, informe o Cliente Solicitante da Demanda"
      );
      return;
    }

    if (["", null, undefined].includes(tipo)) {
      toastr.warning("Atenção", "Por favor, informe o Tipo da Demanda");
      return;
    }

    if (!semTela) {
      if (["", null, undefined].includes(nroTela)) {
        toastr.warning("Atenção", "Por favor, informe o Número da Tela");
        return;
      }
    }

    if ([0, null, undefined].includes(idModulo)) {
      toastr.warning("Atenção", "Por favor, informe o Módulo da Demanda");
      return;
    }

    if (action === ModalActions.add) {
      if (!temTexto()) {
        toastr.warning("Atenção", "Por favor, informe a Descrição da Demanda");
        return;
      }
    }

    const payload = {
      id_cliente: idCliente,
      tipo: tipo,
      titulo: titulo,
      id_modulo: idModulo,
      nro_tela: semTela ? "SEMT" : nroTela,
      id_colab_atrib: idColabAtrib,
      conteudo: conteudo,
      status: action === ModalActions.edit ? status : statusOptions[0].value,
      prioridade: prioridade,
    };

    setLoading(true);

    const submitFunc =
      action === ModalActions.add
        ? DesenvolvimentoService.incluir
        : DesenvolvimentoService.alterar;

    const submitPayload =
      action === ModalActions.add
        ? { ...payload }
        : { ...payload, id_demanda: selected };

    const [ok] = await submitFunc(submitPayload);
    if (ok) {
      if (action === ModalActions.edit) {
        carregarDados();
      } else {
        setRedirect(true);
      }
    }
    setLoading(false);
  };

  const toggleCliCobr = () => setCliCobrOpen(!cliCobrOpen);

  const iniciarTela = async () => {
    if (action === ModalActions.edit) {
      setLoadingFetch(true);
      await carregarDados(true);
      setLoadingFetch(false);
    }
  };

  const onKeyDown = (e: any) => {
    if (e.key === "F9") {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (queryIdAtualiza) {
      setIdAtualizaMostrar(queryIdAtualiza);
    }
  }, [queryIdAtualiza]);

  const buscarPlanoCliente = async () => {
    if (![0, null, undefined].includes(idCliente)) {
      const [ok, ret] = await authGetV2(
        `${routesBaseEmpresa}/buscar_plano_contratado/${idCliente}/`
      );
      if (ok) {
        setNomePlanoCliente(ret.nome_plano_contratado);
        setIconePlanoCliente(ret.icone_plano_contratado);
      } else {
        setNomePlanoCliente(null);
        setIconePlanoCliente(null);
      }
    } else {
      setNomePlanoCliente(null);
      setIconePlanoCliente(null);
    }
  };

  const imprimir = async () => {
    const [okDados, retDados] = await DesenvolvimentoService.buscarImpressao(
      selected
    );
    if (!okDados) {
      return;
    }

    const container = document.createElement("div");
    container.classList.add("ql-container");
    document.getElementById("root")!.appendChild(container);
    container.appendChild(
      (editorRef.current.editor.root as HTMLElement).cloneNode(true)
    );
    container.style.width = "1000px";
    container.style.height = "auto";
    const editorClone = container.firstElementChild! as HTMLElement;
    editorClone.innerHTML = retDados.conteudo;

    const [okImage, retImage] = await toPng(container, {
      quality: 1,
    })
      .then((dataUrl) => [true, dataUrl])
      .catch((error) => [false, error.toString()]);

    container.remove();

    if (okImage) {
      await printReport(
        <DemandaDevPDF dados={retDados} imagem={retImage} />,
        "501_1"
      );
    } else {
      toastr.error(
        "Erro ao gerar impressão do conteúdo da tarefa",
        retImage as string
      );
    }
  };

  useEffect(() => {
    buscarPlanoCliente();
  }, [idCliente]);

  useEffect(() => {
    iniciarTela();
  }, [selected]);

  if (redirect) {
    return <Redirect to={desenvolvimentoDemandasRoute.path} />;
  }

  return (
    <>
      <PageContainer
        title={
          action === ModalActions.add
            ? "Nova Demanda de Desenvolvimento"
            : "Alterar Demanda de Desenvolvimento"
        }
        number="015_1"
        loading={loadingFetch}
        onKeyDown={onKeyDown}
        goBackRoute={desenvolvimentoDemandasRoute}
      >
        <Card body>
          <Row>
            <TextInput
              md={8}
              label="Título"
              value={titulo}
              onChange={setTitulo}
              autoFocus
            />
            {action === ModalActions.edit && (
              <>
                <FixedField
                  label="ID"
                  value={selected}
                  horizontal
                  divClassName="mt-4"
                />
                <FixedField
                  label="Incluído Por"
                  value={`${nomeColabInc} - ${inclusao}`}
                  horizontal
                  divClassName="mt-4"
                />
              </>
            )}
            <IconButton
              icon={BsPrinter}
              size={20}
              className="mt-4"
              onClick={imprimir}
              hint="Imprimir Demanda"
            />
          </Row>
          <Row>
            <AsyncComboBox
              md={4}
              label="Cliente"
              concatModelName="empresa"
              value={idCliente}
              onChange={setIdCliente}
              isClearable
            />
            {nomePlanoCliente && (
              <FixedField
                label="Plano"
                divClassName="pt-4"
                value={
                  <>
                    {nomePlanoCliente}
                    <img className="ml-1" src={iconePlanoCliente!} />
                  </>
                }
                horizontal
              />
            )}
            <ComboBox
              md={3}
              label="Tipo"
              options={tipoDemandaOptions}
              value={tipo}
              onChange={setTipo}
            />
          </Row>
          <Row>
            <AsyncComboBox
              md={5}
              label="Tela"
              concatModelName="menu"
              onChange={handleSetNroTela}
              value={idTela}
              ref={telaRef}
              disabled={semTela}
            />
            <AsyncComboBox
              md={5}
              label="Módulo"
              concatModelName="modulo"
              value={idModulo}
              onChange={setIdModulo}
              disabled={!semTela}
            />
            <FormCheckbox
              label="Sem Tela"
              checked={semTela}
              onChange={(v: boolean) => {
                setSemTela(v);
                if (v) {
                  if (telaRef.current) {
                    telaRef.current.clearValue();
                  }
                  setNroTela("");
                }
              }}
            />
          </Row>
          <Row className="mb-3">
            <AsyncComboBox
              md={4}
              label="Atribuído A"
              concatModelName="colaborador"
              value={idColabAtrib}
              onChange={setIdColabAtrib}
              isClearable
              defaultOptions
            />
            {action === ModalActions.edit && (
              <ComboBox
                md={3}
                label="Status"
                options={statusOptions}
                value={status}
                onChange={setStatus}
                isClearable={false}
              />
            )}
            <IntegerInput
              md={2}
              label="Prioridade"
              value={prioridade}
              onChange={setPrioridade}
            />
            {action === ModalActions.edit && (
              <>
                <IntegerInput
                  md={1}
                  label="Cli. Cobrou"
                  value={cobrancasCli.length}
                  disabled
                  divClassName="pr-1"
                  id="num-cli-cobrou"
                  style={{ textAlign: "center" }}
                />
                <UncontrolledTooltip
                  target="num-cli-cobrou"
                  style={{ textAlign: "left", maxWidth: "max-content" }}
                  placement="bottom"
                >
                  {cobrancasCli.map((e: any) => (
                    <>
                      <span>
                        {e.dh_cobranca} - {e.contato} - {e.nome_colaborador}
                      </span>
                      <br />
                    </>
                  ))}
                </UncontrolledTooltip>
                <IconButton
                  icon={BsPlus}
                  onClick={toggleCliCobr}
                  size={35}
                  className="mt-3"
                />
                <RegistrarCliCobrModal
                  isOpen={cliCobrOpen}
                  toggle={toggleCliCobr}
                  selected={selected}
                  notifyEvent={carregarCobrancasCli}
                />
              </>
            )}
          </Row>
          <ConteudoDemandaEditor
            value={conteudo}
            onChange={setConteudo}
            editorRef={editorRef}
          />
          <Row>
            <FormButton
              divClassName="ml-auto"
              color="success"
              onClick={handleSubmit}
              loading={loading}
            >
              F9 - Confirmar
            </FormButton>
          </Row>
          {action === ModalActions.edit && (
            <>
              <AtualizacoesForm
                data={atualizacoes}
                handleSubmit={enviarAtu}
                handleDelete={excluirAtu}
                modules={quillDemandaDevModules}
                formats={quillDemandaDevFormats}
                idHighlight={idAtualizaMostrar}
              />
            </>
          )}
        </Card>
      </PageContainer>
    </>
  );
};
