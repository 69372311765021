import { FC, useState } from "react";
import { ModalBase } from "../../../../../components/ModalBase";
import { Row } from "reactstrap";
import { TextInput } from "../../../../../components";
import { toastr } from "react-redux-toastr";
import DesenvolvimentoService from "../../../../../services/DesenvolvimentoService";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  selected: any;
  notifyEvent: () => void;
}

export const RegistrarCliCobrModal: FC<Props> = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [contato, setContato] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => setContato("");

  const handleSubmit = async () => {
    if (["", null, undefined].includes(contato)) {
      toastr.warning("Atenção", "Por favor, informe o Contato");
      return;
    }
    const payload = { id_demanda: selected, contato: contato };
    setLoading(true);
    const [ok] = await DesenvolvimentoService.cliCobr.incluir(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Registrar Cobrança pelo Cliente"
      number="015_11"
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <Row>
        <TextInput
          md={12}
          label="Contato"
          value={contato}
          onChange={setContato}
          maxLength={30}
        />
      </Row>
    </ModalBase>
  );
};
