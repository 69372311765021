import { FC, useRef, useState } from "react";
import {
  AtualizacoesEditor,
  AtualizacoesEditorProps,
} from "./AtualizacoesEditor";
import FormButton from "./FormButton";
import { DropdownItem, Row, Spinner } from "reactstrap";
import classNames from "classnames";
import { MoreDropdown } from "./MoreDropdown";
import ReactQuill from "react-quill";
import { extractMarkdownText } from "../coreUtils";
import { Divider } from "./Divider";
import { toastr } from "react-redux-toastr";

interface Props {
  data: any[];
  loading?: boolean;
  handleSubmit: (content: any, mentions: any[]) => Promise<boolean>;
  handleDelete: (id: any) => void;
  idHighlight?: any;
  modules?: AtualizacoesEditorProps["modules"];
  formats?: AtualizacoesEditorProps["formats"];
  editorClassName?: AtualizacoesEditorProps["className"];
}

export const extrairIdColabMencionados = (
  editorRef: React.MutableRefObject<any>
) =>
  Array.from(
    editorRef.current.getEditor().container.querySelectorAll("span.mention")
  ).map((e: any) => parseInt(e.getAttribute("data-id")));

export const AtualizacoesForm: FC<Props> = ({
  data,
  handleSubmit,
  loading,
  handleDelete,
  idHighlight,
  modules,
  formats,
  editorClassName,
}) => {
  const idColab = parseInt(localStorage.getItem("id_colaborador") ?? "0");
  const [rascunho, setRascunho] = useState("");
  const editorRef = useRef<any>();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const temTexto = () => extractMarkdownText(rascunho).trim().length > 0;

  const enviarAtu = async () => {
    if (!temTexto()) {
      toastr.warning("Atenção", "A mensagem está vazia");
      editorRef.current.focus();
      return;
    }
    const idColabMencionados = extrairIdColabMencionados(editorRef);

    setLoadingSubmit(true);
    const ok = await handleSubmit(rascunho, idColabMencionados);
    if (ok) {
      setRascunho("");
    }
    setLoadingSubmit(false);
  };

  return (
    <div className="mt-3">
      <Row className="ml-0">
        <Divider className="mt-0" divStyle={{ flexGrow: 1 }}>
          Comentários
        </Divider>
        <FormButton
          padded={false}
          divClassName="ml-auto"
          onClick={enviarAtu}
          loading={loadingSubmit}
          disabled={!temTexto()}
          disabledHint="A mensagem está vazia."
        >
          Enviar Comentário
        </FormButton>
      </Row>
      <AtualizacoesEditor
        value={rascunho}
        onChange={setRascunho}
        editorRef={editorRef}
        modules={modules}
        formats={formats}
        className={editorClassName}
        onKeyDown={(e) => e.ctrlKey && e.key === "Enter" && enviarAtu()}
      />
      <div style={{ marginTop: "0.8rem" }}>
        {loading ? (
          <div
            style={{
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spinner size="lg" />
          </div>
        ) : (
          data.map((e: any) => {
            return (
              <div
                className={classNames(
                  "atu-card",
                  e.id === idHighlight && "highlight"
                )}
                id={`atu-${e.id}`}
              >
                <div style={{ display: "flex" }}>
                  <h5 className="nome-usu">{e.nome_colaborador}</h5>
                  <span style={{ marginLeft: "1rem" }}>{e.dh_inclusao}</span>
                  <MoreDropdown horizontal style={{ marginLeft: "auto" }}>
                    {e.id_colaborador === idColab && (
                      <DropdownItem onClick={() => handleDelete(e.id)}>
                        Excluir
                      </DropdownItem>
                    )}
                  </MoreDropdown>
                </div>
                <ReactQuill
                  value={e.conteudo}
                  readOnly={true}
                  modules={{ toolbar: false }}
                  className="atu-quill"
                />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
