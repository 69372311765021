import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  FC,
} from "react";
import AsyncSelect from "react-select/async";
import { Col, FormGroup, Label } from "reactstrap";
import { handleFocus } from "../coreUtils";
import ComboBoxService from "../services/components/ComboBoxService";
import { ActionMeta } from "react-select";

interface Props {
  loadOptions?: any;
  md?: number;
  label?: string;
  name?: string;
  onChange?: (value: any, selected: any, target: ActionMeta<any>) => void;
  options?: any;
  isSearchable?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  isConcatField?: boolean;
  concatModelName?: any;
  value?: any;
  defaultOptions?: any;
  placeholder?: string;
  className?: any;
  autoFocus?: boolean;
  ref?: any;
  disabled?: boolean;
  clearOnDisable?: boolean;
  tabOrder?: any;
  onBlur?: any;
  id?: any;
}

export const generateFilters = (
  concatModelName: string,
  inputValue: any,
  searchById?: boolean
) => {
  const filters: any = {};
  if (searchById === true) {
    if (!isNaN(inputValue)) {
      filters["pk"] = inputValue;
    }
  } else {
    if (!isNaN(inputValue)) {
      if (concatModelName === "cliente") {
        filters["pk"] = inputValue;
      }
      if (concatModelName === "menu") {
        filters["nro_tela"] = inputValue;
      } else {
        filters["id"] = inputValue;
      }
    } else {
      let nomeCampo = "nome";
      if (concatModelName === "conta_banc") {
        nomeCampo = "banco__nome";
      } else if (["problema", "base_conhecimento"].includes(concatModelName)) {
        nomeCampo = "titulo";
      } else if (
        [
          "centro_custo",
          "plano_conta",
          "modulo",
          "pre_modulo",
          "plano_contratado",
          "ramo_atividade",
          "menu",
        ].includes(concatModelName)
      ) {
        nomeCampo = "descricao";
      } else if (concatModelName === "empresa") {
        nomeCampo = "nome_fant";
      }
      filters[`${nomeCampo}__icontains`] = inputValue;
    }
  }
  return filters;
};

const AsyncComboBox: FC<Props> = forwardRef(
  (
    {
      loadOptions,
      onChange,
      isClearable,
      isMulti = false,
      isSearchable = true,
      md = 4,
      label,
      placeholder = "Selecione...",
      name,
      className,
      defaultOptions,
      isConcatField = true,
      concatModelName,
      value,
      options,
      disabled,
      clearOnDisable,
      autoFocus,
      tabOrder,
      onBlur,
      id,
    },
    ref
  ) => {
    const [selectedOption, setSelectOption] = useState({});
    const selectRef = useRef<any>();
    const [loadedOptions, setLoadedOptions] = useState(loadOptions);
    const [internalId] = useState(id ?? Math.floor(Math.random() * Date.now()));

    const fetchOptions = async (inputValue: any, searchById = false) => {
      let _result = [];
      if (isConcatField && typeof concatModelName == "string") {
        const filters =
          inputValue !== ""
            ? generateFilters(concatModelName, inputValue, searchById)
            : {};

        const ret = await ComboBoxService.fetchOptions(
          concatModelName,
          filters
        );
        setLoadedOptions(true);
        _result = ret;
      } else {
        _result = options;
      }
      return _result;
    };

    const clearValue = () => {
      selectRef.current.select.select.clearValue();
    };

    const setValue = (value: any) => {
      selectRef.current.select.select.setValue(value);
    };

    const setValueByID = useCallback(async (id) => {
      let i = 0;
      let checked = false;
      while (!checked && i < 10) {
        const options = getOptions();
        if (options.length > 0) {
          setValue(options.find((v: any) => v.value === id));
          checked = true;
        }
        i++;
        await new Promise((r) => setTimeout(r, 75));
      }
    }, []);

    const getOptions = () => {
      if (!selectRef?.current) return [];
      return selectRef.current.select.select.props.options;
    };

    const setFocus = () => {
      selectRef.current.select.select.focus();
    };

    useImperativeHandle(ref, () => ({
      clearValue: () => clearValue(),
      setValue: (v: any) => setValue(v),
      getOptions: () => getOptions(),
      setValueByID: (id: any) => setValueByID(id),
      getValue: () => selectedOption,
      setFocus: () => setFocus(),
    }));

    const __onChange = (selected: any, target: ActionMeta<any>) => {
      setSelectOption(selected);
      if (onChange) {
        onChange(
          isMulti ? selected : selected?.value ?? null,
          selected,
          target
        );
      }
    };

    // useEffect(() => {
    //   if (
    //     ![null, undefined].includes(value) &&
    //     (loadedOptions || !(defaultOptions instanceof Array))
    //   ) {
    //     new Promise(() => setTimeout(setValueByID(value) as any, 1000));
    //   }
    // }, [setValueByID, value, loadedOptions]);

    // Quando o value é zero, não é atribuida a opção no Select, pois zero é falsy
    // Esta função abre uma exceção quando o concatModelName for colaborador
    const checkDefaultValue = (v: any) => {
      if (concatModelName === "colaborador") {
        return v === 0 ? true : v;
      } else {
        return v;
      }
    };

    useEffect(() => {
      const setarDefaultValue = async () => {
        if (
          checkDefaultValue(value) &&
          (loadedOptions || !(defaultOptions instanceof Array)) &&
          defaultOptions
        ) {
          new Promise(() => setTimeout(setValueByID(value) as any, 1000));
        } else if (checkDefaultValue(value) && !defaultOptions) {
          const ret = await fetchOptions(value, true);
          if (ret.length > 0) {
            setValue(ret[0]);
          }
        }
      };
      setarDefaultValue();
    }, [setValueByID, value, loadedOptions]);

    useEffect(() => {
      if (disabled && clearOnDisable) {
        clearValue();
      }
    }, [disabled, clearOnDisable]);

    return (
      <Col md={md} className={className}>
        <FormGroup onKeyDown={handleFocus}>
          <Label for={`react-select-${internalId}-input`}>{label}</Label>
          <AsyncSelect
            placeholder={placeholder}
            className="react-select-container"
            classNamePrefix="react-select"
            loadOptions={isConcatField ? fetchOptions : loadOptions}
            name={name}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isMulti={isMulti}
            defaultOptions={defaultOptions}
            onChange={__onChange}
            ref={selectRef}
            cacheOptions
            isDisabled={disabled}
            autoFocus={autoFocus}
            noOptionsMessage={() =>
              isConcatField ? "Digite Algo" : "Sem Dados"
            }
            loadingMessage={() => "Carregando..."}
            isLoading={
              defaultOptions instanceof Boolean &&
              defaultOptions &&
              !loadedOptions
            }
            tabOrder={tabOrder}
            onBlur={onBlur}
            instanceId={internalId}
          />
        </FormGroup>
      </Col>
    );
  }
);

export default AsyncComboBox;
