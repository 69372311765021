import {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Col,
  FormFeedback,
  FormGroup,
  Input,
  InputProps,
  Label,
} from "reactstrap";
import { handleFocus } from "../coreUtils";
import { UnlockToEdit } from "./UnlockToEdit";

interface Props {
  onChange?: any;
  label?: any;
  md?: any;
  required?: any;
  style?: any;
  name?: any;
  value?: any;
  ref?: any;
  autoFocus?: boolean;
  disabled?: boolean;
  clearOnDisable?: boolean;
  valueOnDisableClear?: number | null;
  tabOrder?: number;
  divClassName?: string;
  id?: InputProps["id"];
  unlockToEdit?: boolean;
  divStyle?: any;
}

const IntegerInput: FC<Props> = forwardRef(
  (
    {
      onChange,
      label,
      md,
      required,
      style,
      name,
      value,
      autoFocus,
      disabled,
      tabOrder,
      clearOnDisable,
      valueOnDisableClear = 0,
      divClassName,
      divStyle,
      id,
      unlockToEdit = false,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState<any>("");
    const [invalidMessage, setInvalidMessage] = useState("");
    const [invalid, setInvalid] = useState(false);

    const inputIsValid = (value: any) => {
      const regex = RegExp(/^([0-9]+)$/);
      if (value) {
        if (!regex.test(value)) {
          setInvalidMessage("Valor inválido");
          return false;
        }
      } else if (required && !value) {
        setInvalidMessage("Esse campo é obrigatório");
        return false;
      }
      setInvalidMessage("");
      return true; // Vazio é válido
    };

    const handleOnChange = (e: any) => {
      e.preventDefault();
      const value = (e.target.value || "").trim();
      if (inputIsValid(value)) {
        setInvalid(false);
        setInputValue(value);
        if (onChange) {
          if (value !== "") {
            onChange(Number.parseFloat(value.replace(",", ".")), e);
          } else {
            onChange(null, e);
          }
        }
      } else {
        setInvalid(true);
        setInputValue("");
        if (onChange) {
          onChange(0.0, e);
        }
      }
    };

    useImperativeHandle(ref, () => ({
      floatValue: () => {
        return inputIsValid(inputValue)
          ? Number.parseFloat(String(inputValue || "0.0").replace(",", "."))
          : 0.0;
      },
      isValid: () => inputIsValid(inputValue),
      clear: () => {
        setInputValue("");
      },
      setValue: (value: any) => {
        if (inputIsValid(value)) {
          setInputValue(value);
        }
      },
    }));

    const atualizaValorInput = () => {
      if (inputIsValid(value)) {
        setInputValue(value);
      }
    };

    const atualizaValorDisable = () => {
      if (disabled && clearOnDisable) {
        setInputValue(valueOnDisableClear);
        if (onChange) {
          onChange(valueOnDisableClear);
        }
      }
    };

    useEffect(atualizaValorInput, [value]);
    useEffect(atualizaValorDisable, [disabled, clearOnDisable]);

    return (
      <Col md={md} className={divClassName} style={divStyle}>
        <FormGroup>
          <Label>{label}</Label>
          <UnlockToEdit enabled={unlockToEdit}>
            <Input
              type="text"
              style={style || {}}
              onChange={handleOnChange}
              onBlur={(e) => {
                const { value } = e.target;
                inputIsValid(value);
              }}
              name={name}
              value={inputValue}
              invalid={invalid}
              required={required}
              onKeyDown={handleFocus}
              autoFocus={autoFocus}
              disabled={disabled}
              tabIndex={tabOrder}
              onFocus={(e) => e.target.select()}
              id={id}
            />
          </UnlockToEdit>
          <FormFeedback valid={false}>{invalidMessage}</FormFeedback>
        </FormGroup>
      </Col>
    );
  }
);

export default IntegerInput;
