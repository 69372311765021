import { FC } from "react";
import { TableCheck } from "../../../../../components/TableCheck";
import { Table } from "../../../../../components/Table";
import { TableColumn } from "../../../../../components/table/TableRow";
import { naturalSort } from "../../../../../coreUtils";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

interface Props {
  data: any;
  setSelected: any;
  alterar: (id: any) => void;
  excluir: (id: any) => void;
}

export const PlanoContratadoGrid: FC<Props> = ({
  data,
  setSelected,
  alterar,
  excluir,
}) => {
  const columns: TableColumn[] = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      sortable: true,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      formatter: (c: any) => <TableCheck value={c} />,
      sortable: true,
    },
    {
      dataField: "obs",
      text: "Obs.",
      align: "left",
    },
    {
      dataField: "icone_base64",
      text: "Ícone",
      align: "center",
      formatter: (c) => <img src={c} />,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      paginated
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
