import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../apiV2";

const URL_BASE = "/implantacao";
const URL_BASE_ATUALIZACAO = `${URL_BASE}/atualizacao`;

const ImplantacaoService = {
  alterarDadosGerais: (payload) =>
    apiPutV2(`${URL_BASE}/alterar_dados_gerais/`, payload),
  alterarItem: (payload) =>
    apiPutV2(`${URL_BASE}/alterar_item/`, payload, {
      successMessage: false,
    }),
  buscarDadosGerais: (idImplant) =>
    apiGetV2(`${URL_BASE}/buscar_dados_gerais/${idImplant}/`),
  buscarItens: (idImplant, setor) =>
    apiGetV2(`${URL_BASE}/buscar_itens/${idImplant}/${setor}/`),
  concluirImplantacao: (payload) =>
    apiPutV2(`${URL_BASE}/concluir_implantacao/`, payload),
  iniciar: (payload) => apiPostV2(`${URL_BASE}/iniciar/`, payload),
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  okDeUso: (payload) => apiPutV2(`${URL_BASE}/ok_de_uso/`, payload),
  atualizacao: {
    excluir: (idAtu) =>
      apiDeleteV2(`${URL_BASE_ATUALIZACAO}/excluir/${idAtu}/`),
    incluir: (payload) =>
      apiPostV2(`${URL_BASE_ATUALIZACAO}/incluir/`, payload),
    listar: (idAgend, params) =>
      apiGetV2(`${URL_BASE_ATUALIZACAO}/listar/${idAgend}/`, params),
  },
};

export default ImplantacaoService;
