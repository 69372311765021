import { FC, useEffect, useState } from "react";
import { BsDownload, BsX } from "react-icons/bs";
import { Col, Label, Row, Spinner } from "reactstrap";
import {
  AsyncComboBox,
  FormCheckbox,
  IntegerInput,
  TextInput,
  ComboBox,
  FormButton,
  NumberInput,
} from "../../../../../components";
import { ComboBoxOption } from "../../../../../components/ComboBox";
import { DatePicker } from "../../../../../components/DatePicker";
import DragAndDropArquivos from "../../../../../components/DragAndDropArquivos";
import MaskedInput from "../../../../../components/MaskedInput";
import { downloadFileFromBlob, formatDate } from "../../../../../coreUtils";
import { DragDropImagem } from "../../../../../components/DragDropImagem";
import { Divider } from "../../../../../components/Divider";
import { toastr } from "react-redux-toastr";
import { auth } from "../../../../../api";
import { PopModal } from "../../../../../components/PopModal";
import {
  RadioGroup,
  RadioGroupOption,
} from "../../../../../components/RadioGroup";
import {
  ModalActions,
  ModalCadastroProps,
  ModalCadastroV2,
} from "../../../../../components/cadastro/ModalCadastroV2";
import { modalTitleEmpresa, routesBaseEmpresa } from "../Empresa";
import { APIs, authPostV2 } from "../../../../../apiV2";
import { IconButton } from "../../../../../components/IconButton";
import ImplantacaoService from "../../../../../services/ImplantacaoService";
import { FixedField } from "../../../../../components/FixedField";

const regimesTribut: ComboBoxOption[] = [
  { label: "Simples Nacional", value: "SIM" },
  { label: "Lucro Presumido", value: "PRE" },
  { label: "Lucro Real", value: "REA" },
  { label: "MEI", value: "MEI" },
];

interface PropsDragDropCert {
  certificado: any;
  handleSetCertificado: any;
  certificadoConfigurado: boolean;
}

const tipoSistemaOptions: RadioGroupOption[] = [
  { label: "Padrão", value: "PADRAO" },
  { label: "Automotivo", value: "MECANICA" },
];

const modoImplantaOptions: ComboBoxOption[] = [
  { label: "Remoto", value: "REM" },
  { label: "Híbrido", value: "HIB" },
  { label: "Presencial", value: "PRE" },
];

const DragDropCert: FC<PropsDragDropCert> = ({
  certificado,
  handleSetCertificado,
  certificadoConfigurado,
}) => (
  <Col md="auto">
    {certificado ? (
      <div>
        <h5 className="mb-1">Certificado:</h5>
        <span>
          <strong>{certificado?.file.name}</strong>
          <BsX
            size={20}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => handleSetCertificado(null)}
          />
        </span>
      </div>
    ) : (
      <DragAndDropArquivos
        md="auto"
        divClassName="no-gutters mt-3"
        text={
          !certificadoConfigurado
            ? "Selecionar Certificado"
            : "Atualizar Certificado"
        }
        onDrop={handleSetCertificado}
        accept="application/x-pkcs12"
        style={{
          padding: "0.5rem 2rem",
          marginTop: "0",
          color: "black",
          cursor: "pointer",
          borderColor: "#ddd",
          marginLeft: "2px",
        }}
      />
    )}
  </Col>
);

interface Props {
  isOpen: ModalCadastroProps["isOpen"];
  toggle: ModalCadastroProps["toggle"];
  action: ModalCadastroProps["action"];
  selected: any;
  notifyEvent: ModalCadastroProps["notifyEvent"];
}

export const CadastroEmpresaModal: FC<Props> = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [nomeFant, setNomeFant] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [regimeTribut, setRegimeTribut] = useState(regimesTribut[0].value);
  const [siglaUser, setSiglaUser] = useState("");
  const [dataLim, setDataLim] = useState<any>(new Date());
  const [licenca, setLicenca] = useState(0);
  const [certificado, setCertificado] = useState<{
    file: File;
    base64: string;
  } | null>();
  const [mudouCertificado, setMudouCertificado] = useState(false);
  const [certificadoConfigurado, setCertificadoConfigurado] = useState(false);
  const [logo, setLogo] = useState<{
    file: File | null;
    base64: string | null;
  } | null>();
  const [mudouLogo, setMudouLogo] = useState(false);
  const [idPlanoContratado, setIdPlanoContratado] = useState(0);
  const [tipoCertificado, setTipoCertificado] = useState("FILE");
  const [senhaCertificado, setSenhaCertificado] = useState("");
  const [validadeCertificado, setValidadeCertificado] = useState<
    string | Date | null
  >("");
  const [nroSerieCertificado, setNroSerieCertificado] = useState<string | null>(
    null
  );
  const [possuiServidorLocal, setPossuiServidorLocal] = useState(false);
  const [ipBancoDados, setIpBancoDados] = useState("");
  const [portaBancoDados, setPortaBancoDados] = useState(5432);
  const [tipoSistema, setTipoSistema] = useState("PADRAO");
  const [emailProprietario, setEmailProprietario] = useState("");
  const [idContabilidade, setIdContabilidade] = useState(null);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [loadingLerCertif, setLoadingLerCertif] = useState(false);
  const [loadingTesteConexao, setLoadingTesteConexao] = useState(false);

  // Dados Contrato
  const [dataInicioContrato, setDataInicioContrato] = useState<any>(null);
  const [diaCobranca, setDiaCobranca] = useState(10);
  const [vlrImplantacao, setVlrImplantacao] = useState(0);
  const [vlrMigDados, setVlrMigDados] = useState(0);
  const [vlrPersonalizaSist, setVlrPersonalizaSist] = useState(0);
  const [vlrSistemaAdmin, setVlrSistemaAdmin] = useState(0);
  const [vlrUserAdm, setVlrUserAdm] = useState(0);
  const [qtdUserAdmin, setQtdUserAdmin] = useState(0);
  const [appPedidoVendaContratado, setAppPedidoVendaContratado] =
    useState(false);
  const [qtdUserAppPedidoVenda, setQtdUserAppPedidoVenda] = useState(0);
  const [vlrPorAppPedidoVenda, setVlrPorAppPedidoVenda] = useState(0);
  const [appGerencialContratado, setAppGerencialContratado] = useState(false);
  const [qtdUserAppGerencial, setQtdUserAppGerencial] = useState(0);
  const [sistemaOpenContratado, setSistemaOpenContratado] = useState(false);
  const [appProntaEntregaContratado, setAppProntaEntregaContratado] =
    useState(false);
  const [qtdUserAppProntaEntrega, setQtdUserAppProntaEntrega] = useState(0);
  const [vlrPorAppProntaEntrega, setVlrPorAppProntaEntrega] = useState(0);
  const [respLegal, setRespLegal] = useState("");

  // Implantação
  const [modoImplanta, setModoImplanta] = useState("");
  const [idSistemaAtual, setIdSistemaAtual] = useState(null);
  const [temTef, setTemTef] = useState(false);
  const [temMigCadProduto, setTemMigCadProduto] = useState(false);
  const [temMigCadCliente, setTemMigCadCliente] = useState(false);
  const [temMigContasPagar, setTemMigContasPagar] = useState(false);
  const [temMigContasReceber, setTemMigContasReceber] = useState(false);
  const [temMigOs, setTemMigOs] = useState(false);
  const [temMigVendas, setTemMigVendas] = useState(false);

  // Controle
  const [podeAlterarDadosSensiveis, setPodeAlterarDadosSensiveis] =
    useState(false);

  const limparDados = () => {
    setAtivo(true);
    setNomeFant("");
    setRazaoSocial("");
    setCnpj("");
    setRegimeTribut(regimesTribut[0].value);
    setSiglaUser("");
    setTipoCertificado("FILE");
    setSenhaCertificado("");
    setNroSerieCertificado(null);
    setPossuiServidorLocal(false);
    setIpBancoDados("");
    setPortaBancoDados(5432);
    setDataLim(new Date());
    setLicenca(0);
    setCertificado(null);
    setMudouCertificado(false);
    setCertificadoConfigurado(false);
    setLogo(null);
    setMudouLogo(false);
    setValidadeCertificado("");
    setIdPlanoContratado(0);
    setAppProntaEntregaContratado(false);
    setQtdUserAppProntaEntrega(0);
    setAppPedidoVendaContratado(false);
    setQtdUserAppPedidoVenda(0);
    setAppGerencialContratado(false);
    setQtdUserAppGerencial(0);
    setQtdUserAdmin(0);
    setSistemaOpenContratado(false);
    setTipoSistema("PADRAO");
    setEmailProprietario("");
    setIdContabilidade(null);
    setModoImplanta("");
    setIdSistemaAtual(null);
    setTemTef(false);
    setTemMigCadProduto(false);
    setTemMigCadCliente(false);
    setTemMigContasPagar(false);
    setTemMigContasReceber(false);
    setTemMigOs(false);
    setTemMigVendas(false);
    setDataInicioContrato(null);
    setDiaCobranca(10);
    setVlrImplantacao(0);
    setVlrMigDados(0);
    setVlrSistemaAdmin(0);
    setVlrPersonalizaSist(0);
    setRespLegal("");
  };

  const buscarLogo = async () =>
    auth
      .get(`${routesBaseEmpresa}/buscar_logo/${selected}/`, {
        responseType: "blob",
      })
      .then(
        (res: any) =>
          new Promise((resolve) => {
            if (res.status !== 200) {
              resolve(null);
            }
            const reader = new FileReader();
            reader.onloadend = () =>
              resolve({ file: null, base64: reader.result });
            reader.readAsDataURL(res.data);
          })
      )
      .catch((err: any) => {
        toastr.error("Erro", err.message);
        return null;
      });

  const carregarLogo = async () => {
    setLoadingLogo(true);
    const resLogo = await buscarLogo();
    setLogo(resLogo as any);
    setLoadingLogo(false);
  };

  const fetchData = async (data: any) => {
    setLicenca(selected);
    setAtivo(data.ativo);
    setNomeFant(data.nome_fant);
    setRazaoSocial(data.razao_social);
    setRegimeTribut(data.regime_tribut);
    setCnpj(data.cnpj);
    setSiglaUser(data.sigla_user ?? "");
    setTipoCertificado(data.tipo_certificado);
    setSenhaCertificado(data.senha_certificado ?? "");
    setNroSerieCertificado(data.nro_serie_certificado);
    setValidadeCertificado(data.validade_certif ?? "");
    setPossuiServidorLocal(data.possui_servidor_local ?? false);
    setPortaBancoDados(data.porta_banco_dados);
    setIpBancoDados(data.ip_banco_dados ?? "");
    setDataLim(data.data_lim);
    setIdPlanoContratado(data.id_plano_contratado);
    setCertificadoConfigurado(data.certificado_configurado);
    setTipoSistema(data.tipo_sist);
    setEmailProprietario(data.email_proprietario);
    setIdContabilidade(data.ci_id_contabilidade);
    setAppProntaEntregaContratado(data.app_pronta_entrega_contratado);
    setAppPedidoVendaContratado(data.app_pedido_venda_contratado);
    setAppGerencialContratado(data.app_gerencial_contratado);
    setSistemaOpenContratado(data.sistema_open_contratado);

    setDataInicioContrato(data.contrato?.data_inicio_contrato);
    setDiaCobranca(data.contrato?.dia_cobranca ?? 10);
    setVlrImplantacao(parseFloat(data.contrato?.vlr_implantacao ?? "0"));
    setVlrMigDados(parseFloat(data.contrato?.vlr_mig_dados ?? "0"));
    setVlrPersonalizaSist(
      parseFloat(data.contrato?.vlr_personaliza_sist ?? "0")
    );
    setVlrSistemaAdmin(parseFloat(data.contrato?.vlr_sistema_admin ?? "0"));
    setQtdUserAdmin(data.contrato?.qtd_user_sistema_admin);
    setVlrUserAdm(parseFloat(data.contrato?.vlr_user_adm ?? "0"));
    setQtdUserAppProntaEntrega(data.contrato?.qtd_user_app_pronta_entrega);
    setVlrPorAppProntaEntrega(
      parseFloat(data.contrato?.vlr_por_app_pronta_entrega ?? "0")
    );
    setQtdUserAppPedidoVenda(data.contrato?.qtd_user_app_pedido_venda);
    setVlrPorAppPedidoVenda(
      parseFloat(data.contrato?.vlr_por_app_pedido_venda ?? "0")
    );
    setQtdUserAppGerencial(data.contrato?.qtd_user_app_gerencial);
    setRespLegal(data.contrato?.resp_legal ?? "");
    await carregarLogo();
  };

  const incluirLogo = async (
    base64: FileReader["result"],
    file: File | null
  ) => {
    if (!base64) {
      setLogo(null);
      return;
    }
    const res = {
      file: file,
      base64: base64 as string,
    };

    setLogo(res);
    setMudouLogo(true);
  };

  const verificarDadosCertif = async (file: File) => {
    if (!senhaCertificado) return;
    const payload = { senha_certificado: senhaCertificado };
    let formData = new FormData();
    formData.append("json", JSON.stringify(payload));
    formData.append("certificado", file, file.name);

    setLoadingLerCertif(true);
    const [ok, ret] = await authPostV2(
      `${routesBaseEmpresa}/ler_dados_certificado/`,
      formData,
      { successMessage: false }
    );
    if (ok) {
      setValidadeCertificado(ret.data_validade);
      setNroSerieCertificado(ret.nro_serie);
    }
    setLoadingLerCertif(false);
  };

  const handleSetCertificado = async (file?: File) => {
    if (!file) {
      setCertificado(null);
      return;
    }
    var reader = new FileReader();

    reader.addEventListener("load", async function (e) {
      const res = {
        file: file!,
        base64: e.target?.result as string,
      };

      if (action === ModalActions.edit) {
        verificarDadosCertif(file);
      }

      setCertificado(res);
      setMudouCertificado(true);
    });

    reader.readAsDataURL(file!);
  };

  const submitPayload = (action: ModalActions) => {
    if (loadingLerCertif) return false;

    if (qtdUserAdmin === 0) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a quantidade de usuários " +
          "com acesso ao sistema de Retaguarda/Administrativo"
      );
      return false;
    }

    if (appProntaEntregaContratado && qtdUserAppProntaEntrega === 0) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a quantidade de APPs " +
          "do sistema de Pronta Entrega ativos."
      );
      return false;
    }

    if (appPedidoVendaContratado && qtdUserAppPedidoVenda === 0) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a quantidade de APPs " +
          "do sistema de Pedido Externo ativos."
      );
      return false;
    }

    if (appGerencialContratado && qtdUserAppGerencial === 0) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a quantidade de APPs " +
          "do sistema Gerencial ativos."
      );
      return false;
    }

    if (action === ModalActions.add) {
      // Valida se dados do contrato e da implantação foram preenchidos
      if (["", null, undefined].includes(modoImplanta)) {
        toastr.warning("Atenção", "Por favor, informe o Modo da Implantação");
        return false;
      }

      if ([0, null, undefined].includes(idSistemaAtual)) {
        toastr.warning(
          "Atenção",
          "Por favor, informe o Sistema Atual da Empresa"
        );
        return false;
      }
    }

    const payload: any = {
      nome_fant: nomeFant,
      cnpj: cnpj,
      regime_tribut: regimeTribut,
      tipo_certificado: tipoCertificado,
      senha_certificado: senhaCertificado,
      possui_servidor_local: possuiServidorLocal,
      ip_banco_dados: ipBancoDados,
      porta_banco_dados: portaBancoDados,
      id_plano_contratado: idPlanoContratado ?? 0,
      tipo_sist: tipoSistema,
      ativo: ativo,
      email_proprietario: emailProprietario ?? "",
      id_contabilidade: idContabilidade ?? 0,
      data_validade_certificado:
        validadeCertificado != null ? formatDate(validadeCertificado) : null,
      nro_serie_certificado: nroSerieCertificado,
      app_pronta_entrega_contratado: appProntaEntregaContratado,
      app_pedido_venda_contratado: appPedidoVendaContratado,
      app_gerencial_contratado: appGerencialContratado,
      sistema_open_contratado: sistemaOpenContratado,
    };

    if (podeAlterarDadosSensiveis || action === ModalActions.add) {
      payload["contrato"] = {
        dia_cobranca: diaCobranca,
        vlr_implantacao: vlrImplantacao,
        vlr_mig_dados: vlrMigDados,
        vlr_personaliza_sist: vlrPersonalizaSist,
        vlr_sistema_admin: vlrSistemaAdmin,
        qtd_user_sistema_admin: qtdUserAdmin,
        vlr_user_adm: vlrUserAdm,
        qtd_user_app_pronta_entrega: qtdUserAppProntaEntrega,
        vlr_por_app_pronta_entrega: vlrPorAppProntaEntrega,
        qtd_user_app_pedido_venda: qtdUserAppPedidoVenda,
        vlr_por_app_pedido_venda: vlrPorAppPedidoVenda,
        qtd_user_app_gerencial: qtdUserAppGerencial,
        resp_legal: respLegal,
      };
    }

    if (action === ModalActions.add) {
      payload["sigla_user"] = siglaUser ?? "";
    } else {
      payload["razao_social"] = razaoSocial;
      payload["licenca"] = selected;
      payload["data_limite"] = formatDate(dataLim);
    }

    let formData = new FormData();
    formData.append("json", JSON.stringify(payload));
    if (certificado && mudouCertificado) {
      formData.append("certificado", certificado.file, certificado.file.name);
    }

    if (logo && mudouLogo && logo.file) {
      formData.append("logo", logo.file, logo.file.name);
    }

    return [formData, { editMethod: "POST" }];
  };

  const handleSetPossuiServidorLocal = (v: boolean) => {
    setPossuiServidorLocal(v);
    if (!v) {
      setIpBancoDados("");
    }
  };

  const downloadCertificado = async () => {
    const [data, filename] = await auth
      .get(`${routesBaseEmpresa}/download_certificado/${selected}/`, {
        responseType: "blob",
      })
      .then(async (ret) => {
        if (ret.data.type === "application/x-pkcs12") {
          const filename =
            ret.headers["content-disposition"] ||
            `certificado_l${selected}.pfx`;
          return [ret.data, filename];
        } else if (ret.data.type === "application/json") {
          const json_ret = JSON.parse(await ret.data.text());
          toastr.error("Erro", json_ret.msg);
        }

        return [false, null];
      })
      .catch((err) => {
        toastr.error("Erro", err.message);
        return [false, null];
      });

    if (data) {
      downloadFileFromBlob(data, filename);
    }
  };

  const incluirProcessoImplantacao = async (idEmpresa: any) => {
    debugger;
    const payload = {
      id_empresa: idEmpresa,
      modo_implanta: modoImplanta,
      tem_tef: temTef,
      id_sistema_atual: idSistemaAtual,
      tem_mig_cad_produto: temMigCadProduto,
      tem_mig_cad_cliente: temMigCadCliente,
      tem_mig_contas_pagar: temMigContasPagar,
      tem_mig_contas_receber: temMigContasReceber,
      tem_mig_os: temMigOs,
      tem_mig_vendas: temMigVendas,
    };

    const [ok] = await ImplantacaoService.iniciar(payload);
    if (!ok) {
      toastr.warning(
        "Falha ao abrir processo de implantação",
        "Por favor, realize a abertura manualmente"
      );
    }

    if (notifyEvent) notifyEvent(action);
  };

  const testarConexao = async () => {
    setLoadingTesteConexao(true);
    try {
      const payload = {
        ip_testar: ipBancoDados,
        porta_testar: portaBancoDados,
      };
      await authPostV2(`${routesBaseEmpresa}/testar_conexao_banco/`, payload);
    } finally {
      setLoadingTesteConexao(false);
    }
  };

  const configurarTela = (action: any, params: any) => {
    setPodeAlterarDadosSensiveis(params.pode_alterar_dados_sensiveis ?? false);
  };

  const ajustarQtdesApps = () => {
    if (!appProntaEntregaContratado) {
      setQtdUserAppProntaEntrega(0);
    }

    if (!appPedidoVendaContratado) {
      setQtdUserAppPedidoVenda(0);
    }
    if (!appGerencialContratado) {
      setQtdUserAppGerencial(0);
    }
  };

  useEffect(ajustarQtdesApps, [
    appProntaEntregaContratado,
    appPedidoVendaContratado,
    appGerencialContratado,
  ]);

  const camposPlanoTipoSistema = (
    <>
      <RadioGroup
        label="Tipo de Sistema"
        value={tipoSistema}
        onChange={setTipoSistema}
        options={tipoSistemaOptions}
      />
    </>
  );

  const campoCnpj = (
    <MaskedInput
      mask="99.999.999/9999-99"
      name="cnpj"
      label={"CNPJ"}
      md={4}
      value={cnpj}
      onChange={setCnpj}
    />
  );

  const campoEmailProprietario = (
    <TextInput
      md={action === ModalActions.add ? 7 : 5}
      upperCaseValue={false}
      type="email"
      label="E-mail Proprietário"
      value={emailProprietario}
      onChange={setEmailProprietario}
    />
  );

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleEmpresa}
      size="xl"
      onClose={limparDados}
      onOpen={configurarTela}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseEmpresa}
      number="003_1"
      selected={selected}
      notifyEvent={
        action === ModalActions.add ? incluirProcessoImplantacao : notifyEvent
      }
      useApi={APIs.auth}
      returnIdOnAdd={true}
      paramsName={"cadastro_empresa"}
    >
      <Row className="no-gutters">
        <Col className="no-gutters" md={8}>
          <Row>
            <TextInput
              label="Nome Fantasia"
              md={action === ModalActions.add ? 8 : 10}
              onChange={setNomeFant}
              value={nomeFant}
              autoFocus
              maxLength={64}
            />
            {action === ModalActions.add && campoCnpj}
            {action === ModalActions.edit && (
              <FixedField
                padded
                md={2}
                label="Licença"
                value={licenca}
                horizontal
                labelStyle={{ marginBottom: "13px" }}
              />
            )}
          </Row>
          {action === ModalActions.edit && (
            <Row>
              <TextInput
                label="Razão Social"
                md={10}
                onChange={setRazaoSocial}
                value={razaoSocial}
                maxLength={64}
              />
            </Row>
          )}
          {action === ModalActions.edit && (
            <Row>
              {campoCnpj}
              <DatePicker
                label="Data Limite da Licença"
                value={dataLim}
                onChange={setDataLim}
              />
              <FormCheckbox label="Ativo" checked={ativo} onChange={setAtivo} />
            </Row>
          )}
          <Row>
            <ComboBox
              md={6}
              label="Regime Tributário"
              options={regimesTribut}
              isSearchable={false}
              onChange={setRegimeTribut}
              value={regimeTribut}
            />
            <AsyncComboBox
              md={6}
              isConcatField
              concatModelName="contabilidade"
              defaultOptions
              label="Contabilidade"
              isSearchable
              onChange={setIdContabilidade}
              value={idContabilidade}
            />
          </Row>
          {action === ModalActions.add && <Row>{camposPlanoTipoSistema}</Row>}
          {action === ModalActions.add && <Row>{campoEmailProprietario}</Row>}
        </Col>
        {loadingLogo ? (
          <div
            className="ml-auto"
            style={{
              display: "flex",
              width: "30%",
              height: "225px",
              background: "#eee",
              border: "1px solid #dee2e6",
            }}
          >
            <Spinner className="m-auto" size="md" color="silver" />
          </div>
        ) : (
          <DragDropImagem
            placeholder="Adicionar Logo"
            divClassName="ml-auto"
            width="30%"
            height="225px"
            value={logo?.base64}
            onChange={incluirLogo}
            noDelete
            acceptFormats="png"
          />
        )}
      </Row>
      <Row>
        {action === ModalActions.edit && camposPlanoTipoSistema}
        {action === ModalActions.edit && campoEmailProprietario}
      </Row>
      <Divider className="mb-0">Certificado Digital</Divider>
      <Row>
        <RadioGroup
          options={[
            { label: "Arquivo", value: "FILE" },
            { label: "Cartão/Token", value: "CART" },
          ]}
          onChange={(v) => {
            if (v !== tipoCertificado) {
              setNroSerieCertificado(null);
              setCertificado(null);
              setCertificadoConfigurado(false);
              setValidadeCertificado(null);
            }
            setTipoCertificado(v);
          }}
          label="Tipo de Certificado"
          value={tipoCertificado}
        />
        {tipoCertificado === "FILE" && (
          <Row className="mb-2">
            {certificadoConfigurado && (
              <Col md="auto" style={{ display: "flex" }}>
                <Row className="no-gutters mt-3">
                  <Label
                    className="mb-0 no-gutters"
                    style={{
                      fontWeight: "bold",
                      color: "blue",
                      alignSelf: "center",
                      margin: "0 !important",
                      padding: "0px",
                      paddingRight: "15px",
                    }}
                  >
                    Certificado Configurado
                  </Label>
                  <IconButton
                    icon={BsDownload}
                    onClick={(e) => {
                      e.preventDefault();

                      downloadCertificado();
                    }}
                    style={{ alignSelf: "center" }}
                    hint="Fazer download do certificado configurado"
                  />
                </Row>
              </Col>
            )}
            <DragDropCert
              certificado={certificado}
              handleSetCertificado={handleSetCertificado}
              certificadoConfigurado={certificadoConfigurado}
            />
            <div className="mt-4">
              <PopModal
                idPop={11}
                label="Converter certificado para .pfx"
                buttonType="label"
                className="mt-3"
              />
            </div>
          </Row>
        )}
      </Row>
      <Row>
        {(action === ModalActions.edit || tipoCertificado === "CART") && (
          <TextInput
            md={5}
            type="text"
            label="Número de Série do Certificado"
            value={nroSerieCertificado ?? ""}
            onChange={setNroSerieCertificado}
            unlockToEdit={true}
          />
        )}
        <TextInput
          md={3}
          label="Senha do Certificado"
          value={senhaCertificado}
          onChange={setSenhaCertificado}
          upperCaseValue={false}
          onBlur={() =>
            mudouCertificado &&
            certificado &&
            verificarDadosCertif(certificado.file)
          }
        />
        {validadeCertificado && (
          <FixedField
            padded
            label="Validade do Certificado"
            value={validadeCertificado ?? ""}
            horizontal
            labelStyle={{ marginBottom: "13px" }}
          />
        )}
      </Row>
      <Divider className="mb-0">Dados Técnicos</Divider>
      <Row>
        <TextInput
          label="Sigla"
          md={3}
          onChange={(v: any) =>
            setSiglaUser(v?.replace(/[^a-zA-Z0-9_@]/gi, "")?.toLowerCase())
          }
          value={siglaUser}
          upperCaseValue={false}
          updateEveryRender
          disabled={action === ModalActions.edit}
        />
        <FormCheckbox
          divClassName="p-0"
          md={2}
          label="Possui Servidor Local"
          checked={possuiServidorLocal}
          onChange={handleSetPossuiServidorLocal}
        />
      </Row>
      {possuiServidorLocal && (
        <Row>
          <TextInput
            label="IP do Banco de Dados"
            md={4}
            upperCaseValue={false}
            value={ipBancoDados}
            onChange={setIpBancoDados}
            unlockToEdit={action === ModalActions.edit}
            maxLength={50}
          />
          <IntegerInput
            label="Porta"
            md={2}
            value={portaBancoDados}
            onChange={setPortaBancoDados}
            style={{ textAlign: "center" }}
            unlockToEdit={action === ModalActions.edit}
          />
          <FormButton
            color="secondary"
            onClick={testarConexao}
            loading={loadingTesteConexao}
          >
            Testar Conexão
          </FormButton>
        </Row>
      )}
      <Divider className="mb-0">Usuários Contratados</Divider>
      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "230px",
            justifyContent: "space-between",
          }}
        >
          <Label className="mt-auto mb-2" md="auto">
            Quantidade de usuários Web ADM
          </Label>
        </div>
        <IntegerInput
          style={{ textAlign: "center", marginTop: "0.1rem" }}
          md="1"
          divClassName="px-0"
          value={qtdUserAdmin}
          onChange={setQtdUserAdmin}
          disabled={!podeAlterarDadosSensiveis}
        />
        {podeAlterarDadosSensiveis && (
          <NumberInput
            md={3}
            mdInput={4}
            label="Valor Usuário ADM"
            value={vlrUserAdm}
            onChange={setVlrUserAdm}
          />
        )}
      </Row>
      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "230px",
            justifyContent: "space-between",
          }}
        >
          <Label className="mt-auto mb-2" md="auto">
            Pronta Entrega
          </Label>
          <FormCheckbox
            divClassName="mr-2"
            md="auto"
            label="Liberado"
            checked={appProntaEntregaContratado}
            onChange={setAppProntaEntregaContratado}
            disabled={!podeAlterarDadosSensiveis}
          />
        </div>
        <IntegerInput
          style={{ textAlign: "center" }}
          md={1}
          divClassName="px-0"
          label="Qtd. Usuários"
          value={qtdUserAppProntaEntrega}
          onChange={setQtdUserAppProntaEntrega}
          disabled={!appProntaEntregaContratado || !podeAlterarDadosSensiveis}
        />
        {podeAlterarDadosSensiveis && (
          <NumberInput
            md={3}
            mdInput={4}
            label="Valor Por APP Pronta Entrega"
            value={vlrPorAppProntaEntrega}
            onChange={setVlrPorAppProntaEntrega}
            disabled={!appProntaEntregaContratado}
          />
        )}
      </Row>
      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "230px",
            justifyContent: "space-between",
          }}
        >
          <Label className="mt-auto mb-2" md="auto">
            Pedido Externo
          </Label>
          <FormCheckbox
            divClassName="mr-2"
            md="auto"
            label="Liberado"
            checked={appPedidoVendaContratado}
            onChange={setAppPedidoVendaContratado}
            disabled={!podeAlterarDadosSensiveis}
          />
        </div>
        <IntegerInput
          style={{ textAlign: "center" }}
          md={1}
          divClassName="px-0"
          label="Qtd. Usuários"
          value={qtdUserAppPedidoVenda}
          onChange={setQtdUserAppPedidoVenda}
          disabled={!appPedidoVendaContratado || !podeAlterarDadosSensiveis}
        />
        {podeAlterarDadosSensiveis && (
          <NumberInput
            md={3}
            mdInput={4}
            label="Valor Por APP Pedido Venda"
            value={vlrPorAppPedidoVenda}
            onChange={setVlrPorAppPedidoVenda}
            disabled={!appPedidoVendaContratado}
          />
        )}
      </Row>
      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "230px",
            justifyContent: "space-between",
          }}
        >
          <Label className="mt-auto mb-2" md="auto">
            APP Gerencial
          </Label>
          <FormCheckbox
            divClassName="mr-2"
            md="auto"
            label="Liberado"
            checked={appGerencialContratado}
            onChange={setAppGerencialContratado}
            disabled={!podeAlterarDadosSensiveis}
          />
        </div>
        <IntegerInput
          style={{ textAlign: "center" }}
          md={1}
          divClassName="px-0"
          label="Qtd. Usuários"
          value={qtdUserAppGerencial}
          onChange={setQtdUserAppGerencial}
          disabled={!appGerencialContratado || !podeAlterarDadosSensiveis}
        />
      </Row>
      <Divider>DADOS CONTRATUAIS</Divider>
      <Row>
        <AsyncComboBox
          md={4}
          isConcatField
          concatModelName="plano_contratado"
          defaultOptions
          label="Plano Contratado"
          isSearchable={false}
          onChange={setIdPlanoContratado}
          value={idPlanoContratado}
          disabled={!podeAlterarDadosSensiveis}
        />
        {podeAlterarDadosSensiveis && (
          <>
            <IntegerInput
              md={2}
              label="Dia de Cobrança"
              value={diaCobranca}
              onChange={setDiaCobranca}
              style={{ textAlign: "center" }}
            />
            <TextInput
              label="Responsável Legal"
              md={6}
              value={respLegal}
              onChange={setRespLegal}
              maxLength={100}
              upperCaseValue
            />
          </>
        )}
      </Row>
      {podeAlterarDadosSensiveis && (
        <Row>
          <NumberInput
            md={2}
            label="Valor Implantação"
            value={vlrImplantacao}
            onChange={setVlrImplantacao}
          />
          <NumberInput
            md={2}
            label="Valor Mig. Dados"
            value={vlrMigDados}
            onChange={setVlrMigDados}
          />
          <NumberInput
            md={2}
            label="Valor Personalização"
            value={vlrPersonalizaSist}
            onChange={setVlrPersonalizaSist}
          />
          <NumberInput
            md={2}
            label="Valor Sistema ADM"
            value={vlrSistemaAdmin}
            onChange={setVlrSistemaAdmin}
          />
        </Row>
      )}
      {action === ModalActions.add && (
        <>
          <Divider>PROCESSO DE IMPLANTAÇÃO</Divider>
          <Row>
            <ComboBox
              md={4}
              label="Modo"
              value={modoImplanta}
              options={modoImplantaOptions}
              onChange={setModoImplanta}
            />
            <AsyncComboBox
              md={6}
              label="Sistema Atual"
              concatModelName="sistema_terceiro"
              value={idSistemaAtual}
              onChange={setIdSistemaAtual}
            />
            <FormCheckbox
              label="Tem Tef"
              checked={temTef}
              onChange={setTemTef}
            />
          </Row>
          <Divider>Migrações</Divider>
          <Row>
            <FormCheckbox
              padded={false}
              label="Cadastro de Produto"
              checked={temMigCadProduto}
              onChange={setTemMigCadProduto}
              className="fb-2"
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Cadastro de Cliente"
              checked={temMigCadCliente}
              onChange={setTemMigCadCliente}
              className="fb-2"
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Contas a Pagar"
              checked={temMigContasPagar}
              onChange={setTemMigContasPagar}
              className="fb-2"
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Contas a Receber"
              checked={temMigContasReceber}
              onChange={setTemMigContasReceber}
              className="fb-2"
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Ordens de Serviço"
              checked={temMigOs}
              onChange={setTemMigOs}
              className="fb-2"
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Vendas"
              checked={temMigVendas}
              onChange={setTemMigVendas}
              className="fb-2"
            />
          </Row>
        </>
      )}
    </ModalCadastroV2>
  );
};
