import { FC } from "react";
import { TabBody, TabBodyProps } from "../../../../components/TabController";
import { ItemCheckbox } from "./ItemCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { AtualizacoesSetorForm } from "./AtualizacoesSetorForm";
import ImplantacaoService from "../../../../services/ImplantacaoService";
import { setAtuDev, setupDev } from "../store/detalhesImplantSlice";

interface Props extends TabBodyProps {}

export const TabMigracao: FC<Props> = ({ title, active }) => {
  const store = useSelector((state: any) => state.detalhesImplant);
  const itens = store.dev;
  const dispatch = useDispatch();

  const carregarDados = async () => {
    const [ok, ret] = await ImplantacaoService.buscarItens(
      store.selected,
      "DEV"
    );
    if (ok) {
      dispatch(setupDev(ret));
    }
  };

  return (
    <TabBody title={title} active={active} onActivate={() => carregarDados()}>
      {itens.tem_mig_cad_produto && (
        <ItemCheckbox
          label="Migrar Cadastro de Produto"
          name="mig_cad_produto"
          checked={itens.mig_cad_produto}
          notifyEvent={carregarDados}
        />
      )}
      {itens.tem_mig_cad_cliente && (
        <ItemCheckbox
          label="Migrar Cadastro de Clientes"
          name="mig_cad_cliente"
          checked={itens.mig_cad_cliente}
          notifyEvent={carregarDados}
        />
      )}
      {itens.tem_mig_contas_pagar && (
        <ItemCheckbox
          label="Migrar Contas a Pagar"
          name="mig_contas_pagar"
          checked={itens.mig_contas_pagar}
          notifyEvent={carregarDados}
        />
      )}
      {itens.tem_mig_contas_receber && (
        <ItemCheckbox
          label="Migrar Contas a Receber"
          name="mig_contas_receber"
          checked={itens.mig_contas_receber}
          notifyEvent={carregarDados}
        />
      )}
      {itens.tem_mig_os && (
        <ItemCheckbox
          label="Migrar Ordens de Serviço"
          name="mig_os"
          checked={itens.mig_os}
          notifyEvent={carregarDados}
        />
      )}
      {itens.tem_mig_vendas && (
        <ItemCheckbox
          label="Migrar Vendas"
          name="mig_vendas"
          checked={itens.mig_vendas}
          notifyEvent={carregarDados}
        />
      )}
      <AtualizacoesSetorForm
        setor="DEV"
        abaAtiva={active ?? false}
        dados={itens.atualizacoes}
        setDados={(d) => dispatch(setAtuDev(d))}
      />
    </TabBody>
  );
};
