import { FC, useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FormCheckbox,
  TextInput,
} from "../../../../components";
import { PageContainer } from "../../../../components/PageContainer";
import { CadastroUsuariosModal } from "./components/CadastroUsuariosModal";
import { UsuariosGrid } from "./components/UsuariosGrid";
import { authGetV2 } from "../../../../apiV2";
import { ModalActions } from "../../../../components/cadastro/ModalCadastroV2";
import { BotaoIncluir } from "../../../../components/cadastro";

export const modalTitleUsuario = "Usuário";
export const routesBaseUsuario = "/cadastro/usuario";

export const Usuarios: FC = () => {
  const [empresa, setEmpresa] = useState(null);
  const [nickname, setNickName] = useState("");
  const [somenteAtivos, setSomenteAtivos] = useState(true);
  const [selected, setSelected] = useState(null);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(ModalActions.add);
  const [cadastroOpen, setCadastroOpen] = useState(false);

  const limparDados = () => setDados([]);

  const carregarDados = async () => {
    setLoading(true);
    const params: any = {
      id_empresa: empresa,
      nickname: nickname,
      somente_ativos: somenteAtivos,
    };

    const [ok, ret] = await authGetV2(`${routesBaseUsuario}/listar/`, params);

    setDados(ok ? ret : []);
    setLoading(false);
  };

  useEffect(() => {
    carregarDados();
  }, []);

  const notifyEvent = (action: any) => {
    carregarDados();
  };

  useEffect(() => {
    limparDados();
  }, [empresa, nickname, somenteAtivos]);

  const toggleCadastro = (action: ModalActions) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const alterar = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(ModalActions.edit);
    }, 1);
  };

  return (
    <PageContainer
      title="Usuários habilitados sistema Skillsoft"
      number="002"
      canGoBack
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            isConcatField
            concatModelName="empresa"
            defaultOptions
            label="Empresa"
            isSearchable
            isClearable
            onChange={setEmpresa}
            value={empresa}
            autoFocus
          />
          <TextInput
            label="Nickname"
            md={4}
            onChange={setNickName}
            value={nickname}
            upperCaseValue={false}
          />
          <FormCheckbox
            label="Somente Ativos"
            onChange={setSomenteAtivos}
            checked={somenteAtivos}
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={() => toggleCadastro(ModalActions.add)} />
          <CadastroUsuariosModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <UsuariosGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
        />
      </Card>
    </PageContainer>
  );
};
