import { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Col } from "reactstrap";

interface Props {
  md?: number | string;
  accept?: string;
  onDrop?: any;
  multiple?: boolean;
  maxFiles?: number;
  text?: any;
  style?: React.CSSProperties;
  divClassName?: string;
}

const DragAndDropArquivos: FC<Props> = ({
  md = 3,
  accept = "*",
  onDrop,
  multiple = false,
  maxFiles = 0,
  style,
  text = "Arraste e solte um arquivo aqui, ou, clique para selecionar o arquivo",
  divClassName,
}) => {
  const dropzoneOptions = { multiple, maxFiles } as any;

  const _onDrop = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        acceptedFiles.forEach((file: any) => onDrop(file));
      }
    },
    [onDrop]
  );

  dropzoneOptions["onDrop"] = _onDrop;

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  return (
    <Col md={md} className={divClassName}>
      <div
        {...getRootProps({
          className: "dropzone",
          style: style,
        })}
      >
        <input
          {...getInputProps({
            multiple: false,
            accept: accept !== "*" ? accept : undefined,
          })}
        />
        <span>{text}</span>
      </div>
    </Col>
  );
};

export default DragAndDropArquivos;
