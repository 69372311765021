import moment from "moment";
import cookies from "js-cookie";
import { pdf } from "@react-pdf/renderer";
import { toastr } from "react-redux-toastr";

export const isDevEnv = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return true;
  } else {
    return false;
  }
};

export const MODAL_ACTIONS = Object.freeze({
  ADD: 1,
  EDIT: 2,
  DELETE: 3,
});

export const selectedRowColor = "#EDF5FC";

export const readOnlySelection = {
  mode: "radio",
  clickToSelect: true,
  bgColor: selectedRowColor,
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// `wait` milliseconds.
export const debounce = (func, wait) => {
  let timeout;

  // This is the function that is returned and will be executed many times
  // We spread (...args) to capture any number of parameters we want to pass
  return function executedFunction(...args) {
    // The callback function to be executed after
    // the debounce time has elapsed
    const later = () => {
      // null timeout to indicate the debounce ended
      timeout = null;

      // Execute the callback
      func(...args);
    };
    // This will reset the waiting every function execution.
    // This is the step that prevents the function from
    // being executed because it will never reach the
    // inside of the previous setTimeout
    clearTimeout(timeout);

    // Restart the debounce waiting period.
    // setTimeout returns a truthy value (it differs in web vs Node)
    timeout = setTimeout(later, wait);
  };
};

export const naturalGenericSort = (a, b, order) => {
  let _a = String(a);
  let _b = String(b);
  if (order === "desc") {
    _a = String(b);
    _b = String(a);
  }
  return _a.localeCompare(_b, undefined, {
    sensitivity: "base",
  });
};

export const naturalSort = (a, b, order) => {
  let _a = a;
  let _b = b;
  if (order === "desc") {
    _a = b;
    _b = a;
  }

  return String(_a).localeCompare(String(_b), undefined, {
    numeric: true,
    sensitivity: "base",
  });
};

export const naturalSortDate = (a, b, order) =>
  naturalSort(
    moment(a, "DD/MM/YYYY").isValid()
      ? moment(a, "DD/MM/YYYY").toDate().toISOString()
      : "",
    moment(b, "DD/MM/YYYY").isValid()
      ? moment(b, "DD/MM/YYYY").toDate().toISOString()
      : "",
    order
  );

export const defaultDebounceTime = 750;

export const ufs = [
  { label: "AC", value: "AC" },
  { label: "AL", value: "AL" },
  { label: "AP", value: "AP" },
  { label: "AM", value: "AM" },
  { label: "BA", value: "BA" },
  { label: "CE", value: "CE" },
  { label: "DF", value: "DF" },
  { label: "ES", value: "ES" },
  { label: "GO", value: "GO" },
  { label: "MA", value: "MA" },
  { label: "MT", value: "MT" },
  { label: "MS", value: "MS" },
  { label: "MG", value: "MG" },
  { label: "PA", value: "PA" },
  { label: "PB", value: "PB" },
  { label: "PR", value: "PR" },
  { label: "PE", value: "PE" },
  { label: "PI", value: "PI" },
  { label: "RJ", value: "RJ" },
  { label: "RN", value: "RN" },
  { label: "RS", value: "RS" },
  { label: "RO", value: "RO" },
  { label: "RR", value: "RR" },
  { label: "SC", value: "SC" },
  { label: "SP", value: "SP" },
  { label: "SE", value: "SE" },
  { label: "TO", value: "TO" },
];

export const pessoa = [
  {
    label: "Física",
    value: "F",
  },
  {
    label: "Jurídica",
    value: "J",
  },
];

export const handleFocus = (e) => {
  if ((e.key === "Enter" || e.key === "ArrowUp") && e.target.form) {
    const action = e.key === "Enter" ? 1 : -1;
    const formFilter = Array.from(e.target.form.elements).filter(
      (e) =>
        ["text", "textarea", "time", "password"].includes(e.type) &&
        !["hidden", "button"].includes(e.type) &&
        !e.disabled
    );

    let targetIndex = formFilter.indexOf(e.target) + action;

    if (targetIndex > formFilter.length - 1) {
      targetIndex = 0;
    } else if (targetIndex < 0) {
      targetIndex = formFilter.length - 1;
    }

    if (formFilter[targetIndex]) formFilter[targetIndex].focus();
  }
};

export const handleTableFocus = (tableId, rowIndex, keyCode) => {
  const currRow = document.getElementById(`${tableId}--row-${rowIndex}`);
  if (document.activeElement === currRow) {
    if (keyCode === "ArrowDown") {
      //down
      const nextRow = document.getElementById(
        `${tableId}--row-${rowIndex + 1}`
      );
      if (nextRow) {
        nextRow.focus();
        nextRow.click();
      }
    }
    if (keyCode === "ArrowUp") {
      //up
      const prevRow = document.getElementById(
        `${tableId}--row-${rowIndex - 1}`
      );
      if (prevRow) {
        prevRow.focus();
        prevRow.click();
      }
    }
  }
};

export const formatDate = (v) =>
  v instanceof Date
    ? v.toISOString().split("T")[0]
    : moment(v, "DD/MM/YYYY").isValid()
    ? moment(v, "DD/MM/YYYY").format("YYYY-MM-DD")
    : v;

export const isValidDate = (d) => {
  return moment(d, "MM/DD/YYYY", true).isValid();
};

export const formatValueFromAPI = (value, id, maxLength) => {
  return `${
    maxLength
      ? value?.substring(
          0,
          // Calcula o tamanho máximo do nome considerando o (id)
          maxLength - (id.toString().length + 3)
        )
      : value
  } (${id})`;
};

export const formatNumber = (
  number,
  parseVal = true,
  roundTo = 2,
  checkFloat = false
) => {
  if (parseVal) {
    number = parseFloat(number);
  }
  if (checkFloat && number % 1 === 0) {
    // Número inteiro
    roundTo = 0;
  }
  return number
    .toFixed(roundTo)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const rawAPIUrl = (url) => {
  return (
    `${cookies.get("api-address")}${url}?` +
    `token=${cookies.get("access-token")}` +
    `&database-name=${cookies.get("database-name")}`
  );
};

export const downloadFileFromBlob = (blob, filename) => {
  const link = document.createElement("a");
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute("download", filename);

  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const printReport = async (data, number) => {
  let arq;
  try {
    arq = await pdf(data).toBlob();
  } catch (error) {
    toastr.error("Erro ao imprimir Relatório", error.toString());
    return;
  }

  downloadFileFromBlob(
    arq,
    `${number}_${moment(new Date()).format("DDMMYYYY_hhmmss")}.pdf`
  );
};

export const limparNumero = (v) => (v ?? "").replaceAll(/\D/g, "");

export const formatDateISO = (d) =>
  d instanceof Date ? d.toISOString().split("T")[0] : d;

const sortAnyArray = (x, y) => {
  var pre = ["string", "number", "bool"];
  if (typeof x !== typeof y)
    return pre.indexOf(typeof y) - pre.indexOf(typeof x);

  if (x === y) return 0;
  else return x > y ? 1 : -1;
};

export const compareArraysNoOrder = (a, b) =>
  a instanceof Array &&
  b instanceof Array &&
  a.sort(sortAnyArray) === b.sort(sortAnyArray);

const getRGB = (c) => {
  return parseInt(c, 16) || c;
};

const getsRGB = (c) => {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
};

const getLuminance = (hexColor) => {
  return (
    0.2126 * getsRGB(hexColor.substr(1, 2)) +
    0.7152 * getsRGB(hexColor.substr(3, 2)) +
    0.0722 * getsRGB(hexColor.substr(-2))
  );
};

const getContrast = (f, b) => {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
};

// Define se o texto é preto ou branco conforme o fundo
export const getTextColorBg = (bgColor) => {
  const whiteContrast = getContrast(bgColor, "#ffffff");
  const blackContrast = getContrast(bgColor, "#000000");

  return whiteContrast > blackContrast ? "#ffffff" : "#000000";
};

export const resizeImage = (base64Str, maxWidth = 700, maxHeight = 700) => {
  return new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
};

export const dateFromLocaleString = (v) => {
  const d = moment(v, "DD/MM/YYYY", true);
  return d.isValid() ? d.toDate() : null;
};

export const filterFormFocusableElements = (elements) => {
  return Array.from(elements)
    .filter((e) => {
      return (
        ([
          "text",
          "textarea",
          "time",
          "button",
          "password",
          "radio",
          "checkbox",
          "select-one",
        ].includes(e.type) ||
          e.hasAttribute("tabindex")) &&
        !["hidden"].includes(e.type) &&
        !e.disabled
      );
    })
    .sort((a, b) => a.tabIndex - b.tabIndex);
};

export const extractMarkdownText = (v) => v.replace(/<(.|\n)*?>/g, "");

export const viewDownloadPDF = (data, filename) => {
  downloadFileFromBlob(data, `${filename}.pdf`);
};
