import { FC } from "react";
import classNames from "classnames";

interface Props {
  className?: string;
  children?: any;
  divStyle?: React.CSSProperties;
}

export const Divider: FC<Props> = ({ className, children, divStyle }) => (
  <div className={classNames("divider", className)} style={divStyle}>
    {children && <div className="divider-text">{children}</div>}
    <hr className="divider-line" />
  </div>
);
