import { useState, FC } from "react";
import { Col, Row } from "reactstrap";
import { TextInput } from "../../../../../components";
import {
  ModalActions,
  ModalCadastroProps,
  ModalCadastroV2,
} from "../../../../../components/cadastro/ModalCadastroV2";
import { APIs } from "../../../../../apiV2";
import {
  modalTitlePlanoContratado,
  routesBasePlanoContratado,
} from "../PlanoContratado";
import { DragDropImagem } from "../../../../../components/DragDropImagem";
import { resizeImage } from "../../../../../coreUtils";

interface Props {
  isOpen: ModalCadastroProps["isOpen"];
  toggle: ModalCadastroProps["toggle"];
  action: ModalCadastroProps["action"];
  selected: any;
  notifyEvent: ModalCadastroProps["notifyEvent"];
}

export const CadastroPlanoContratadoModal: FC<Props> = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [descricao, setDescricao] = useState("");
  const [obs, setObs] = useState("");
  const [ativo, setAtivo] = useState(true);
  const [icone, setIcone] = useState<{
    file: File | null;
    base64: string | null;
  } | null>();

  const selecionarIcone = async (
    base64: FileReader["result"],
    file: File | null
  ) => {
    if (!base64) {
      setIcone(null);
      return;
    }
    const f = {
      file: file,
      base64: base64 ? await resizeImage(base64 as string, 12, 12) : null,
    };

    setIcone(f);
  };

  const limparDados = () => {
    setDescricao("");
    setObs("");
    setAtivo(true);
    setIcone(null);
  };

  const fetchData = (data: any) => {
    setDescricao(data.descricao);
    setObs(data.obs);
    setAtivo(data.ativo);
    if (data.icone_base64) {
      if (!data.icone_base64.includes("data:image/png;base64,")) {
        data.icone_base64 = `data:image/png;base64,${data.icone_base64}`;
      }
      setIcone({
        file: null,
        base64: data.icone_base64,
      });
    }
  };

  const submitPayload = (action: any) => {
    const payload = {
      descricao: descricao,
      ativo: ativo,
      obs: obs,
      icone_base64: icone?.base64 ?? "",
    };
    return action === ModalActions.add ? payload : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitlePlanoContratado}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBasePlanoContratado}
      number="010_1"
      selected={selected}
      notifyEvent={notifyEvent}
      useApi={APIs.auth}
    >
      <Row style={{ minHeight: "100px" }}>
        <TextInput
          label="Descrição"
          md={9}
          onChange={setDescricao}
          value={descricao}
          maxLength={60}
        />
        <Col md={3}>
          <DragDropImagem
            placeholder="Selecionar Ícone"
            divClassName="ml-auto"
            width="100%"
            height="80px"
            value={icone?.base64}
            onChange={selecionarIcone}
            acceptFormats="png"
          />
        </Col>
      </Row>
      <Row>
        <TextInput
          label="Observação"
          type="textarea"
          rows={2}
          md={12}
          onChange={setObs}
          value={obs}
        />
      </Row>
    </ModalCadastroV2>
  );
};
