import { FC, useEffect, useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  PageContainer,
  SearchInput,
} from "../../../../../components";
import { Card, Row } from "reactstrap";
import { APIs, authGetV2 } from "../../../../../apiV2";
import { ExcecaoGrid } from "./components/ExcecaoGrid";
import { IncluirExcecaoModal } from "./components/IncluirExcecaoModal";
import { debounce, defaultDebounceTime } from "../../../../../coreUtils";
import { ModalExcluirV2 } from "../../../../../components/cadastro";

export const routesBaseExcecaoMenu = "/cadastro/menu/excecoes";

export const Excecao: FC = () => {
  const [dados, setDados] = useState([]);
  const [idLicenca, setIdLicenca] = useState(null);
  const [pista, setPista] = useState("");
  const [selected, setSelected] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async (p: any) => {
    const params = {
      id_licenca: idLicenca,
      pista: p,
    };
    const [ok, ret] = await authGetV2(
      `${routesBaseExcecaoMenu}/listar/`,
      params
    );
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (p: any) => {
    setPista(p);
    setLoadingPista(true);
    await buscarDados(p);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const excluir = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  useEffect(() => {
    carregarDados();
  }, []);

  return (
    <PageContainer title="Exceções do Menu" number="012_3" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            isConcatField
            concatModelName="empresa"
            label="Empresa"
            isSearchable
            isClearable
            onChange={setIdLicenca}
          />
          <SearchInput
            md={4}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Nº da Tela, Descrição ou Módulo"
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <IncluirExcecaoModal notifyEvent={carregarDados} />
          <ModalExcluirV2
            title="Exceção de Menu"
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="012_32"
            selected={selected}
            routeBase={routesBaseExcecaoMenu}
            notifyEvent={() => {
              setSelected(null);
              carregarDados();
            }}
            useApi={APIs.auth}
          />
        </Row>
      </Card>
      <Card body>
        <ExcecaoGrid dados={dados} excluir={excluir} />
      </Card>
    </PageContainer>
  );
};
