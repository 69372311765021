import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import {
  Button,
  Card,
  CardBody,
  CustomInput,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  UncontrolledAlert,
} from "reactstrap";
import { kClientId, kClientSecret } from "../../api";
import settings from "../../settings";
import { isDevEnv } from "../../coreUtils";

const SignIn = () => {
  const [user, setUser] = useState();
  const [password, setPassword] = useState("");
  const [loginErrMsg, setLoginErrMsg] = useState("");
  const [loginErrAttempt, setLoginErrAttempt] = useState(0);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const loginAttempt = (msg) => {
    setLoginErrMsg(msg);
    setLoginErrAttempt(loginErrAttempt + 1);
  };

  const prepareNickname = (user) => {
    let userToPrepare = user.split("@")[0];
    let preparedNickname =
      userToPrepare.charAt(0).toUpperCase() + userToPrepare.slice(1);
    return preparedNickname;
  };

  const handleClickLogin = async (e) => {
    e.preventDefault();

    setLoginErrMsg("");

    if (user && password) {
      // É gerado um URLSearchParams pois o content-type do POST precisa ser
      // x-www-form-urlencoded
      const data = new URLSearchParams({
        grant_type: "password",
        username: user,
        password: password,
      });

      Cookies.remove("access_token");
      const urlAuth = isDevEnv()
        ? settings.AUTENTICADOR_DEV
        : settings.AUTENTICADOR;
      await axios
        .post(`${urlAuth}/o/token/`, data, {
          timeout: 5000,
          auth: { username: kClientId, password: kClientSecret },
        })
        .then((res) => {
          if (res.status === 200) {
            if (!res.data.acessos?.admin_skill) {
              loginAttempt(
                "Somente usuários administradores Skillsoft " +
                  "tem acesso à esse sistema"
              );
            } else {
              Cookies.set("access_token", res.data.access_token, { path: "/" });
              Cookies.set("refresh_token", res.data.refresh_token, {
                path: "/",
              });
              Cookies.set("user", user, { path: "/" });
              Cookies.set("nickname", prepareNickname(user), { path: "/" });
              localStorage.setItem(
                "id_colaborador",
                res.data?.acessos?.id_colaborador
              );

              window.location = "/";
            }
          } else {
            loginAttempt(res.data.msg);
          }
        })
        .catch((err) => {
          loginAttempt(
            err.response?.data?.error === "invalid_grant"
              ? "Usuário ou senha incorretos."
              : "Falha ao tentar se conectar com o serviço de autenticação."
          );
        });
    } else {
      loginAttempt("Revise o formulário e tente novamente.");
    }
  };

  const nickname = Cookies.get("nickname");

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>Bem vindo {nickname && "de volta, " + nickname}</h2>
        <p className="lead">Faça login na sua conta para continuar</p>
      </div>

      {loginErrMsg.length > 0 && (
        <UncontrolledAlert color="danger">
          <div className="alert-message">
            <strong>Ops!</strong> {loginErrMsg}
          </div>
        </UncontrolledAlert>
      )}

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Form>
              <FormGroup>
                <Label className="label-auth-login">Usuário</Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="user"
                  placeholder="usuario@empresa"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  required
                />
              </FormGroup>

              <FormGroup>
                <Label className="label-auth-login">Senha</Label>
                <InputGroup>
                  <Input
                    bsSize="lg"
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    placeholder="Minha senha"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? (
                        <Eye size={15} />
                      ) : (
                        <EyeOff size={15} />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <div>
                <CustomInput
                  type="checkbox"
                  id="rememberMe"
                  label="Lembrar de mim?"
                  defaultChecked
                />
              </div>

              <div className="text-center mt-3">
                <Button
                  type="submit"
                  size="lg"
                  onClick={handleClickLogin}
                  style={{ backgroundColor: "#4a154b", width: "200px" }}
                >
                  Entrar
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default SignIn;
