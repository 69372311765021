import { FC, useState } from "react";
import {
  AsyncComboBox,
  FormButton,
  TextInput,
} from "../../../../../../components";
import { ModalBase } from "../../../../../../components/ModalBase";
import { toastr } from "react-redux-toastr";
import { authPostV2 } from "../../../../../../apiV2";
import { routesBaseExcecaoMenu } from "../Excecao";
import { Row } from "reactstrap";

interface Props {
  notifyEvent: () => void;
}

export const IncluirExcecaoModal: FC<Props> = ({ notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idLicenca, setIdLicenca] = useState(null);
  const [idMenu, setIdMenu] = useState(null);
  const [motivo, setMotivo] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setIdLicenca(null);
    setIdMenu(null);
    setMotivo("");
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idLicenca)) {
      toastr.warning("Atenção", "Por favor, informe a Empresa");
      return;
    }

    if ([0, null, undefined].includes(idMenu)) {
      toastr.warning("Atenção", "Por favor, informe o Menu");
      return;
    }

    setLoading(true);
    const payload = {
      id_licenca: idLicenca,
      id_menu: idMenu,
      motivo: motivo,
    };
    const [ok] = await authPostV2(`${routesBaseExcecaoMenu}/incluir/`, payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <FormButton md="auto" onClick={toggle} color="info">
        Incluir
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Incluir Exceção do Menu"
        number="012_31"
        onClosed={limparDados}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <AsyncComboBox
            md={12}
            isConcatField
            concatModelName="empresa"
            label="Empresa"
            isSearchable
            onChange={setIdLicenca}
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={12}
            isConcatField
            concatModelName="menu"
            label="Menu"
            isSearchable
            onChange={setIdMenu}
          />
        </Row>
        <Row>
          <TextInput
            md={12}
            label="Motivo"
            type="textarea"
            value={motivo}
            onChange={setMotivo}
            rows={2}
            maxLength={255}
          />
        </Row>
      </ModalBase>
    </>
  );
};
