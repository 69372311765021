import { FC } from "react";
import { useState } from "react";
import { Col, CustomInput, FormGroup, Label } from "reactstrap";

interface PropsRadioItem {
  label: any;
  value: any;
  disabled?: boolean;
  id?: any;
  checked?: boolean;
  className?: string;
}
const RadioItem: FC<PropsRadioItem> = ({
  label = "",
  value,
  disabled,
  id,
  checked,
  className,
}) => (
  <CustomInput
    type="radio"
    label={label}
    value={value}
    id={id}
    inline
    checked={checked}
    disabled={disabled}
    className={className}
  />
);

export interface RadioGroupOption {
  label: string;
  value: any;
  disabled?: boolean;
}

interface PropsRadioGroup {
  label?: any;
  onChange: (v: RadioGroupOption["value"]) => void;
  value?: RadioGroupOption["value"];
  disabled?: boolean;
  divClassName?: string;
  options: RadioGroupOption[];
  labelStyle?: React.CSSProperties;
  optionsClassName?: string;
}
export const RadioGroup: FC<PropsRadioGroup> = ({
  label,
  onChange = (v: any) => {},
  value,
  disabled,
  divClassName,
  options,
  labelStyle,
  optionsClassName,
}) => {
  const [id] = useState(
    options.map((e) => "radio-" + Math.floor(Math.random() * Date.now()))
  );
  return (
    <Col md="auto" className={divClassName}>
      <FormGroup
        onChange={({ target }: any) => {
          onChange(target.value);
        }}
      >
        {label && <Label style={labelStyle}>{label}</Label>}
        <div>
          {options.map((item, index) => (
            <RadioItem
              id={id[index]}
              label={item.label}
              value={item.value}
              checked={value === item.value}
              disabled={disabled || item.disabled}
              className={optionsClassName}
            />
          ))}
        </div>
      </FormGroup>
    </Col>
  );
};
