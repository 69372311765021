import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pista: "",
  idCliente: null,
  tipo: null,
  nroTela: null,
  idColabAtrib: null,
  status: "EMP",
  idModulo: null,
};

export const listagemDemandaDevSlice = createSlice({
  name: "listagem_demanda_dev_slice",
  initialState: initialState,
  reducers: {
    setPista: (state, action) => {
      state.pista = action.payload;
    },
    setIdCliente: (state, action) => {
      state.idCliente = action.payload;
    },
    setTipo: (state, action) => {
      state.tipo = action.payload;
    },
    setNroTela: (state, action) => {
      state.nroTela = action.payload;
    },
    setIdColabAtrib: (state, action) => {
      state.idColabAtrib = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setIdModulo: (state, action) => {
      state.idModulo = action.payload;
    },
  },
});

export const {
  setPista,
  setIdCliente,
  setTipo,
  setNroTela,
  setIdColabAtrib,
  setStatus,
  setIdModulo,
} = listagemDemandaDevSlice.actions;

export default listagemDemandaDevSlice.reducer;
