import { FC, useEffect, useState } from "react";
import { naturalSort, naturalSortDate } from "../../../../../coreUtils";
import { Table } from "../../../../../components/Table";
import { TableColumn } from "../../../../../components/table/TableRow";
import { IconButton } from "../../../../../components/IconButton";
import { BsPlus } from "react-icons/bs";
import { InteracoesLeadGrid } from "./InteracoesLeadGrid";
import {
  ColorField,
  ColorFieldScheme,
} from "../../../../../components/ColorField";
import { MoreDropdown } from "../../../../../components/MoreDropdown";
import { DropdownItem } from "reactstrap";
import { iconeAlterar } from "../../../../../components/cadastro";
import LeadService from "../../../../../services/components/comercial/LeadService";
import { VisitasLeadGrid } from "./VisitasLeadGrid";
import { PropostasLeadGrid } from "./PropostasLeadGrid";
import { lerNotificacao } from "../../../../../redux/reducers/notificacoesReducer";
import { useDispatch } from "react-redux";

interface Props {
  data: any;
  handleSelect: any;
  incluirInteracao: (idLead: any) => void;
  incluirVisita: (idLead: any) => void;
  incluirProposta: (idLead: any) => void;
  naoConquistaLead: (idLead: any) => void;
  conquistaLead: (idLead: any) => void;
  reativarLead: (idLead: any, estagio: any) => void;
  alterar: (id: any) => void;
  excluir: (id: any) => void;
}

const colorsEstagio = [
  { value: "ABE", color: "#96e3a5" },
  { value: "PER", color: "gray" },
  { value: "QUI", color: "#008040" },
  { value: "TRA", color: "#ff8080" },
  { value: "REA", color: "#ffd11e" },
];

const coresGrauConquista: ColorFieldScheme[] = [
  { hint: "Pouca", value: "POU", color: "#C3916A" },
  { hint: "Média", value: "MED", color: "#1F79DB" },
  { hint: "Grande", value: "GRA", color: "#43C990" },
  { hint: "Total", value: "TOT", color: "#DA5583" },
];

export const LeadGrid: FC<Props> = ({
  data,
  handleSelect,
  incluirInteracao,
  incluirVisita,
  incluirProposta,
  naoConquistaLead,
  conquistaLead,
  reativarLead,
  excluir,
  alterar,
}) => {
  const columns: TableColumn[] = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
      cellContentTag: null,
      formatter: (c) => <span id={`id-lead-${c}`}>{c}</span>,
    },
    {
      dataField: "nome_empresa",
      text: "Empresa",
      align: "left",
      sortable: true,
    },
    {
      dataField: "nome_contato",
      text: "Contato",
      align: "left",
      sortable: true,
    },
    {
      dataField: "nome_origem",
      text: "Origem",
      align: "left",
      sortable: true,
    },
    {
      dataField: "telefone",
      text: "Telefone",
      align: "center",
    },
    {
      dataField: "grau_conv",
      text: "Grau Conv.",
      align: "center",
      sortable: true,
      formatter: (c, row) => (
        <ColorField
          value={row.grau_conversao}
          scheme={coresGrauConquista}
          valueOnHint={false}
          type="label"
          labelStyle={{ color: c ? "white" : "black" }}
        />
      ),
    },
    {
      dataField: "proximo_contato",
      text: "Próx. Interação",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
      formatter: (c, row) =>
        row.hora_proximo_contato ? `${c} - ${row.hora_proximo_contato}` : c,
    },
    {
      dataField: "num_interacoes",
      text: "Interações",
      align: "center",
      formatter: (c, row) => (
        <>
          {c}{" "}
          <IconButton
            icon={BsPlus}
            color="green"
            onClick={() => incluirInteracao(row.id)}
            hint="Incluir Interação"
            tooltipPlacement="left"
          />
        </>
      ),
    },
    {
      dataField: "num_visitas",
      text: "Visitas",
      align: "center",
      formatter: (c, row) => (
        <>
          {c}{" "}
          <IconButton
            icon={BsPlus}
            color="green"
            onClick={() => incluirVisita(row.id)}
            hint="Incluir Visita"
            tooltipPlacement="left"
          />
        </>
      ),
    },
    {
      dataField: "num_propostas",
      text: "Propostas",
      align: "center",
      formatter: (c, row) => (
        <>
          {c}{" "}
          <IconButton
            icon={BsPlus}
            color="green"
            onClick={() => incluirProposta(row.id)}
            hint="Incluir Proposta"
            tooltipPlacement="left"
          />
        </>
      ),
    },
    {
      dataField: "desc_estagio",
      text: "Negociação",
      align: "left",
      sortable: true,
      formatter: (c, row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ColorField
            scheme={colorsEstagio}
            value={row.estagio}
            showHint={false}
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginLeft: 0,
              marginRight: "0.4rem",
            }}
          />
          {c}
        </div>
      ),
    },
    {
      dataField: "dias_ult_contato",
      text: "Dias Ult Int",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    iconeAlterar(alterar),
    {
      dataField: "act",
      text: "",
      align: "center",
      selectOnClick: false,
      formatter: (c, row) => (
        <MoreDropdown>
          {!["QUI", "TRA", "REA"].includes(row.estagio) && (
            <DropdownItem onClick={() => reativarLead(row.id, "TRA")}>
              Tentar Reativar
            </DropdownItem>
          )}
          {!["QUI", "REA"].includes(row.estagio) && (
            <DropdownItem onClick={() => reativarLead(row.id, "REA")}>
              Lead Reativado
            </DropdownItem>
          )}
          {!["QUI", "PER"].includes(row.estagio) && (
            <>
              <DropdownItem onClick={() => conquistaLead(row.id)}>
                Conquistado
              </DropdownItem>
              <DropdownItem onClick={() => naoConquistaLead(row.id)}>
                Não Conquistado
              </DropdownItem>
              <hr></hr>
              <DropdownItem onClick={() => excluir(row.id)}>
                Excluir
              </DropdownItem>
            </>
          )}
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={handleSelect}
      pageSize={20}
      canExpand
      expandedAreaBuilder={(row: any) => <DetalhesLead idLead={row.id} />}
      paginated={false}
      hideSelectColumn
      onRowDoubleClick={alterar}
    />
  );
};

interface PropsDetalhes {
  idLead: number;
}

const DetalhesLead: FC<PropsDetalhes> = ({ idLead }) => {
  const [interacoes, setInteracoes] = useState([]);
  const [propostas, setPropostas] = useState([]);
  const [visitas, setVisitas] = useState([]);
  const dispatch = useDispatch();

  const buscarInteracoes = async () => {
    const [ok, ret] = await LeadService.interacao.listar(idLead);
    setInteracoes(ok ? ret : []);
    if (ok) {
      dispatch(
        lerNotificacao(
          "LEAME",
          ret.map((e: any) => e.id)
        )
      );
    }
  };

  const buscarPropostas = async () => {
    const [ok, ret] = await LeadService.proposta.listar(idLead);
    setPropostas(ok ? ret : []);
    if (ok) {
      dispatch(
        lerNotificacao(
          "LEAPR",
          ret.map((e: any) => e.id)
        )
      );
    }
  };

  const buscarVisitas = async () => {
    const [ok, ret] = await LeadService.visita.listar(idLead);
    setVisitas(ok ? ret : []);
    if (ok) {
      dispatch(
        lerNotificacao(
          "LEAVI",
          ret.map((e: any) => e.id)
        )
      );
    }
  };

  const carregarDados = async () => {
    buscarPropostas();
    buscarInteracoes();
    buscarVisitas();
  };

  useEffect(() => {
    carregarDados();
  }, []);

  return (
    <>
      <InteracoesLeadGrid data={interacoes} />
      <VisitasLeadGrid data={visitas} />
      <PropostasLeadGrid data={propostas} />
    </>
  );
};
