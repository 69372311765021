import { FC } from "react";
import { ModalBase } from "../../../components/ModalBase";
import { TabController } from "../../../components/TabController";
import { TabFinanceiro } from "./components/TabFinanceiro";
import { TabSuporte } from "./components/TabSuporte";
import { TabMigracao } from "./components/TabMigracao";
import { useDispatch, useSelector } from "react-redux";
import {
  initialState,
  setIdAtualizaMostrar,
  setIdEmpresa,
  setDescModoImplanta,
  setNomeEmpresa,
  setNomeSistemaAtual,
  setSelected,
  setup,
  setModoImplanta,
} from "./store/detalhesImplantSlice";
import ImplantacaoService from "../../../services/ImplantacaoService";
import { FixedField } from "../../../components/FixedField";
import { formatValueFromAPI } from "../../../coreUtils";
import { Row } from "reactstrap";
import { AlterarDadosGeraisModal } from "./components/AlterarDadosGeraisModal";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  selected: any;
  notifyEvent: () => void;
  idAtualizaMostrar: any;
  setorMostrar: any;
}

export const DetalhesImplantacaoModal: FC<Props> = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
  idAtualizaMostrar,
  setorMostrar,
}) => {
  const store = useSelector((state: any) => state.detalhesImplant);
  const dispatch = useDispatch();

  const limparDados = () => {
    dispatch(setup(initialState));
  };

  const carregarDados = async () => {
    const [ok, ret] = await ImplantacaoService.buscarDadosGerais(selected);
    if (ok) {
      dispatch(setIdEmpresa(ret.id_empresa));
      dispatch(setNomeEmpresa(ret.nome_empresa));
      dispatch(setModoImplanta(ret.modo_implanta));
      dispatch(setDescModoImplanta(ret.desc_modo_implanta));
      dispatch(setNomeSistemaAtual(ret.nome_sistema_atual));
    }
  };

  const onBeforeOpen = async () => {
    dispatch(setSelected(selected));
    await carregarDados();
    if (idAtualizaMostrar) {
      dispatch(setIdAtualizaMostrar(idAtualizaMostrar));
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      title="Detalhes da Implantação"
      number="014_2"
      size="lg"
      onBeforeClose={notifyEvent}
      onClosed={limparDados}
      onBeforeOpen={onBeforeOpen}
    >
      <Row className="mb-2">
        <FixedField
          label="Empresa"
          value={formatValueFromAPI(store.nome_empresa, store.id_empresa)}
        />
        <FixedField label="Modo" value={store.desc_modo_implanta} />
        <FixedField
          label="Sistema Atual"
          value={store.nome_sistema_atual}
          divClassName="pr-0"
        />
        <AlterarDadosGeraisModal
          selected={selected}
          notifyEvent={carregarDados}
        />
      </Row>
      <TabController
        contentStyle={{
          height: "850px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        defaultActiveTab={
          setorMostrar === "FIN"
            ? "1"
            : setorMostrar === "SUP"
            ? "2"
            : setorMostrar === "DEV"
            ? "3"
            : undefined
        }
      >
        <TabFinanceiro title="Financeiro" />
        <TabSuporte title="Suporte" />
        <TabMigracao title="Migração de Dados" />
      </TabController>
    </ModalBase>
  );
};
