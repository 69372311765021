import { FC, useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroContabilidadeModal } from "./components/CadastroContabilidadeModal";
import { ContabilidadeGrid } from "./components/ContabilidadeGrid";
import { apiGetV2 } from "../../../../apiV2";
import {
  debounce,
  defaultDebounceTime,
  printReport,
} from "../../../../coreUtils";
import { ModalActions } from "../../../../components/cadastro/ModalCadastroV2";
import {
  BotaoPesquisar,
  PageContainer,
  SearchInput,
} from "../../../../components";
import { BotaoIncluir } from "../../../../components/cadastro";
import { ContabilidadePDF } from "./pdf/ContabilidadePDF";
import { BotaoImprimir } from "../../../../components/BotaoImprimir";

export const modalTitleContabilidade = "Contabilidade";
export const routesBaseContabilidade = "/cadastro/contabilidade";

export const Contabilidade: FC = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(ModalActions.add);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const buscarDados = (p: any) =>
    apiGetV2(`${routesBaseContabilidade}/listar/`, {
      pista: p,
    });

  const buscarDadosGrid = async (p: any) => {
    const [ok, ret] = await buscarDados(p);
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDadosGrid(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v: any) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDadosGrid(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action?: ModalActions) => {
    carregarDados();
    if (action === ModalActions.delete) setSelected(null);
  };

  const toggleCadastro = (action: ModalActions) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const alterar = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(ModalActions.edit);
    }, 1);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados(pista);
    if (ok) {
      await printReport(<ContabilidadePDF dados={ret} />, "013");
    }
    setLoadingImprimir(false);
  };

  return (
    <PageContainer title="Cadastro de Contabilidades" number="013" canGoBack>
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
          <CadastroContabilidadeModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <ContabilidadeGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
        />
      </Card>
    </PageContainer>
  );
};
