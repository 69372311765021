import { useHistory } from "react-router-dom";
import FormButton, { FormButtonProps } from "./FormButton";
import { FC } from "react";

interface Props {
  pathname: string;
  state?: any;
  md?: FormButtonProps["md"];
  color?: FormButtonProps["color"];
  disabled?: FormButtonProps["disabled"];
  padded?: FormButtonProps["padded"];
  className?: FormButtonProps["className"];
  divClassName?: FormButtonProps["divClassName"];
  divStyle?: FormButtonProps["divStyle"];
  style?: FormButtonProps["style"];
  loading?: FormButtonProps["loading"];
  children?: FormButtonProps["children"];
  disabledHint?: FormButtonProps["disabledHint"];
  id?: FormButtonProps["id"];
  tabIndex?: FormButtonProps["tabIndex"];
}

export const LinkButton: FC<Props> = ({
  pathname,
  state,
  md,
  color,
  disabled,
  padded,
  className,
  divClassName,
  divStyle,
  style,
  loading,
  children,
  disabledHint,
  id,
  tabIndex,
}) => {
  const history = useHistory();
  return (
    <FormButton
      md={md}
      color={color}
      disabled={disabled}
      padded={padded}
      className={className}
      divClassName={divClassName}
      divStyle={divStyle}
      style={style}
      loading={loading}
      disabledHint={disabledHint}
      id={id}
      onClick={() => history.push(pathname, state)}
      tabIndex={tabIndex}
    >
      {children}
    </FormButton>
  );
};
