import axios from "axios";
import Cookies from "js-cookie";
import { toastr } from "react-redux-toastr";
import settings from "./settings";
import { isDevEnv } from "./coreUtils";

const kClientId = "xc3BYHQIbFFQL00nhwuSBL10ULggkYOK6vfivM1X";
const kClientSecret =
  "MUi14fA8i8pYiDhk3oymVgzL4MRGkWBCT5c0TFCzqaLy0xnsb5MvY5K8KlGCdfmIfCEEmPjJc3Gmz8mqXAKF71v1AtzyeSCGD2R9Jco2JVMFe4hP1IrndHTuzrz5ZPBG";

const api = axios.create({
  baseURL: isDevEnv() ? settings.API_ADDRESS_DEV : settings.API_ADDRESS,
  headers: {
    Authorization: `Bearer ${Cookies.get("access_token")}`,
  },
});

const auth = axios.create({
  baseURL: isDevEnv() ? settings.AUTENTICADOR_DEV : settings.AUTENTICADOR,
  headers: {
    Authorization: `Bearer ${Cookies.get("access_token")}`,
  },
});

const apiSkill = axios.create({
  baseURL: isDevEnv()
    ? settings.API_SKILL_ADDRESS_DEV
    : settings.API_SKILL_ADDRESS,
  headers: {
    Authorization: `Bearer ${Cookies.get("access_token")}`,
    "database-name": "default",
  },
});

const refreshToken = async (error) => {
  try {
    const data = new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: Cookies.get("refresh_token"),
    });
    const urlAuth = isDevEnv()
      ? settings.AUTENTICADOR_DEV
      : settings.AUTENTICADOR;
    axios
      .post(`${urlAuth}/o/token/`, data, {
        timeout: 5000,
        auth: { username: kClientId, password: kClientSecret },
      })
      .then(async (res) => {
        Cookies.set("access_token", res.data.access_token);
        Cookies.set("refresh_token", res.data.refresh_token);
        return res;
      })
      .catch((_) => {
        return error;
      });
  } catch (err) {
    return err;
  }
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const access_token = Cookies.get("access_token");
    if (error.response.status === 403 && access_token) {
      const response = await refreshToken(error);
      return response;
    }
    return Promise.reject(error);
  }
);

export const urlParams = (params) =>
  ![null, undefined, {}].includes(params)
    ? "?" +
      new URLSearchParams(
        Object.keys(params)
          .filter((key) => ![null, undefined, ""].includes(params[key]))
          .reduce((obj, key) => {
            if (params[key] instanceof Date) {
              params[key] = params[key].toISOString().split("T")[0];
            }
            return Object.assign(obj, {
              [key]: params[key],
            });
          }, {})
      ).toString()
    : "";

export { api, kClientId, kClientSecret, auth };

export const revokeToken = async () => {
  try {
    const data = new URLSearchParams({
      token: Cookies.get("access_token"),
      client_id: kClientId,
      client_secret: kClientSecret,
    });
    const urlAuth = isDevEnv()
      ? settings.AUTENTICADOR_DEV
      : settings.AUTENTICADOR;
    await axios
      .post(`${urlAuth}/o/revoke-token/`, data, {
        timeout: 5000,
      })
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  } catch (err) {
    return err;
  }
};

const processaRetorno = (ret, options) => {
  if (ret.data.success || (ret.data.hasOwnProperty("err") && !ret.data.err)) {
    if (options?.successMesage) {
      toastr.success("Sucesso", ret.data.msg);
    }

    return options?.returnDataSuccess ? ret.data.res : true;
  } else {
    toastr.error("Erro", ret.data.msg);
    return false;
  }
};

const processaErro = (err) => {
  toastr.error("Erro", err.message);
  return false;
};

export const apiGet = async (url, params, options) =>
  await api
    .get(url + urlParams(params))
    .then((ret) =>
      processaRetorno(ret, { returnDataSuccess: true, ...options })
    )
    .catch((err) => processaErro(err));

export const apiPost = async (url, payload, options) =>
  await api
    .post(url, payload)
    .then((ret) => processaRetorno(ret, { successMesage: true, ...options }))
    .catch((err) => processaErro(err));

export const apiPut = async (url, payload, options) =>
  await api
    .put(url, payload)
    .then((ret) => processaRetorno(ret, { successMesage: true, ...options }))
    .catch((err) => processaErro(err));

export const apiDelete = async (url) =>
  await api
    .delete(url)
    .then((ret) => processaRetorno(ret, { successMesage: true }))
    .catch((err) => processaErro(err));

export const authGet = async (url, params, options) =>
  await auth
    .get(url + urlParams(params))
    .then((ret) =>
      processaRetorno(ret, { returnDataSuccess: true, ...options })
    )
    .catch((err) => processaErro(err));

export const authPost = async (url, payload, options) =>
  await auth
    .post(url, payload)
    .then((ret) => processaRetorno(ret, { successMesage: true, ...options }))
    .catch((err) => processaErro(err));

export const authPut = async (url, payload, options) =>
  await auth
    .put(url, payload)
    .then((ret) => processaRetorno(ret, { successMesage: true, ...options }))
    .catch((err) => processaErro(err));

export const authDelete = async (url) =>
  await auth
    .delete(url)
    .then((ret) => processaRetorno(ret, { successMesage: true }))
    .catch((err) => processaErro(err));

export const apiSkillGet = async (url, params, options) =>
  await apiSkill
    .get(url + urlParams(params))
    .then((ret) =>
      processaRetorno(ret, { returnDataSuccess: true, ...options })
    )
    .catch((err) => processaErro(err));
