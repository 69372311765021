import React, { useState, FC } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import { MoreHorizontal, MoreVertical } from "react-feather";
import { Direction } from "reactstrap/lib/Dropdown";

interface Props {
  children?: any;
  onToggle?: (
    event: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>,
    isOpen: boolean
  ) => void;
  disabledHint?: any;
  horizontal?: boolean;
  dropdownDirection?: Direction;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const MoreDropdown: FC<Props> = ({
  children,
  onToggle,
  disabled,
  disabledHint = "Sem ações disponíveis",
  horizontal = true,
  dropdownDirection = "left",
  style,
}) => {
  const [id] = useState("more-" + Math.floor(Math.random() * Date.now()));
  const Icon = horizontal ? MoreHorizontal : MoreVertical;
  return !disabled ? (
    <UncontrolledDropdown
      direction={dropdownDirection}
      onToggle={onToggle}
      style={style}
    >
      <DropdownToggle
        style={{
          background: "none",
          border: "none",
          marginLeft: "auto",
          padding: 0,
          cursor: "pointer",
        }}
      >
        <Icon size={16} color="black" style={{ cursor: "pointer" }} />
      </DropdownToggle>
      <DropdownMenu>{children}</DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <>
      <MoreVertical size={16} color="silver" id={id} />
      <UncontrolledTooltip target={id}>{disabledHint}</UncontrolledTooltip>
    </>
  );
};
