import { FC, useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  BotaoPesquisar,
  FormCheckbox,
  SearchInput,
} from "../../../../components";
import { PageContainer } from "../../../../components/PageContainer";
import { debounce, defaultDebounceTime } from "../../../../coreUtils";
import { CadastroModuloModal } from "./components/CadastroModuloModal";
import { ModuloGrid } from "./components/ModuloGrid";
import { ModalActions } from "../../../../components/cadastro/ModalCadastroV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { APIs, authGetV2 } from "../../../../apiV2";

export const modalTitleModulo = "Módulo";
export const routesBaseModulo = "/cadastro/modulo";

export const Modulo: FC = () => {
  const [pista, setPista] = useState("");
  const [somenteAtivos, setSomenteAtivos] = useState(true);
  const [selected, setSelected] = useState(null);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(ModalActions.add);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const limparDados = () => setDados([]);

  const buscarDados = async (p: string = pista) => {
    const params: any = {
      descricao: p,
      somente_ativos: somenteAtivos,
    };

    const [ok, ret] = await authGetV2(`${routesBaseModulo}/listar/`, params);
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const handlePista = debounce(async (p: any) => {
    setPista(p);
    setLoadingPista(true);
    await buscarDados(p);
    setLoadingPista(false);
  }, defaultDebounceTime);

  useEffect(() => {
    carregarDados();
  }, []);

  const notifyEvent = (action: any) => {
    carregarDados();
  };

  const toggleCadastro = (action: ModalActions) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(ModalActions.edit);
    }, 1);
  };

  const excluir = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Módulos" number="006" canGoBack>
      <Card body>
        <Row>
          <SearchInput
            md={6}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Descrição"
          />
          <FormCheckbox
            label="Somente Ativos"
            onChange={(v: boolean) => {
              setSomenteAtivos(v);
              limparDados();
            }}
            checked={somenteAtivos}
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={() => toggleCadastro(ModalActions.add)} />
          <CadastroModuloModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleModulo}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="006_2"
            selected={selected}
            routeBase={routesBaseModulo}
            notifyEvent={notifyEvent}
            useApi={APIs.auth}
          />
        </Row>
      </Card>
      <Card body>
        <ModuloGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
