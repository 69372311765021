import { FC, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { getTextColorBg } from "../coreUtils";

export interface ColorFieldScheme {
  value: any;
  hint?: string;
  color: React.CSSProperties["backgroundColor"];
}

interface Props {
  scheme: ColorFieldScheme[];
  value?: any;
  style?: React.CSSProperties;
  showHint?: boolean;
  valueOnHint?: boolean;
  type?: "hint" | "label";
  labelStyle?: React.CSSProperties;
}

export const ColorField: FC<Props> = ({
  scheme,
  value,
  style,
  showHint = true,
  valueOnHint = true,
  type = "hint",
  labelStyle,
}) => {
  const [id] = useState(Math.floor(Math.random() * Date.now()));

  const valueColor = scheme.find((e) => e.value === value);

  const color = valueColor?.color ?? "transparent";

  const hint = valueColor
    ? valueOnHint
      ? `${value} - ${valueColor.hint}`
      : valueColor.hint ?? ""
    : "Não Identificado";

  return type === "hint" ? (
    <>
      <div
        style={{
          width: "40px",
          height: "20px",
          borderRadius: "4px",
          marginLeft: "auto",
          marginRight: "auto",
          ...style,
          backgroundColor: color,
        }}
        id={`color-field-${id}`}
      />
      {showHint && (
        <UncontrolledTooltip placement="bottom" target={`color-field-${id}`}>
          {hint}
        </UncontrolledTooltip>
      )}
    </>
  ) : (
    <div
      style={{
        padding: "0.05rem",
        borderRadius: "4px",
        ...style,
        backgroundColor: color,
      }}
    >
      <label style={{ color: getTextColorBg(color), ...labelStyle }}>
        {hint}
      </label>
    </div>
  );
};
