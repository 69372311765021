import { FC, useState } from "react";
import { Card, Row } from "reactstrap";
import { apiGet } from "../../../../api";
import {
  BotaoPesquisar,
  ComboBox,
  FormCheckbox,
  SearchInput,
} from "../../../../components";
import { BotaoImprimir } from "../../../../components/BotaoImprimir";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import { PageContainer } from "../../../../components/PageContainer";
import {
  debounce,
  defaultDebounceTime,
  printReport,
} from "../../../../coreUtils";
import { CadastralEmpresasGrid } from "./components/CadastralEmpresasGrid";
import { CadastralEmpresasPDF } from "./pdf/CadastralEmpresasPDF";

const statusOptions: any = [
  { label: "Todos", value: "T" },
  { label: "Ativos", value: "A" },
  { label: "Inativos", value: "I" },
];

export const CadastralEmpresas: FC = () => {
  const [pista, setPista] = useState("");
  const [status, setStatus] = useState(statusOptions[1].value);
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [somenteDataLimVencida, setSomenteDataLimVencida] = useState(false);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const limparDados = () => setDados([]);

  const handleDate = (di: any, df: any) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async (p: string = pista) => {
    const params: any = {
      pista: p,
      status: status,
      data_ini: dataIni,
      data_fim: dataFim,
      somente_data_lim_vencida: somenteDataLimVencida,
    };

    const ret = await apiGet(`/relatorios/cadastrais/empresas/`, params);
    return ret ? ret : [];
  };

  const carregarDados = async () => {
    setLoading(true);
    setDados(await buscarDados());
    setLoading(false);
  };

  const handlePista = debounce(async (p: any) => {
    setPista(p);
    setLoadingPista(true);
    setDados(await buscarDados(p));
    setLoadingPista(false);
  }, defaultDebounceTime);

  const imprimir = async () => {
    setLoadingImprimir(true);
    const ret = await buscarDados();

    await printReport(
      <CadastralEmpresasPDF dataIni={dataIni} dataFim={dataFim} dados={ret} />,
      "501_1"
    );
    setLoadingImprimir(false);
  };

  return (
    <PageContainer title="Relatório Cadastral de Empresas" number="501">
      <Card body>
        <Row>
          <SearchInput
            md={6}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Licença, nome fantasia, razão social ou CNPJ"
          />
          <ComboBox
            md={2}
            options={statusOptions}
            label="Status"
            value={status}
            onChange={setStatus}
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas
            label="Data Limite"
            defaultOption={null}
            defaultStart={null}
            defaultEnd={null}
            onChange={handleDate}
          />
          <FormCheckbox
            label="Somente Clientes com a Data Limite Vencida"
            onChange={(v: boolean) => {
              setSomenteDataLimVencida(v);
              limparDados();
            }}
            checked={somenteDataLimVencida}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <Card body>
        <CadastralEmpresasGrid data={dados} />
      </Card>
    </PageContainer>
  );
};
