import { FC, useState } from "react";
import { Row } from "reactstrap";
import { ModalBase } from "../ModalBase";
import { APIs, apiDeleteV2 } from "../../apiV2";
import { authDeleteV2 } from "../../apiV2";
import { ModalActions } from "./ModalCadastroV2";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  notifyEvent?: any;
  route?: string;
  routeBase?: string;
  selected?: any;
  title?: any;
  number?: any;
  message?: any;
  useApi?: APIs;
}

export const ModalExcluirV2: FC<Props> = ({
  isOpen,
  toggle,
  notifyEvent,
  route,
  routeBase,
  selected,
  title,
  number,
  message = "Tem certeza de que deseja excluir o registro selecionado?",
  useApi = APIs.interno,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const url = route ?? `${routeBase}/excluir/${selected}/`;

    const deleteFunc = useApi === APIs.interno ? apiDeleteV2 : authDeleteV2;

    const [ok] = await deleteFunc(route ?? `${routeBase}/excluir/${selected}/`);
    if (ok) {
      toggle();
      notifyEvent(ModalActions.delete);
    }
    setLoading(false);
  };

  return (
    <>
      {isOpen && (
        <ModalBase
          isOpen={isOpen}
          size="md"
          title={`Exclusão de ${title}`}
          number={number}
          confirmButtonText="Excluir"
          onConfirm={handleSubmit}
          loadingConfirm={loading}
          toggle={toggle}
          autoFocus
        >
          <Row className="justify-content-center">
            <p style={{ textAlign: "center" }}>{message}</p>
          </Row>
        </ModalBase>
      )}
    </>
  );
};
