import { FC, useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { isValidDate } from "../coreUtils";
import { DatePicker } from "./DatePicker";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { CgChevronDown } from "react-icons/cg";

interface DropdownOptionsProps {
  onChange: (v: any) => void;
}

const options = [
  { label: "Hoje", value: "HOJE" },
  { label: "Ontem", value: "ONTEM" },
  { label: "3 Dias", value: "3D" },
  { label: "Semana Atual", value: "SEMAT" },
  { label: "7 Dias", value: "7D" },
  { label: "15 Dias", value: "15D" },
  { label: "30 Dias", value: "30D" },
  { label: "Mês Atual", value: "MESATU" },
  { label: "Mês Anterior", value: "MESANT" },
  { label: "Mês Anterior + Atual", value: "MESATUANT" },
  { label: "Próximo Mês", value: "PROXMES" },
];

const primeiroUltimoDiaSemana = (curr: Date) => {
  var first = curr.getDate() - curr.getDay();
  var last = first + 6;

  var firstDay = new Date(curr.setDate(first));
  var lastDay = new Date(curr.setDate(last));
  return [firstDay, lastDay];
};

const DropdownOptions: FC<DropdownOptionsProps> = ({ onChange }) => {
  return (
    <UncontrolledDropdown
      direction="down"
      onToggle={(e) => e.stopPropagation()}
    >
      <DropdownToggle
        className="form-control"
        style={{
          background: "none",
          borderColor: "rgb(194 201 207)",
          marginTop: "1.2rem",
          cursor: "pointer",
          boxShadow: "none",
          paddingInline: "0.25rem",
          paddingTop: "0.2rem",
        }}
      >
        <CgChevronDown size={25} color="rgb(194 201 207)" />
      </DropdownToggle>
      <DropdownMenu>
        {options.map((e) => (
          <DropdownItem onClick={() => onChange(e.value)}>
            {e.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

interface Props {
  onChange: any;
  label?: any;
  defaultOption?: any;
  defaultStart?: any;
  defaultEnd?: any;
}

export const FiltroPeriodoDatas: FC<Props> = ({
  onChange,
  label = "Período",
  defaultOption = "HOJE",
  defaultStart = new Date(),
  defaultEnd = new Date(),
}) => {
  const [dataIniInt, setDataIniInt] = useState(defaultStart);
  const [dataFimInt, setDataFimInt] = useState(defaultEnd);

  const handleDataIni = (value: any) => {
    var _dataIni = value;
    setDataIniInt(_dataIni);
    onChange(_dataIni, dataFimInt);
  };

  const handleDataFim = (value: any) => {
    var _dataFim = value;
    setDataFimInt(_dataFim);
    onChange(dataIniInt, _dataFim);
  };

  const handleSelect = (value: any) => {
    if (
      !(dataFimInt instanceof Date) &&
      ["3D", "7D", "15D", "30D"].includes(value)
    ) {
      toastr.warning("Atenção", "Por favor, revise a data de fim.");
      return;
    }
    let _dataFim: any = dataFimInt;
    let _dataIni: any =
      dataFimInt instanceof Date ? new Date(dataFimInt.getTime()) : new Date();

    switch (value) {
      case "HOJE":
        _dataIni = new Date();
        _dataFim = new Date();
        break;
      case "ONTEM":
        _dataFim = new Date();
        _dataIni.setDate(new Date().getDate() - 1);
        _dataFim.setDate(new Date().getDate() - 1);
        break;
      case "3D":
        _dataIni.setDate(_dataFim.getDate() - 3);
        break;
      case "SEMAT":
        [_dataIni, _dataFim] = primeiroUltimoDiaSemana(new Date());
        break;
      case "7D":
        _dataIni.setDate(_dataFim.getDate() - 7);
        break;
      case "15D":
        _dataIni.setDate(_dataFim.getDate() - 15);
        break;
      case "30D":
        _dataIni.setDate(_dataFim.getDate() - 30);
        break;
      case "MESATU":
        _dataFim = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        );
        _dataIni = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        break;
      case "MESANT":
        _dataFim = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
        _dataIni = new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          1
        );
        break;
      case "MESATUANT":
        _dataFim = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        );
        _dataIni = new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          1
        );
        break;
      case "PROXMES":
        _dataFim = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 2,
          0
        );
        _dataIni = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          1
        );
        break;
      default:
        return;
    }
    if (!isValidDate(_dataIni)) {
      _dataIni = null;
    }
    if (!isValidDate(_dataFim)) {
      _dataFim = null;
    }
    setDataFimInt(_dataFim);
    setDataIniInt(_dataIni);
    onChange(_dataIni, _dataFim);
  };

  useEffect(() => {
    if (defaultOption) {
      handleSelect(defaultOption);
    }
  }, [defaultOption]);

  return (
    <>
      <DatePicker
        label={label ? `${label} - Início` : "Início"}
        name="data_ini"
        value={dataIniInt}
        onChange={handleDataIni}
        inputId="in-data-ini"
      />
      <DatePicker
        label="Fim"
        name="data_fim"
        value={dataFimInt}
        onChange={handleDataFim}
        inputId="in-data-fim"
        divClassName="pr-0"
      />
      <Col md="auto">
        <DropdownOptions onChange={handleSelect} />
      </Col>
    </>
  );
};
