import { useState, FC, useEffect } from "react";
import { Card, Row } from "reactstrap";
import {
  BotaoPesquisar,
  FormCheckbox,
  SearchInput,
} from "../../../../components";
import { PageContainer } from "../../../../components/PageContainer";
import { debounce, defaultDebounceTime } from "../../../../coreUtils";
import { CadastroPlanoContratadoModal } from "./components/CadastroPlanoContratadoModal";
import { PlanoContratadoGrid } from "./components/PlanoContratadoGrid";
import { APIs, authGetV2 } from "../../../../apiV2";
import { ModalActions } from "../../../../components/cadastro/ModalCadastroV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";

export const modalTitlePlanoContratado = "Plano Contratado";
export const routesBasePlanoContratado = "/cadastro/plano_contratado";

export const PlanoContratado: FC = () => {
  const [pista, setPista] = useState("");
  const [somenteAtivos, setSomenteAtivos] = useState(true);
  const [selected, setSelected] = useState(null);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(ModalActions.add);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const limparDados = () => setDados([]);

  const buscarDados = async (p = pista) => {
    const params = {
      pista: p,
      somente_ativos: somenteAtivos,
    };

    const [ok, ret] = await authGetV2(
      `${routesBasePlanoContratado}/listar/`,
      params
    );
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const handlePista = debounce(async (p: any) => {
    setPista(p);
    setLoadingPista(true);
    await buscarDados(p);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action: any) => {
    if (action === ModalActions.delete) setSelected(null);
    carregarDados();
  };

  useEffect(() => {
    carregarDados();
  }, []);

  const toggleCadastro = (action: ModalActions) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(ModalActions.edit);
    }, 1);
  };

  const excluir = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Plano Contratado" number="010" canGoBack>
      <Card body>
        <Row>
          <SearchInput
            md={6}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Descrição"
          />
          <FormCheckbox
            label="Somente Ativos"
            name="somente_ativos"
            onChange={() => {
              setSomenteAtivos(!somenteAtivos);
              limparDados();
            }}
            checked={somenteAtivos}
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={() => toggleCadastro(ModalActions.add)} />
          <CadastroPlanoContratadoModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitlePlanoContratado}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="010_2"
            selected={selected}
            routeBase={routesBasePlanoContratado}
            notifyEvent={notifyEvent}
            useApi={APIs.auth}
          />
        </Row>
      </Card>
      <Card body>
        <PlanoContratadoGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
