import { useState, FC, DOMAttributes, SVGAttributes } from "react";
import { Icon } from "react-feather";
import { IconBaseProps, IconType } from "react-icons/lib";
import { UncontrolledTooltip } from "reactstrap";

interface Props {
  icon: IconType | Icon;
  size?: IconBaseProps["size"];
  color?: IconBaseProps["color"];
  onClick: DOMAttributes<SVGElement>["onClick"];
  disabled?: boolean;
  disabledHint?: any;
  hint?: any;
  id?: any;
  className?: string;
  tooltipPlacement?: UncontrolledTooltip["props"]["placement"];
  style?: SVGAttributes<SVGElement>["style"];
  loading?: boolean;
}

export const IconButton: FC<Props> = ({
  icon: Icon,
  size = 16,
  color,
  onClick,
  disabled,
  disabledHint,
  hint,
  id,
  className,
  tooltipPlacement,
  style,
  loading,
}) => {
  const [internalId] = useState(
    id ?? "ib-" + Math.floor(Math.random() * Date.now())
  );

  const canClick = !disabled && !loading;

  return (
    <>
      <Icon
        size={size}
        onClick={canClick && onClick ? (e) => onClick(e) : () => {}}
        color={canClick ? color : "#999"}
        id={internalId}
        style={{ cursor: canClick ? "pointer" : "default", ...style }}
        className={className}
      />
      {hint && (
        <UncontrolledTooltip target={internalId} placement={tooltipPlacement}>
          {!disabled ? hint : disabledHint}
        </UncontrolledTooltip>
      )}
    </>
  );
};
