import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import store from "./redux/store/index";
import "react-datetime/css/react-datetime.css";
import Routes from "./routes/Routes";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Routes />
      <ReduxToastr
        timeOut={3000}
        newestOnTop={true}
        position="bottom-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        preventDuplicates
        progressBar
        closeOnToastrClick
      />
    </Provider>
  );
};

export default App;
