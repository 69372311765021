const SET_NOTIFICACOES = "SET_NOTIFICACOES";
const ADD_NOTIFICACAO = "ADD_NOTIFICACAO";
const LER_NOTIFICACAO = "LER_NOTIFICACAO";
const SET_MOSTRAR_NAO_LIDAS = "SET_MOSTRAR_NAO_LIDAS";

export function setNotificacoes(payload) {
  return {
    type: SET_NOTIFICACOES,
    payload: payload,
  };
}

export function addNotificacao(payload) {
  return {
    type: ADD_NOTIFICACAO,
    payload: payload,
  };
}

export function lerNotificacao(origem, idOrigem) {
  return {
    type: LER_NOTIFICACAO,
    origem: origem,
    idOrigem: idOrigem,
  };
}

export function notifMostrarNaoLidas(payload) {
  return {
    type: SET_MOSTRAR_NAO_LIDAS,
    payload: payload,
  };
}

const initialState = {
  mostrarNaoLidas: false,
  notificacoes: [],
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case SET_NOTIFICACOES:
      return {
        ...state,
        notificacoes: actions.payload,
      };
    case SET_MOSTRAR_NAO_LIDAS:
      return {
        ...state,
        mostrarNaoLidas: actions.payload,
      };
    case ADD_NOTIFICACAO:
      return {
        ...state,
        notificacoes: [actions.payload, ...state.notificacoes],
      };
    case LER_NOTIFICACAO:
      return {
        ...state,
        notificacoes: state.notificacoes.map((e) =>
          e.origem === actions.origem &&
          (actions.idOrigem instanceof Array
            ? actions.idOrigem.includes(e.id_origem)
            : e.id_origem === actions.idOrigem)
            ? { ...e, lida: true }
            : e
        ),
      };
    default:
      return state;
  }
}
