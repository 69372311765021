import { FC } from "react";
import { TableCheck } from "../../../../../components/TableCheck";
import { Table } from "../../../../../components/Table";
import { TableColumn } from "../../../../../components/table/TableRow";

interface Props {
  data?: any;
}
const columns: TableColumn[] = [
  {
    dataField: "id",
    text: "Licença",
    align: "center",
  },
  {
    dataField: "razao_social",
    text: "Razão Social",
    align: "left",
  },
  {
    dataField: "nome_fant",
    text: "Nome Fantasia",
    align: "left",
  },
  {
    dataField: "cnpj",
    text: "CNPJ",
    align: "left",
  },
  {
    dataField: "nome_plano_contratado",
    text: "Plano",
    align: "left",
  },
  {
    dataField: "data_lim",
    text: "Data Limite",
    align: "center",
  },
  {
    dataField: "ativo",
    text: "Ativo",
    align: "center",
    formatter: (c: any) => <TableCheck value={c} />,
  },
];

export const CadastralEmpresasGrid: FC<Props> = ({ data }) => {
  return <Table data={data} columns={columns} />;
};
