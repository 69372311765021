import moment from "moment";
import { FC } from "react";
import { Table } from "../../../../components/Table";
import { TableColumn } from "../../../../components/table/TableRow";

interface Props {
  data: any;
  setSelected: any;
}

export const SuporteGrid: FC<Props> = ({ data, setSelected }) => {
  const columns: TableColumn[] = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
    },
    {
      dataField: "contato",
      text: "Contato",
      align: "left",
    },
    {
      dataField: "aberta",
      text: "Situação",
      align: "center",
      formatter: (c: any) => (c ? "Aberta" : "Fechada"),
    },
    {
      dataField: "data_hora_inc",
      text: "Espera",
      align: "center",
      // formatExtraData: Date.now(),
      formatter: (c: any, r: any, i: any) =>
        moment
          .duration(moment(new Date()).diff(Date.parse(c + "Z")))
          .humanize(),
    },
    {
      dataField: "qtd_atend",
      text: "Qtd. Atende",
      align: "center",
    },
    {
      dataField: "setor",
      text: "Setor",
      align: "center",
    },
    {
      dataField: "resolvido",
      text: "Resolvido",
      align: "center",
      // formatter: (c: any) => <TableCheck value={c} />,
    },
  ];

  return (
    <Table data={data} columns={columns} onSelect={setSelected} paginated />
  );
};
