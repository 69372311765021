import { FC, useState, useEffect } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FormButton,
  FormCheckbox,
} from "../../../components";
import { FiltroPeriodoDatas } from "../../../components/FiltroPeriodoDatas";
import { PageContainer } from "../../../components/PageContainer";
import { RadioGroup, RadioGroupOption } from "../../../components/RadioGroup";
import PendenciaService from "../../../services/PendenciaService";
import { IncluirDemandaSuporteModal } from "./components/IncluirDemandaSuporteModal";
import { SuporteGrid } from "./components/SuporteGrid";

const setorOptions: RadioGroupOption[] = [
  { label: "Suporte", value: "SUPO" },
  { label: "Financeiro | ADM", value: "FINA" },
  { label: "Comercial", value: "COME" },
];

export const Suporte: FC = () => {
  const [cliente, setCliente] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [gerouDemandaDev, setGerouDemandaDev] = useState(false);
  const [setor, setSetor] = useState("SUPO");
  const [somenteEmAberto, setSomenteEmAberto] = useState(false);
  const [clienteNeonatal, setClienteNeonatal] = useState(false);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDate = (di: any, df: any) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    setLoading(true);
    const params = {
      id_cliente: cliente,
      data_ini: dataIni,
      data_fim: dataFim,
      setor: setor,
      gerou_demanda_dev: gerouDemandaDev,
      somente_em_aberto: somenteEmAberto,
      cliente_neonatal: clienteNeonatal,
    };
    const ret = await PendenciaService.listar(params);
    setDados(ret ? ret : []);
    setLoading(false);
  };

  useEffect(() => {
    carregarDados();
  }, []);

  useEffect(() => {
    carregarDados();
  }, [
    cliente,
    dataIni,
    dataFim,
    gerouDemandaDev,
    setor,
    somenteEmAberto,
    clienteNeonatal,
  ]);

  return (
    <PageContainer title="Suporte" number="004">
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            isConcatField
            concatModelName="empresa"
            defaultOptions
            label="Cliente"
            isSearchable
            isClearable
            onChange={setCliente}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
        </Row>
        <Row>
          <RadioGroup
            label="Setor"
            value={setor}
            onChange={setSetor}
            options={setorOptions}
          />
          <FormCheckbox
            label="Gerou Demanda Desenvolvimento"
            onChange={setGerouDemandaDev}
            checked={gerouDemandaDev}
          />
          <FormCheckbox
            label="Somente em Aberto"
            onChange={setSomenteEmAberto}
            checked={somenteEmAberto}
          />
          <FormCheckbox
            label="Cliente Neonatal"
            onChange={setClienteNeonatal}
            checked={clienteNeonatal}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <IncluirDemandaSuporteModal notifyEvent={carregarDados} />
          <FormButton md="auto" color="success">
            Atender
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <SuporteGrid data={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};
