import Cookies from "js-cookie";
import { FC, useEffect, useState } from "react";
import { Settings } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";

import { logoff } from "../auth/session";

import { toggleSidebar } from "../redux/actions/sidebarActions";
import ScreenTitle from "./ScreenTitle";
import { RouteType, defaultRoutes } from "../routes";
import { RootState } from "../redux/store";
import { NotificationDropdown } from "./NotificationDropdown";

interface ScreenType extends RouteType {
  level: number;
  number: string | undefined;
}

const listScreens = () => {
  const unwrapRoute = (node: RouteType, level: number): ScreenType[] => {
    if (node.children) {
      return node.children.reduce<ScreenType[]>(
        (acc, child) => [...acc, ...unwrapRoute(child, level + 1)],
        []
      );
    } else {
      return [
        {
          ...node,
          level: level,
          number: node.id?.replace("AcSkWeb", ""),
        },
      ];
    }
  };

  return [...defaultRoutes].reduce<ScreenType[]>(
    (acc, node) => [...acc, ...unwrapRoute(node, 1)],
    []
  );
};

const screensList = listScreens();

const NavbarComponent: FC = () => {
  const [rotaTela, setRotaTela] = useState<ScreenType | null>(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const pageState = useSelector((state: RootState) => state.page);
  const history = useHistory();

  const onLogout = async () => {
    await logoff();
    window.location.href = "/acesso/login/";
  };

  const buscarNomeTela = (path: string) => {
    const route = screensList.find((e) => e.path === path);
    setRotaTela(route ?? null);
  };

  useEffect(() => {
    buscarNomeTela(location.pathname);
  }, [location.pathname]);

  const onGoingBack = () => {
    if (!rotaTela) {
      return;
    }
    if (rotaTela.canGoBack !== false && history.length > 1) {
      if (pageState.goBackRoute) {
        history.replace(pageState.goBackRoute.path);
      } else {
        history.goBack();
      }
    }
  };

  const getNickname = () => {
    let nickname = Cookies.get("nickname");
    if (nickname) {
      return nickname;
    } else {
      return "Não autenticado";
    }
  };

  useEffect(() => {
    const handleToggle = (event: KeyboardEvent) => {
      if (event.key === "q" && document.activeElement!.tagName === "BODY") {
        dispatch(toggleSidebar());
      }
    };

    document.addEventListener("keydown", handleToggle);
  }, [dispatch]);

  return (
    <Navbar color="white" light expand>
      {rotaTela && (
        <ScreenTitle onGoingBack={onGoingBack}>{pageState.title}</ScreenTitle>
      )}

      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          <NotificationDropdown />
          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>

            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <span className="text-dark">{getNickname()}</span>
              </DropdownToggle>
            </span>

            <DropdownMenu right>
              <DropdownItem>Configurações</DropdownItem>
              <DropdownItem>Suporte</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={onLogout}>Sair</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
