import React, { FC, Fragment } from "react";

interface Props {
  isActive?: any;
  toggleCheck?: any;
  label?: any;
  id?: any;
}
const ModalHeaderActionCheckbox: FC<Props> = ({
  isActive,
  toggleCheck,
  label,
  id,
}) => {
  return (
    <Fragment>
      <input
        type="checkbox"
        id={id || ""}
        className="position-relative mr-2 ml-2"
        style={{ top: "1.8px" }}
        checked={isActive}
        onClick={toggleCheck}
      />
      <label style={{ fontSize: "14px" }}>{label}</label>
    </Fragment>
  );
};

export default ModalHeaderActionCheckbox;
